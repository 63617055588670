import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Table, Button, Form, Container, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Layout from "app/universal/layout/Layout";
import Loader from "app/deliveryTool/components/delivery/FakeDelivery";
import { getTenders, getTenderCatalogRanges, updateTenderCatalogRanges, resetTenderCatalog, resetTenderCatalogRanges } from "state/tender/actions";
import { Link } from "react-router-dom";
import Select from 'react-select'; // Importer react-select

const TenderCatalogRangeManager = () => {
  const { tenderId } = useParams();
  const dispatch = useDispatch();

  const tenderCatalogRangesState = useSelector((state) => state.tender.tenderCatalogRanges);
  const tendersState = useSelector((state) => state.tender.tenders);
  const { isLoading } = tenderCatalogRangesState;
  const tender = useSelector((state) => tendersState.data.find((tender) => tender.id === parseInt(tenderId)));

  const [ranges, setRanges] = useState(tenderCatalogRangesState?.data ? tenderCatalogRangesState.data : []);

  const handleAddRange = () => {
    setRanges([...ranges, { min: '', max: '', prestationTypes: [] }]);
  };

  const handleRemoveRange = (index) => {
    const newRanges = ranges.filter((_, i) => i !== index);
    setRanges(newRanges);
  };

  const handleRangeChange = (index, field, value) => {
    const newRanges = ranges.map((range, i) => 
      i === index ? { ...range, [field]: value } : range
    );
    setRanges(newRanges);
  };

  const handlePrestationTypeChange = (index, selectedOptions) => {
    const newRanges = ranges.map((range, i) => 
      i === index ? { ...range, prestation_types: selectedOptions.map(option => option.value) } : range
    );
    setRanges(newRanges);
  };

  const handleSave = () => {
    // Verifie que les ranges sont valides
    if (ranges.some((range) => (range.min === '' || !range.min) || (range.max === '' || !range.max) || (range.prestation_types.length === 0))) {
      alert('Veuillez remplir tous les champs');
      return;
    }
    dispatch(updateTenderCatalogRanges(tenderId, ranges));
    dispatch(resetTenderCatalog());
  };

  // Si l'id du tender change, on reset le state
  useEffect(() => {
    dispatch(resetTenderCatalog());
    dispatch(resetTenderCatalogRanges());
  }
  , [dispatch, tenderId]);

  // Recupère les ranges
  useEffect(() => {
    if (!tenderCatalogRangesState.isInitialized && !tenderCatalogRangesState.isLoading) {
      dispatch(getTenderCatalogRanges(tenderId));
    }
  }
  , [dispatch, tenderCatalogRangesState.isInitialized, tenderId]);

  useEffect(() => {
    setRanges(tenderCatalogRangesState.data);
  }
  , [tenderCatalogRangesState.data]);

  // Recupère le tender
  useEffect(() => {
    if (!tendersState.isInitialized && !tendersState.isLoading) {
      dispatch(getTenders());
    }
  }
  , [dispatch, tendersState]);

  const prestationOptions = [
    { value: 'mealtray', label: 'PR' },
    { value: 'breakfast', label: 'Dej' },
    { value: 'caterer', label: 'Cocktail' }
  ];

  return (
    <Layout reduxIsUsed={true}>
      <Container fluid>
        <h2 className="text-center py-3"><small><Link to={'/appel_offre'}><u>Liste AO</u></Link> {`> Palier ${tender?.name}`}</small></h2>
    {isLoading ? (
      <Loader />
    ) : (
      <>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Min</th>
              <th>Max</th>
              <th>Types de prestation</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {ranges && ranges.map((range, index) => (
              <tr key={index}>
                <td>
                  <Form.Control 
                    type="number" 
                    value={range.min !== undefined && range.min !== null ? range.min : ''} 
                    min="1" // Valeur minimale autorisée
                    step="1" // Incrément de la valeur
                    placeholder="Min"
                    onChange={(e) => handleRangeChange(index, 'min', e.target.value)} 
                  />
                </td>
                <td>
                  <Form.Control 
                    type="number" 
                    value={range.max !== undefined && range.max !== null ? range.max : ''} 
                    min="1" // Valeur minimale autorisée
                    step="1" // Incrément de la valeur
                    placeholder="Max"
                    onChange={(e) => handleRangeChange(index, 'max', e.target.value)} 
                  />
                </td>
                <td>
                  <Select
                    isMulti
                    value={range.prestation_types !== undefined && range.prestation_types !== null ? prestationOptions.filter(option => range.prestation_types.includes(option.value)) : ''} 
                    options={prestationOptions}
                    onChange={(selectedOptions) => handlePrestationTypeChange(index, selectedOptions)}
                    placeholder="Sélectionner les types de prestation"
                  />
                </td>
                <td>
                  <Button variant="danger" onClick={() => handleRemoveRange(index)} title="Supprimer ce palier">×</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Row>
          <Col xs={6}>
            <Button variant="primary" onClick={handleAddRange} className="mr-2" title="Ajouter un nouveau palier">Ajouter un palier</Button>
          </Col>
          <Col xs={6} className='text-right'>
            <Button variant="success" onClick={handleSave} disabled={!ranges} title="Sauvegarder les paliers">Sauvegarder</Button>
          </Col>
        </Row>
      </>
      )}
      </Container>
    </Layout>
  );
};

export default TenderCatalogRangeManager;