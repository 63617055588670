import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { thunk } from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Uses local storage for the web
import apiService from "./apiService";
import adminDataReducer from "./adminData/reducers";
import orderEditionReducer from "./orderEdition/reducers";
import tenderReducer from "./tender/reducers";
import customerReducer from "./customer/reducers";
import companyReducer from "./company/reducers";

// For debugging with Redux DevTools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Combine reducers
const rootReducer = combineReducers({
  adminData: adminDataReducer,
  orderEdition: orderEditionReducer,
  tender: tenderReducer,
  customer: customerReducer,
  company: companyReducer,
});

// Persist configuration
const persistConfig = {
  key: "root",
  storage,
  whitelist: [], // Specify which reducers should persist
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create store
export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk, apiService)) // Apply both middlewares
);

// Persist the store
export const persistor = persistStore(store);
