export const ATSCustomer = (data) => {
    return {
        id: data?.id || null,
        firstname: data?.contact_firstname || "",
        lastname: data?.contact_lastname || "",
        email: data?.email || "",
        phone: data?.phone || "",
        company_id: data?.company_bis?.id || null,
        postal_code: data?.postal_code || "",
        city: data?.city || "",
        street_number: data?.street_number || "",
        street: data?.street || "",
        address_complement: data?.address_complement || "",
    };
}