import React from 'react'
import Layout from 'app/universal/layout/Layout'
import {Container, Row, Col} from 'react-bootstrap'
import { css } from 'emotion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'

import Providers from 'app/deliveryTool/context/Providers'
import Filters from 'app/deliveryTool/components/filters/Filters'
import BuildRoadmaps from 'app/deliveryTool/pages/itineraire/BuildRoadMaps'

const Itineraires = (props) => {

  const urlPrint = () => {
    props.history.push({
      pathname: '/impression-planning',
      search: window.location.search,
    });
  }

  return (
    <Providers>
      <Layout>
        <Container fluid={true}>
          <Row>
            <Col xs={12} sm={8}  className={css(styles.filterBlock)}>
              <Filters/>
            </Col>
            <Col xs={12} sm={4} className={css(styles.action)}>
              <div className={css(styles.print)} onClick={ () => {urlPrint()}}><FontAwesomeIcon icon={faPrint} /> Outil d'impression</div>
            </Col>
          </Row>
          <BuildRoadmaps idRoadMapUrl={props.match.params.id} router={props.history}/>
        </Container>
      </Layout>
    </Providers>
  )

}

const styles = {
  filterBlock:{
    'borderBottom': '1px #ddd solid',
    'padding': '10px 10px 10px 10px'
  },
  action: {
    'borderBottom': '1px #ddd solid',
    'padding': '10px 10px 10px 10px',
    'textAlign': 'right'
  },
  print: {
    cursor: 'pointer',
    display:'inline-block',
    border: 'thin solid #bbb',
    color: '#555',
    padding: '0.2rem 1rem',
    transition: '300ms',
    ':hover': {
      textDecoration: 'none',
      color: 'white',
      backgroundColor: '#bbb',
      transition: '300ms'
    }
  }
}

export default Itineraires
