export const ADD_COMPANY = "ADD_COMPANY";
export const ADD_COMPANY_COMPLETED = "ADD_COMPANY_COMPLETED";
export const ADD_COMPANY_FAILED = "ADD_COMPANY_FAILED";

export const addCompany = (companyInfo) => ({
  type: ADD_COMPANY,
  meta: {
    async: true,
    blocking: true,
    path: `/api/companies`,
    method: "POST",
    body: {company: companyInfo}
  },
});
