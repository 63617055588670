import * as yup from "yup";

export const TenderCatalogItemSchema = yup.object().shape({
  id: yup.number().nullable(),
  name: yup.string().required(),
  renammed: yup.string().nullable(),
  type: yup.string().oneOf(['product', 'group', 'package']).required(),
  product_id: yup.number().nullable().notRequired(),
  group_id: yup.number().nullable().notRequired(),
  package_id: yup.number().nullable().notRequired(),
  pricings: yup.array().of(
    yup.object().shape({
      price: yup.number().nullable(true),
      range_id: yup.number().required(),
    })
  ).nullable(true).notRequired(),
  price: yup.number().nullable(true),
  types: yup.array().of(yup.string()).nullable(true),
  is_up_to_date: yup.boolean().nullable(true)
})
.test(
  'product-or-group-or-package',
  'Either product_id or group_id or package_id must be provided, but not all.',
  function (value) {
    return (value.product_id && !value.group_id && !value.package_id) || (!value.product_id && value.group_id && !value.package_id) || (!value.product_id && !value.group_id && value.package_id);
  }
);

export const TenderCatalogSchema = yup.object().shape({
  id: yup.number().required(),
  items: yup.array().of(TenderCatalogItemSchema).min(0),
});
