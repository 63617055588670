import { createSelector } from "reselect";

const getOrders = (state) => state.orderEdition.orders;

/**
 * Retourne les items de type formule petit dej
 */
export const getBreakfastFormulaItemsByOrderIndex = createSelector(
  [getOrders, (_, orderIndex) => orderIndex],
  (orders, orderIndex) => {
    const items = orders[orderIndex].items;
    return items ? items.map((item, index) => ({ ...item, originalIndex: index })).filter(item => item.type === 'formula') : [];
  }
);

export const getProductGroupsItemsByOrderIndex = createSelector(
  [getOrders, (_, orderIndex) => orderIndex],
  (orders, orderIndex) => {
    const items = orders[orderIndex].items;
    return items ? items.map((item, index) => ({ ...item, originalIndex: index }))?.filter(item => item.type === 'productGroup') : [];
  }
);

export const getProductPackagesItemsByOrderIndex = createSelector(
  [getOrders, (_, orderIndex) => orderIndex],
  (orders, orderIndex) => {
    const items = orders[orderIndex].items;
    return items ? items.map((item, index) => ({ ...item, originalIndex: index })).filter(item => item.type === 'productPackage') : [];
  }
);

/**
 * Retourne les items de type produit
 */
export const getProductItemsByOrderIndex = createSelector(
  [getOrders, (_, orderIndex) => orderIndex],
  (orders, orderIndex) => {
    const items = orders[orderIndex].items;
    return items ? items.map((item, index) => ({ ...item, originalIndex: index })).filter(item => item.type === 'product') : [];
  }
);
