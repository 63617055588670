import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateReduction } from "state/orderEdition/actions";
import { Form } from "react-bootstrap";
import CurrencyComponent from './CurrencyComponent';

const ReductionComponent = ({ orderIndex }) => {
  const dispatch = useDispatch();
  const reduction = useSelector((state) =>
    state.orderEdition.orders[orderIndex]
      ? state.orderEdition.orders[orderIndex].reduction
      : {}
  );

  const [reductionType, setReductionType] = useState("");
  const [reductionLabel, setReductionLabel] = useState(reduction.label || null);
  const [directReduction, setDirectReduction] = useState(reduction.direct || null);
  const [proportionalReduction, setProportionalReduction] = useState(reduction.proportional || null);

  useEffect(() => {
    // Update local state when reduction data changes
    setReductionType(reduction.direct ? "direct" : reduction.proportional ? "proportional" : null);
    setReductionLabel(reduction.label || null);
    setDirectReduction(reduction.direct || null);
    setProportionalReduction(reduction.proportional || null);
  }, [reduction]);

  const handleValueChange = (field, value) => {
    if (field === "direct" || field === "proportional") {
      if (value === "" || value === null || value === undefined || value === 0) {
        setReductionType(null);
      } else {
        setReductionType(field);
      }
    }
    dispatch(updateReduction(orderIndex, { ...reduction, [field]: value }));
  };

  return (
    <Form>
      <Form.Group>
        <Form.Label>Libellé de la réduction</Form.Label>
        <Form.Control
          id="reductionLabel"
          name="reductionLabel"
          placeholder="Libellé"
          value={reductionLabel === null ? "" : reductionLabel}
          onChange={(e) => {
            setReductionLabel(e.target.value);
            handleValueChange("label", e.target.value);
          }}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Réduction directe</Form.Label>
        <CurrencyComponent
          initialPrice={directReduction}
          onPriceChange={(newValue) => handleValueChange("direct", newValue)}
          disabled={reductionType === "proportional"}
          style={reductionType === "proportional" ? { backgroundColor: '#cccccc' } : {}}
          nullable={true}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Réduction proportionnelle (%)</Form.Label>
        <Form.Control
          type="number"
          step="0.01"
          id="proportionalReduction"
          name="proportionalReduction"
          placeholder="Pourcentage"
          value={proportionalReduction || ""}
          onChange={(e) => {
            const newValue = e.target.value;
            setProportionalReduction(newValue);
            handleValueChange("proportional", newValue);
          }}
          disabled={reductionType === "direct"}
          style={reductionType === "direct" ? { backgroundColor: '#cccccc' } : {}}
        />
      </Form.Group>
    </Form>
  );
};

export default ReductionComponent;
