import moment from "moment";

export const orderStateToTotal = (order) => {
  let itemsCost = 0;
  const participantsNumber = order?.deliveryInfo?.person || 1;

  // Si cocktail, les forfaits sont exclus du prix classique, ce sont les prix dans priceInfo qui compte
  if (order?.priceInfo?.catererPackagePiecesPriceHtPerParticipant || order?.priceInfo?.catererPackageSoftsPriceHtPerParticipant || order?.priceInfo?.catererPackageAlcoholsPriceHtPerParticipant) {
    itemsCost = (order.priceInfo.catererPackagePiecesPriceHtPerParticipant + order.priceInfo.catererPackageSoftsPriceHtPerParticipant + order.priceInfo.catererPackageAlcoholsPriceHtPerParticipant) * participantsNumber;
  }
  
  // ajoute le prix des items sauf si prestationType = caterer et item.type = productPackage
  itemsCost += order.items ? order.items.filter((item) => {
    if (order.prestationType === 'caterer') {
      if (item.type === 'productPackage') {
        return false;
      }
    }
    return true;
  }).reduce(
    (total, item) => total + ((item.price || 0) * item.quantity),
    0
  ) : 0;

  const deliveryCost = order.deliveryCost;
  let reductionAmount = 0;
  if (order.reduction.proportional) {
    reductionAmount = ((itemsCost + deliveryCost) * order.reduction.proportional) / 100;
  } else if (order.reduction.direct) {
    reductionAmount = order.reduction.direct;
  }

  const totalHT = itemsCost - reductionAmount + deliveryCost;
  const totalHTWithoutReduction = itemsCost + deliveryCost;

  return {
    totalHT,
    totalHTWithoutReduction
  };
};

/**
 * Prend les commandes dans le state et formate pour l'api
 */
export const multiOrdersStoreToApi = (orderInfo) => {
  return orderInfo.orders.map((order, orderIndex) => {
    const deliveryDate = orderInfo?.orders?.[orderIndex]?.deliveryInfo?.deliveryDate
      ? moment(orderInfo.orders[orderIndex].deliveryInfo.deliveryDate, "YYYY/MM/DD").format("YYYY-MM-DD")
      : null;

    const deliveryStartAt = orderInfo?.orders?.[orderIndex]?.deliveryInfo?.startAt || null;
    const deliveryEndAt = orderInfo?.orders?.[orderIndex]?.deliveryInfo?.endAt || null;
    const deliveryArrivedAtStart = orderInfo?.orders?.[orderIndex]?.deliveryInfo?.arrivedAtStart || null;
    const deliveryArrivedAtEnd = orderInfo?.orders?.[orderIndex]?.deliveryInfo?.arrivedAtEnd || null;
    const isCaterer = order.prestationType === 'caterer';
    const articles = order.items.map((item) => {
      if (item.type === 'productGroup') {
        const groupArticle = {
          name: item.name,
          renamedName: item?.renammed || null,
          group: item.group_id,
          quantity: item.quantity,
          price: item.price,
          id: item?.article_id,
          category: item?.classification_name,
          children: item.products.map((product) => ({
            product: product.product_id,
            quantity: product.quantity,
            price: product.price,
            id: product?.article_id,
            name: product?.name
          }))
        };
        return groupArticle;
      } 
      else if (item.type === 'productPackage') {
        let childrenProducts = item.products.map((product) => ({
          product: product.product_id,
          quantity: product.quantity,
          price: product.price,
          id: product?.article_id,
          name: product?.name
        }));
        let childrenProductParts = item.product_parts.map((productPart) => ({
          productPart: productPart.product_part_id,
          quantity: productPart.quantity,
          price: productPart.price,
          id: productPart?.article_id,
          name: productPart?.name
        }));
        const children = childrenProducts.concat(childrenProductParts);
        // Si c'est pour un cocktail, les forfaits n'ont pas de prix directement donc on prend le prix indicatif
        const packageArticle = {
          name: item.name,
          renamedName: item?.renammed || null,
          package: item.package_id,
          quantity: item.quantity,
          price: isCaterer ? null : item?.price,
          indicativePrice: isCaterer ? item?.price : null,
          id: item?.article_id,
          category: item?.classification_name,
          children: children
        };
        return packageArticle;       
      }
      else {
        return {
          product: item.product_id,
          quantity: item.quantity,
          price: item.price,
          id: item?.article_id,
          name: item?.name,
          renamedName: item?.renammed || null,
          category: item?.classification_name,
          catererName: item?.caterer_name || null
        };
      }
    });

    // Les infos sur les prix
    let priceInfo = {
      totalPriceHt: Math.round(orderInfo?.orders?.[orderIndex]?.priceInfo?.totalHT) || 0,
      totalPriceHtWithoutPromotion: Math.round(orderInfo?.orders?.[orderIndex]?.priceInfo?.totalHTWithoutReduction) || 0,
    }

    // Si type traiteur, on ajoute les infos sur les prix par personne
    if (order.prestationType === 'caterer') {
      priceInfo = {
        ...priceInfo,
        catererPackagePiecesPriceHtPerParticipant: Math.round(orderInfo?.orders?.[orderIndex]?.priceInfo?.catererPackagePiecesPriceHtPerParticipant) || null,
        catererPackageSoftsPriceHtPerParticipant: Math.round(orderInfo?.orders?.[orderIndex]?.priceInfo?.catererPackageSoftsPriceHtPerParticipant) || null,
        catererPackageAlcoholsPriceHtPerParticipant: Math.round(orderInfo?.orders?.[orderIndex]?.priceInfo?.catererPackageAlcoholsPriceHtPerParticipant) || null,
        piecesTaxRate: 0.100,
        softsTaxRate: 0.100,
        alcoholsTaxRate: 0.200,
      }
    }

    let deliveryInformation;
    if (orderInfo?.orders?.[orderIndex]?.deliveryInfo?.deliverySpecific === true) {
      deliveryInformation = {
        firstname: orderInfo?.orders?.[orderIndex]?.deliveryInfo?.firstname === "" ? null : orderInfo?.orders?.[orderIndex]?.deliveryInfo?.firstname,
        lastname: orderInfo?.orders?.[orderIndex]?.deliveryInfo?.lastname === "" ? null : orderInfo?.orders?.[orderIndex]?.deliveryInfo?.lastname,
        company: orderInfo?.orders?.[orderIndex]?.deliveryInfo?.company === "" ? null : orderInfo?.orders?.[orderIndex]?.deliveryInfo?.company,
        phone: orderInfo?.orders?.[orderIndex]?.deliveryInfo?.phone === "" ? null : orderInfo?.orders?.[orderIndex]?.deliveryInfo?.phone,
        phoneSecondary: orderInfo?.orders?.[orderIndex]?.deliveryInfo?.phoneSecondary === "" ? null : orderInfo?.orders?.[orderIndex]?.deliveryInfo?.phoneSecondary,
        streetNumber: orderInfo?.orders?.[orderIndex]?.deliveryInfo?.streetNumber === "" ? null : orderInfo?.orders?.[orderIndex]?.deliveryInfo?.streetNumber,
        street: orderInfo?.orders?.[orderIndex]?.deliveryInfo?.street === "" ? null : orderInfo?.orders?.[orderIndex]?.deliveryInfo?.street,
        addressComplement: orderInfo?.orders?.[orderIndex]?.deliveryInfo?.addressComplement === "" ? null : orderInfo?.orders?.[orderIndex]?.deliveryInfo?.addressComplement,
        postalCode: orderInfo?.orders?.[orderIndex]?.deliveryInfo?.postalCode === "" ? null : orderInfo?.orders?.[orderIndex]?.deliveryInfo?.postalCode,
        city: orderInfo?.orders?.[orderIndex]?.deliveryInfo?.city === "" ? null : orderInfo?.orders?.[orderIndex]?.deliveryInfo?.city,
        note: orderInfo?.orders?.[orderIndex]?.deliveryInfo?.textarea === "" ? null : orderInfo?.orders?.[orderIndex]?.deliveryInfo?.textarea,
        deliverySpecific: true
      }
    }
    else {
      deliveryInformation = {
        firstname: orderInfo?.deliveryInfo?.firstname === "" ? null : orderInfo?.deliveryInfo?.firstname,
        lastname: orderInfo?.deliveryInfo?.lastname === "" ? null : orderInfo?.deliveryInfo?.lastname,
        company: orderInfo?.deliveryInfo?.company === "" ? null : orderInfo?.deliveryInfo?.company,
        phone: orderInfo?.deliveryInfo?.phone === "" ? null : orderInfo?.deliveryInfo?.phone,
        phoneSecondary: orderInfo?.deliveryInfo?.phoneSecondary === "" ? null : orderInfo?.deliveryInfo?.phoneSecondary,
        streetNumber: orderInfo?.deliveryInfo?.streetNumber === "" ? null : orderInfo?.deliveryInfo?.streetNumber,
        street: orderInfo?.deliveryInfo?.street === "" ? null : orderInfo?.deliveryInfo?.street,
        addressComplement: orderInfo?.deliveryInfo?.addressComplement === "" ? null : orderInfo?.deliveryInfo?.addressComplement,
        postalCode: orderInfo?.deliveryInfo?.postalCode === "" ? null : orderInfo?.deliveryInfo?.postalCode,
        city: orderInfo?.deliveryInfo?.city === "" ? null : orderInfo?.deliveryInfo?.city,
        note: orderInfo?.deliveryInfo?.textarea === "" ? null : orderInfo?.deliveryInfo?.textarea,
        deliverySpecific: false
      }
    }

    return {
      id: order?.id,
      status: orderInfo?.status,
      prestationType: order.prestationType,
      format: order.format,
      commentaryForProd: order.commentaryForProd,
      commentaryForRecupProd: order.commentaryForRecupProd,
      customerSpecialReference: orderInfo.customerSpecialReference,
      customerQuotationCommentary: orderInfo.customerQuotationCommentary,
      customerSpecialCommentary: order?.customerSpecialCommentary || null,
      codeServiceChorus: orderInfo.codeServiceChorus,
      orderFormDate: orderInfo.orderFormDate ? moment(orderInfo.orderFormDate).format(
        "YYYY-MM-DD"
      ) : null,
      disableApproachingMail: orderInfo.disableApproachingMail === true || orderInfo.disableApproachingMail === "1" || orderInfo.disableApproachingMail === 1 ? 1 : 0,
      tender: orderInfo?.tender?.id ? orderInfo.tender.id.toString() : null,
      cart: {
        customer: orderInfo?.customer?.id ? orderInfo.customer.id.toString() : null,
        company: orderInfo?.company?.id ? orderInfo.company.id.toString() : null,
        articles: articles,
        deliveryInfo: {
          deliveryDate: deliveryDate,
          arrivedAtStart: deliveryArrivedAtStart,
          arrivedAtEnd: deliveryArrivedAtEnd,
          startAt: deliveryStartAt,
          endAt: deliveryEndAt,
          deliveryInformation:  orderInfo?.orders?.[orderIndex]?.deliveryInfo?.deliveryInformation ? orderInfo.orders[orderIndex].deliveryInfo.deliveryInformation : null,
          firstname: deliveryInformation?.firstname,
          lastname: deliveryInformation?.lastname,
          company: deliveryInformation?.company,
          phone: deliveryInformation?.phone,
          phoneSecondary: deliveryInformation?.phoneSecondary,
          streetNumber: deliveryInformation?.streetNumber,
          street: deliveryInformation?.street,
          addressComplement: deliveryInformation?.addressComplement,
          postalCode: deliveryInformation?.postalCode,
          city: deliveryInformation?.city,
          note: deliveryInformation?.note,
          recovery: "",
          service: orderInfo?.orders?.[orderIndex]?.deliveryInfo?.service === true || orderInfo?.orders?.[orderIndex]?.deliveryInfo?.service === "1" || orderInfo?.orders?.[orderIndex]?.deliveryInfo?.service === 1 ? 1 : 0,
          blanket: orderInfo?.orders?.[orderIndex]?.deliveryInfo?.blanket === true || orderInfo?.orders?.[orderIndex]?.deliveryInfo?.blanket === "1" || orderInfo?.orders?.[orderIndex]?.deliveryInfo?.blanket === 1 ? 1 : 0,
          type: 'v2',
          person: orderInfo?.orders?.[orderIndex]?.deliveryInfo?.person ? orderInfo.orders[orderIndex].deliveryInfo.person.toString() : null,
          deliveryCostHt: order.deliveryCost,
          deliverySpecific: deliveryInformation?.deliverySpecific === true ? 1 : 0,
        },
        priceInfo: priceInfo,
        billingInfo: {
          firstname: orderInfo?.billingInfo?.firstname === "" ? null : orderInfo?.billingInfo?.firstname,
          lastname: orderInfo?.billingInfo?.lastname === "" ? null : orderInfo?.billingInfo?.lastname,
          company: orderInfo?.billingInfo?.company === "" ? null : orderInfo?.billingInfo?.company,
          phone: orderInfo?.billingInfo?.phone === "" ? null : orderInfo?.billingInfo?.phone,
          address: orderInfo?.billingInfo?.fullAddress === "" ? null : orderInfo?.billingInfo?.fullAddress,
          company: orderInfo?.billingInfo?.company === "" ? null : orderInfo?.billingInfo?.company,
          moreInformation: orderInfo?.billingInfo.addressComplement === "" ? null : orderInfo?.billingInfo?.addressComplement,
        },
        customerInfo: {
          firstname: orderInfo?.billingInfo?.firstname === "" ? null : orderInfo?.billingInfo?.firstname,
          lastname: orderInfo?.billingInfo?.lastname === "" ? null : orderInfo?.billingInfo?.lastname,
          company: orderInfo?.billingInfo?.company === "" ? null : orderInfo?.billingInfo?.company,
          streetNumber: orderInfo?.billingInfo?.streetNumber === "" ? null : orderInfo?.billingInfo?.streetNumber,
          street: orderInfo?.billingInfo?.street === "" ? null : orderInfo?.billingInfo?.street,
          postalCode: orderInfo?.billingInfo?.postalCode === "" ? null : orderInfo?.billingInfo?.postalCode,
          city: orderInfo?.billingInfo?.city === "" ? null : orderInfo?.billingInfo?.city,
          addressComplement: orderInfo?.billingInfo?.addressComplement === "" ? null : orderInfo?.billingInfo?.addressComplement,
          phone: orderInfo?.billingInfo?.phone === "" ? null : orderInfo?.billingInfo?.phone,
        },
        promotions: order?.reduction && (order.reduction?.direct || order.reduction?.proportional) ? [{
          name: order.reduction?.label,
          amount: order.reduction?.direct === "" ? null : order.reduction.direct,
          percent: order.reduction?.proportional === "" ? null : order.reduction.proportional,
          ht: order?.reduction?.direct ? order.reduction.direct : (priceInfo?.totalHTWithoutReduction || 0 - priceInfo?.totalHT || 0),
        }] : null,
      }
    }
  });
};

export const breakfastOrderStoreToApi = (orderIndex, orderInfo) => {
  const deliveryDate = orderInfo?.orders?.[orderIndex]?.deliveryInfo?.deliveryDate
  ? moment(orderInfo.orders[orderIndex].deliveryInfo.deliveryDate, "YYYY/MM/DD").format("DD/MM/YYYY")
  : null;
  const billingAddress = `${orderInfo?.billingInfo?.streetNumber || ''} ${orderInfo?.billingInfo?.street || ''}, ${orderInfo?.billingInfo?.postalCode || ''}, ${orderInfo?.billingInfo.city || ''}`;
  const deliveryAddress = `${orderInfo?.deliveryInfo?.streetNumber || ''} ${orderInfo?.deliveryInfo?.street || ''}, ${orderInfo?.deliveryInfo?.postalCode || ''}, ${orderInfo?.deliveryInfo?.city || ''}`;

  const deliveryStartAt = orderInfo?.orders?.[orderIndex]?.deliveryInfo?.startAt ? orderInfo.orders[orderIndex].deliveryInfo.startAt : null;
  const deliveryEndAt = orderInfo?.orders?.[orderIndex]?.deliveryInfo?.endAt ? orderInfo.orders[orderIndex].deliveryInfo.endAt : null;
  const deliveryArrivedAtStart = orderInfo?.orders?.[orderIndex]?.deliveryInfo?.arrivedAtStart ? orderInfo.orders[orderIndex].deliveryInfo.arrivedAtStart : null;
  const deliveryArrivedAtEnd = orderInfo?.orders?.[orderIndex]?.deliveryInfo?.arrivedAtEnd ? orderInfo.orders[orderIndex].deliveryInfo.arrivedAtEnd : null;
  return {
    customer: orderInfo?.customer?.id ? orderInfo.customer.id.toString() : null,
    company: orderInfo?.company?.id ? orderInfo.company.id.toString() : null,
    delivery_date: deliveryDate,
    tender: orderInfo?.tender?.id ? orderInfo.tender.id.toString() : null,
    confirmTender: orderInfo?.confirmTender ? orderInfo.confirmTender : null,
    customerSpecialCommentary: orderInfo?.customerSpecialCommentary || null,
    disable_approaching_mail: orderInfo.disableApproachingMail === true || orderInfo.disableApproachingMail === "1" || orderInfo.disableApproachingMail === 1 ? 1 : 0,
    orderFormDate: orderInfo?.orderFormDate ? moment(
      orderInfo.orderFormDate,
      "YYYY/MM/DD"
    ).format("YYYY-MM-DD") : null,
    customer_special_reference: orderInfo.customerSpecialReference === "" ? null : orderInfo?.customerSpecialReference,
    codeServiceChorus: orderInfo.codeServiceChorus === "" ? null : orderInfo?.codeServiceChorus,
    customer_quotation_commentary:
      orderInfo?.customerQuotationCommentary || null,
    commentaryForProd: orderInfo.orders[orderIndex].commentaryForProd,
    commentaryForRecupProd: orderInfo.orders[orderIndex].commentaryForRecupProd,
    deliveryAddress: deliveryAddress === "" ? null : deliveryAddress,
    billingAddress: billingAddress === "" ? null : billingAddress,
    firstname: orderInfo?.billingInfo?.firstname === "" ? null : orderInfo?.billingInfo?.firstname,
    lastname: orderInfo?.billingInfo?.lastname === "" ? null : orderInfo?.billingInfo?.lastname,
    billingCompany: orderInfo?.company?.name === "" ? null : orderInfo?.company?.name,
    phone: orderInfo?.billingInfo?.phone === "" ? null : orderInfo?.billingInfo?.phone,
    address: billingAddress === "" ? null : billingAddress,
    street_number: orderInfo?.billingInfo?.streetNumber === "" ? null : orderInfo?.billingInfo?.streetNumber,
    street: orderInfo?.billingInfo?.street === "" ? null : orderInfo?.billingInfo?.street,
    address_complement: orderInfo?.billingInfo.addressComplement === "" ? null : orderInfo?.billingInfo?.addressComplement,
    postal_code: orderInfo?.billingInfo?.postalCode === "" ? null : orderInfo?.billingInfo?.postalCode,
    city: orderInfo?.billingInfo?.city === "" ? null : orderInfo?.billingInfo?.city,
    format: orderInfo.orders[orderIndex].format,
    participants_number: orderInfo?.orders[orderIndex]?.deliveryInfo?.person ? orderInfo.orders[orderIndex].deliveryInfo.person.toString() : null,
    status: orderInfo?.orders[orderIndex]?.status || '101',
    delivery: {
      arrived_at_start: deliveryArrivedAtStart,
      arrived_at_end: deliveryArrivedAtEnd,
      start_at: deliveryStartAt,
      end_at: deliveryEndAt,
      delivery_information: orderInfo.deliveryInfo.deliveryInformation === "" ? null : orderInfo?.deliveryInfo?.deliveryInformation,
      firstname: orderInfo?.deliveryInfo?.firstname === "" ? null : orderInfo?.deliveryInfo?.firstname,
      lastname: orderInfo?.deliveryInfo?.lastname === "" ? null : orderInfo?.deliveryInfo?.lastname,
      company: orderInfo?.deliveryInfo?.company === "" ? null : orderInfo?.deliveryInfo?.company,
      phone: orderInfo?.deliveryInfo?.phone === "" ? null : orderInfo?.deliveryInfo?.phone,
      phone_secondary: orderInfo?.customer?.delivery_phone_secondary === "" ? null : orderInfo?.customer?.delivery_phone_secondary,
      street_number: orderInfo?.deliveryInfo?.streetNumber === "" ? null : orderInfo?.deliveryInfo?.streetNumber,
      street: orderInfo?.deliveryInfo?.street === "" ? null : orderInfo?.deliveryInfo?.street,
      address_complement: orderInfo?.deliveryInfo?.addressComplement === "" ? null : orderInfo?.deliveryInfo?.addressComplement,
      postal_code: orderInfo?.deliveryInfo?.postalCode === "" ? null : orderInfo?.deliveryInfo?.postalCode,
      city: orderInfo?.deliveryInfo?.city === "" ? null : orderInfo?.deliveryInfo?.city,
      note: orderInfo?.deliveryInfo?.textarea === "" ? null : orderInfo?.deliveryInfo?.textarea,
      recovery: "",
      service: orderInfo?.orders?.[orderIndex]?.deliveryInfo?.service === true || orderInfo?.orders?.[orderIndex]?.deliveryInfo?.service === "1" || orderInfo?.orders?.[orderIndex]?.deliveryInfo?.service === 1 ? 1 : 0,
      blanket: orderInfo?.orders?.[orderIndex]?.deliveryInfo?.blanket === true || orderInfo?.orders?.[orderIndex]?.deliveryInfo?.blanket === "1" || orderInfo?.orders?.[orderIndex]?.deliveryInfo?.blanket === 1 ? 1 : 0,
    },
    formulas: orderInfo.orders[orderIndex].items
    .filter((item) => item.type === 'formula')
    .map((selectedFormula) => {
      const formulaComponents = selectedFormula.formula_elements.map(
        (element) => {
          return {
            id: element.breakfast_product.id.toString(),
            quantity: element.quantity_per_person.toString(),
            type: "menu",
          };
        }
      );
  
      return {
        name: selectedFormula.name,
        quantity: selectedFormula.quantity,
        price: selectedFormula.price,
        components: formulaComponents,
      };
    }),
    products: orderInfo.orders[orderIndex].items
    .filter((item) => item.type === 'product')
    .map((selectedProduct) => {
      return {
        quantity: selectedProduct.quantity,
        name: selectedProduct.name,
        price: selectedProduct.price,
        id: selectedProduct.id,
      };
    }),
    deliveryCost: orderInfo.orders[orderIndex].deliveryCost,
    reduction: orderInfo?.orders[orderIndex]?.reduction ? {
      label: orderInfo?.orders[orderIndex]?.reduction?.label || "",
      direct: orderInfo?.orders[orderIndex]?.reduction?.direct || 0,
      proportional: orderInfo?.orders[orderIndex]?.reduction?.proportional || 0,
    } : null,
  };
};

export const breakfastOrderApiToStore = (order) => {

  let orderState = {
    deliveryInfo: {
      deliveryDate: moment(order.delivery_date, "DD/MM/YYYY").format(
        "YYYY/MM/DD"
      ),
      arrivedAtStart: order.delivery.arrived_at_start,
      arrivedAtEnd: order.delivery.arrived_at_end,
      startAt: order.delivery.start_at,
      endAt: order.delivery.end_at,
      deliveryInformation: order.delivery.delivery_information,
      firstname: order.delivery.firstname,
      lastname: order.delivery.lastname,
      company: order.delivery.company,
      phone: order.delivery.phone,
      phoneSecondary: order.delivery.phone_secondary,
      streetNumber: order.delivery.street_number,
      street: order.delivery.street,
      addressComplement: order.delivery.address_complement,
      postalCode: order.delivery.postal_code,
      city: order.delivery.city,
      codeChorus: order.codeServiceChorus,
      customerQuotationCommentary: order.customer_quotation_commentary,
    },
    billingInfo: {
      firstname: order.firstname,
      lastname: order.lastname,
      company: order.billingCompany,
      streetNumber: order.street_number,
      street: order.street,
      postalCode: order.postal_code,
      city: order.city,
      addressComplement: order.address_complement,
      phone: order.phone,
    },
    customer: order.customer ? {
      id: order.customer.id,
      firstname: order.customer.firstname,
      lastname: order.customer.lastname
    } : null,
    company: order.company ? {
      id: order.company.id,
      name: order.company.name,
    } : null,
    tender: order.tender ? {
      id: order.tender.id,
      name: order.tender.name,
    } : null,
    currentOrder: {},
    currentOrderIndex: null,
    customerSpecialCommentary: order.customer_quotation_commentary,
    customerSpecialReference: order.customer_special_reference,
    codeServiceChorus: order.code_service_chorus,
    orderFormDate: order.order_form_date ? moment(order.order_form_date).format(
      "YYYY-MM-DD"
    ) : null,
    disableApproachingMail: order.disable_approaching_mail === true || order.disable_approaching_mail === "1" || order.disable_approaching_mail=== 1,
    confirmTender: order.id ? true : false,
    orders: [
      {
        id: order.id,
        version: order.version,
        status: order.status,
        format: order.format,
        prestationType: 'breakfast',
        commentaryForProd: order.commentary_for_prod,
        commentaryForRecupProd: order.commentary_for_recup_prod,
        priceInfo: {
          totalHT: order.total_price
        },
        reduction: order.components
        .reduce((acc, component) => {
          if (component.type === 'Promotion') {
            return {
              label: component.name,
              direct: -1 * (acc.direct + component.price),
              proportional: 0,
            }
          }
          return acc;
        }, {
          label: "",
          direct: 0,
          proportional: 0,
        }),
        deliveryCost: order.components
        .reduce((acc, component) => {
          if (component.type === 'Delivery') {
            return acc + component.price;
          }
          return acc;
        }, 0),
        deliveryInfo: {
          startAt: order.delivery.start_at ? moment.parseZone(order.delivery.start_at).format("HH:mm") : "",
          endAt: order.delivery.end_at ? moment.parseZone(order.delivery.end_at).format("HH:mm") : "",
          deliveryDate: moment(order.delivery_date).format(
            "YYYY-MM-DD"
          ),
          person: order.participants_number,
          arrivedAtStart: order.delivery.arrived_at_start ? moment.parseZone(order.delivery.arrived_at_start).format("HH:mm") : "",
          arrivedAtEnd: order.delivery.arrived_at_end ? moment.parseZone(order.delivery.arrived_at_end).format("HH:mm") : "",
          service: order.delivery.service === true || order.delivery.service === "1" || order.delivery.service === 1,
          blanket: order.delivery.blanket === true || order.delivery.blanket === "1" || order.delivery.blanket === 1,
        },
        items: order.components
        .reduce((acc, component) => {

          if (component.type === 'menu') {
            // Trouver l'élément existant dans le tableau accumulé
            let existingItem = acc.find(item => item.name === component.formula_name && item.price === component.formula_price && item.quantity === component.formula_quantity);
          
            // Si l'élément existe déjà, ajoutez simplement le nouveau composant à formula_elements
            if (existingItem) {
              existingItem.formula_elements.push({
                breakfast_product: {
                  id: component.product_id,
                },
                quantity_per_person: component.quantity,
              });
            } else {
              // Sinon, créez un nouvel élément et ajoutez-le à l'accumulateur
              acc.push({
                name: component.formula_name,
                price: component.formula_price,
                quantity: component.formula_quantity,
                type: 'formula',
                formula_elements: [{
                  breakfast_product: {
                    id: component.product_id,
                  },
                  quantity_per_person: component.quantity,
                }],
              });
            }
          }
          else {
            // Ne prend que les composants de type supplement et special. Les autres (Delivery, Promotion sont traités autres part)
            if (component.type === 'supplement' || component.type === 'special') {
              acc.push({
                name: component.name,
                price: component.price,
                quantity: component.quantity,
                type: 'product',
                id: component.product_id,
              });
            }
          }
        
          return acc;
        }, []),
        info: order.info
      },
    ],
  };

  const totals = orderStateToTotal(orderState.orders[0]);
  orderState.orders[0].totalHT = totals.totalHT;

  return orderState;
}

export const groupedOrderApiToStore = (groupedOrder) => {
  let firstOrder = groupedOrder.orders[0];

  // Pour les infos de livraison, prends les infos de la première commande non spécifique
  let deliveryInfo = {
    firstname: firstOrder.delivery.firstname,
    lastname: firstOrder.delivery.lastname,
    company: firstOrder.delivery.company,
    phone: firstOrder.delivery.phone,
    phoneSecondary: firstOrder.delivery.phone_secondary,
    streetNumber: firstOrder.delivery.street_number,
    street: firstOrder.delivery.street,
    addressComplement: firstOrder.delivery.address_complement,
    postalCode: firstOrder.delivery.postal_code,
    city: firstOrder.delivery.city,
    codeChorus: firstOrder.codeServiceChorus
  };
  groupedOrder.orders.forEach((order) => {
    if (order.delivery.delivery_specific !== true) {
      deliveryInfo = {
        firstname: order.delivery.firstname,
        lastname: order.delivery.lastname,
        company: order.delivery.company,
        phone: order.delivery.phone,
        phoneSecondary: order.delivery.phone_secondary,
        streetNumber: order.delivery.street_number,
        street: order.delivery.street,
        addressComplement: order.delivery.address_complement,
        postalCode: order.delivery.postal_code,
        city: order.delivery.city,
        codeChorus: order.codeServiceChorus
      };
      return;
    }
  });
  let orderState = {
    status: groupedOrder.status,
    deliveryInfo: deliveryInfo,
    billingInfo: {
      firstname: firstOrder.billing_info.firstname,
      lastname: firstOrder.billing_info.lastname,
      company: firstOrder.billing_info.company,
      streetNumber: firstOrder.billing_info.street_number,
      street: firstOrder.billing_info.street,
      postalCode: firstOrder.billing_info.postal_code,
      city: firstOrder.billing_info.city,
      addressComplement: firstOrder.billing_info.more_information,
      phone: firstOrder.billing_info.phone,
      fullAddress: firstOrder.billing_info.address,
    },
    customer: firstOrder.customer ? {
      id: firstOrder.customer.id,
      firstname: firstOrder.customer.firstname,
      lastname: firstOrder.customer.lastname
    } : null,
    company: firstOrder.company ? {
      id: firstOrder.company.id,
      name: firstOrder.company.name,
    } : null,
    tender: firstOrder.tender ? {
      id: firstOrder.tender.id,
      name: firstOrder.tender.name,
    } : null,
    currentOrder: {},
    customerSpecialReference: firstOrder?.customer_special_reference || null,
    codeServiceChorus: firstOrder?.code_service_chorus || null,
    customerQuotationCommentary: firstOrder?.customer_quotation_commentary || null,
    orderFormDate: firstOrder.order_form_date ? moment(firstOrder.order_form_date).format(
      "YYYY-MM-DD"
    ) : null,
    disableApproachingMail: firstOrder.disable_approaching_mail === true || firstOrder.disable_approaching_mail === "1" || firstOrder.disable_approaching_mail=== 1,
    confirmTender: true,
    orders: groupedOrder.orders.map((order) => {
      const specificDelivery = order?.delivery?.delivery_specific ? {
        firstname: order.delivery.firstname,
        lastname: order.delivery.lastname,
        company: order.delivery.company,
        phone: order.delivery.phone,
        phoneSecondary: order.delivery.phone_secondary,
        streetNumber: order.delivery.street_number,
        street: order.delivery.street,
        addressComplement: order.delivery.address_complement,
        postalCode: order.delivery.postal_code,
        city: order.delivery.city,
        note: order.delivery.note,
      } : {};
      return {
        id: order.id,
        version: order.version,
        prestationType: order.prestation_type,
        status: order.status,
        format: order.format,
        commentaryForProd: order?.commentary_for_prod || null,
        commentaryForRecupProd: order?.commentary_for_recup_prod || null,
        customerSpecialCommentary: order?.customer_special_commentary || null,
        priceInfo: {
          totalHT: order?.price_info?.total_price_ht || 0,
          totalHTWithoutReduction: order?.price_info?.total_price_ht_without_promotion || 0,
          catererPackagePiecesPriceHtPerParticipant: order?.price_info?.caterer_package_pieces_price_ht_per_participant || null,
          catererPackageSoftsPriceHtPerParticipant: order?.price_info?.caterer_package_softs_price_ht_per_participant || null,
          catererPackageAlcoholsPriceHtPerParticipant: order?.price_info?.caterer_package_alcohols_price_ht_per_participant || null,
        },
        reduction: order?.promotions ? order.promotions
        .reduce((acc, reduction) => {
          return {
            label: reduction.name,
            direct: reduction?.amount || null,
            proportional: reduction?.percent * 100 || null,
          }
        }, {
          label: null,
          direct: null,
          proportional: null,
        }) : {
          label: null,
          direct: null,
          proportional: null,
        },
        deliveryCost: order?.delivery?.delivery_cost_ht || 0,
        deliveryInfo: {
          startAt: order.delivery.start_at ? moment.parseZone(order.delivery.start_at).format("HH:mm") : "",
          endAt: order.delivery.end_at ? moment.parseZone(order.delivery.end_at).format("HH:mm") : "",
          deliveryDate: moment(order.delivery.delivery_date).format(
            "YYYY-MM-DD"
          ),
          person: order.participants_number,
          arrivedAtStart: order.delivery.arrived_at_start ? moment.parseZone(order.delivery.arrived_at_start).format("HH:mm") : "",
          arrivedAtEnd: order.delivery.arrived_at_end ? moment.parseZone(order.delivery.arrived_at_end).format("HH:mm") : "",
          service: order.delivery.service === true || order.delivery.service === "1" || order.delivery.service === 1,
          blanket: order.delivery.blanket === true || order.delivery.blanket === "1" || order.delivery.blanket === 1,
          deliveryInformation: order?.delivery?.delivery_information || null,
          deliverySpecific: order?.delivery?.delivery_specific || false,
          ...specificDelivery
        },
        items: order.articles
        .reduce((acc, article) => {

          if (article.item_type === 'group') {
            acc.push({
              name: article.name,
              renammed: article?.renamed_name || null,
              price: article.price,
              quantity: article.quantity,
              type: 'productGroup',
              group_id: article.group_id,
              article_id: article.id,
              classification_name: article.category,
              caterer_price_group: article.caterer_price_group,
              products: article.children.map((child) => ({
                id: child.id,
                name: child.name,
                quantity: child.quantity,
                product_id: child.product_id,
                article_id: child.id,
                sweet: child.sweet,
                salty: child.salted,
                alcohol: child.alcohol,
                soft: child.soft
              })),
            });
          }
          else if (article.item_type === 'package') {
            acc.push({
              name: article.name,
              renammed: article?.renamed_name || null,
              price: order.prestation_type === 'caterer' ? article.indicative_price : article.price,
              quantity: article.quantity,
              type: 'productPackage',
              package_id: article.package_id,
              article_id: article.id,
              classification_name: article.category,
              caterer_price_group: article.caterer_price_group,
              products: article.children.map((child) => {
                if (child.item_type === 'product') {
                  return {
                    id: child.id,
                    name: child.name,
                    quantity: child.quantity,
                    product_id: child.product_id,
                    article_id: child.id,
                    sweet: child.sweet,
                    salty: child.salted,
                    alcohol: child.alcohol,
                    soft: child.soft,
                  }
                }
                return null;
              })
              .filter((child) => child !== null),
              product_parts: article.children.map((child) => {
                if (child.item_type === 'productPart') {
                  return {
                    id: child.id,
                    name: child.name,
                    quantity: child.quantity,
                    product_part_id: child.product_part_id,
                    article_id: child.id,
                    sweet: child.sweet,
                    salty: child.salted,
                    alcohol: child.alcohol,
                    soft: child.soft,
                  }
                }
                return null;
              })
              .filter((child) => child !== null),
            });
          }
          else {
            acc.push({
              name: article.name,
              renammed: article?.renamed_name || null,
              price: article.price,
              quantity: article.quantity,
              type: article.item_type,
              product_id: article.product_id || null,
              product_part_id: article.product_part_id || null,
              article_id: article.id,
              classification_name: article.category,
              caterer_name: article?.caterer_name || null,
              sweet: article.sweet,
              salty: article.salted,
              alcohol: article.alcohol,
              soft: article.soft,
            });
          }
        
          return acc;
        }, []),
        info: order.info
      }
    }),
  };
  return orderState;
}
