import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
import swal from "sweetalert";
import { addCompany } from "state/company/actions";
import {
  setCompany,
} from "state/orderEdition/actions";
import { ATSCompany } from "transformer/company/companyTransformer";

const AddCompanyComponent = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const customers = useSelector((state) => state.adminData.customers || []);
  const addresses = useSelector((state) => state.adminData.addresses || []);
  const companies = useSelector((state) => state.adminData.companies || []);

  const customersFormat = customers.map((customer) => ({
    value: customer.id,
    label: customer.email,
    ...customer,
  }));

  const addressesFormat = addresses.map((address) => ({
    value: address.id,
    label: address.name_address,
    ...address,
  }));

  const companiesFormat = companies.map((company) => ({
    value: company.id,
    label: company.name,
    ...company,
  }));

  const [companyInfo, setCompanyInfo] = useState({
    name: "",
    billOnChorus: false,
    domain: "",
    siret: "",
    email: "",
    phone: "",
    customers: [],
    billingAddress: "",
    deliveryAddresses: [],
    domains: [],
    parent: null,
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setCompanyInfo({
      ...companyInfo,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSelectChange = (selectedOptions, { name }) => {
    setCompanyInfo({
      ...companyInfo,
      [name]: selectedOptions,
    });
  };

  // Ajoutez cette fonction pour gérer les changements dans le tableau de domaines
  const handleDomainsChange = (index, value) => {
    const newDomains = [...companyInfo.domains];
    newDomains[index] = value;
    setCompanyInfo({
      ...companyInfo,
      domains: newDomains,
    });
  };

  // Ajoutez cette fonction pour ajouter un nouveau champ de domaine
  const addDomainField = () => {
    setCompanyInfo({
      ...companyInfo,
      domains: [...companyInfo.domains, ""],
    });
  };

  // Ajoutez cette fonction pour supprimer un champ de domaine
  const removeDomainField = (index) => {
    const newDomains = companyInfo.domains.filter((_, i) => i !== index);
    setCompanyInfo({
      ...companyInfo,
      domains: newDomains,
    });
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const companyData = {
        ...companyInfo,
        customers: companyInfo.customers.map((customer) => customer.value),
        domains: companyInfo.domains.map((domain) => {
          return {domain: domain};
        }),
        parent: companyInfo?.parent?.id,
        billingAddress: companyInfo?.billingAddress?.id,
        deliveryAddresses: companyInfo.deliveryAddresses.map((address) => address.id),
      };
      await dispatch(addCompany(companyData)).then(async (resp) => {
        setIsLoading(false);
        let msg = "L'entreprise a été ajoutée avec succès";
        if (resp?.result?.success === false || resp?.success === false) {
          msg = resp?.result?.message || resp?.message || "Une erreur est survenue";
          swal({
            title: "Attention ",
            text: msg,
            icon: "warning",
            dangerMode: true,
          });
        } else {
          // Si ok, récupère le dernier client dans le listing
          const company = ATSCompany(companyData);
          await dispatch(setCompany(company));
          handleClose();
          swal({
            title: "Information",
            text: msg,
            icon: "success",
            dangerMode: false,
          });
        }
      });
    } catch (err) {
      setIsLoading(false);
      swal({
        title: "Attention ",
        text: err?.message || "Une erreur est survenue",
        icon: "warning",
        dangerMode: true,
      });
    }
  };

  return (
    <>
      <Button variant="success" onClick={handleShow}>
        +
      </Button>

      <Modal show={show} onHide={handleClose} dialogClassName="modal-xl">
        <Modal.Header closeButton>
          <Modal.Title>Ajouter une entreprise</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <div>Enregistrement en cours..</div>
          ) : (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Nom de l'entreprise*</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={companyInfo.name}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>A facturer sur chorus</Form.Label>
                    <Form.Check
                      type="checkbox"
                      name="billOnChorus"
                      checked={companyInfo.billOnChorus}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Nom de domaine PRINCIPAL de l'entreprise (sans @)*</Form.Label>
                    <Form.Control
                      type="text"
                      name="domain"
                      value={companyInfo.domain}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>SIRET</Form.Label>
                    <Form.Control
                      type="text"
                      name="siret"
                      value={companyInfo.siret}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Email (pour la facturation)</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={companyInfo.email}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Téléphone de l'entreprise</Form.Label>
                    <Form.Control
                      type="text"
                      name="phone"
                      value={companyInfo.phone}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Clients</Form.Label>
                    <Select
                      options={customersFormat}
                      isMulti
                      name="customers"
                      value={companyInfo.customers}
                      onChange={handleSelectChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Adresse de facturation</Form.Label>
                    <Select
                      options={addressesFormat}
                      name="billingAddress"
                      value={companyInfo.billingAddress}
                      onChange={handleSelectChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Adresses de livraison</Form.Label>
                    <Select
                      options={addressesFormat}
                      isMulti
                      name="deliveryAddresses"
                      value={companyInfo.deliveryAddresses}
                      onChange={handleSelectChange}
                    />
                  </Form.Group>
                  <Form.Group>
                  <Form.Label>Autres noms de domaine</Form.Label>
                  {companyInfo.domains.map((domain, index) => (
                    <div key={index} className="d-flex mb-2">
                      <Form.Control
                        type="text"
                        value={domain}
                        onChange={(e) => handleDomainsChange(index, e.target.value)}
                      />
                      <Button
                        variant="danger"
                        onClick={() => removeDomainField(index)}
                        className="ml-2"
                      >
                        Supprimer
                      </Button>
                    </div>
                  ))}
                  <Button variant="secondary" onClick={addDomainField}>
                    Ajouter un domaine
                  </Button>
                </Form.Group>
                  <Form.Group>
                    <Form.Label>Société mère</Form.Label>
                    <Select
                      options={companiesFormat}
                      name="parent"
                      value={companyInfo.parent}
                      onChange={handleSelectChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Button variant="success" type="submit" block size="lg" className="mt-3">
                Enregistrer
              </Button>
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddCompanyComponent;