import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import { setDeliveryInfo as setDeliveryInfoState, setOrderDeliveryInfo } from "state/orderEdition/actions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import Select from "react-select";

const DeliveryInfoComponent = ({ title, orderIndex }) => {

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const addresses = useSelector((state) => state?.adminData?.addresses || []);

  const order = useSelector((state) => {
    if (orderIndex === undefined) {
      return null;
    } else {
      return state.orderEdition.orders[orderIndex];
    }
  });
  const [deliveryInformationSpecific, setDeliveryInformationSpecific] = useState(order?.deliveryInfo?.deliverySpecific || false);

  const deliveryInfoState = useSelector((state) => {
    if (orderIndex === undefined) {
      return state.orderEdition.deliveryInfo;
    } else {
      return state.orderEdition.orders[orderIndex]?.deliveryInfo;
    }
  });

  const [deliveryInfo, setDeliveryInfo] = useState({
    lastname: deliveryInfoState?.lastname || "",
    firstname: deliveryInfoState?.firstname || "",
    company: deliveryInfoState?.company || "",
    phone: deliveryInfoState?.phone || "",
    phoneSecondary: deliveryInfoState?.phoneSecondary || "",
    streetNumber: deliveryInfoState?.streetNumber || "",
    street: deliveryInfoState?.street || "",
    postalCode: deliveryInfoState?.postalCode || "",
    city: deliveryInfoState?.city || "",
    addressComplement: deliveryInfoState?.addressComplement || "",
  });

  useEffect(() => {
    setDeliveryInformationSpecific(order?.deliveryInfo?.deliverySpecific || false);
    setDeliveryInfo({
      lastname: deliveryInfoState?.lastname || "",
      firstname: deliveryInfoState?.firstname || "",
      company: deliveryInfoState?.company || "",
      phone: deliveryInfoState?.phone || "",
      phoneSecondary: deliveryInfoState?.phoneSecondary || "",
      streetNumber: deliveryInfoState?.streetNumber || "",
      street: deliveryInfoState?.street || "",
      postalCode: deliveryInfoState?.postalCode || "",
      city: deliveryInfoState?.city || "",
      addressComplement: deliveryInfoState?.addressComplement || "",
    });
  }, [order, deliveryInfoState]);

  const handleSelectAddressChange = (event) => {
    
    setSelectedOptions(event);
    
    const selectedAddress = addresses.find(
      (adresse) => adresse.id === Number(event.value)
    );
    if (selectedAddress) {
      setDeliveryInfo({
        ...deliveryInfo,
        streetNumber: selectedAddress?.street_number || "",
        street: selectedAddress?.street || "",
        postalCode: selectedAddress?.postal_code || "",
        city: selectedAddress?.city || "",
        addressComplement: selectedAddress?.address_complement || "",
      });
    }
  };

  const handleDeliveryInformationSpecific = (e) => {
    setDeliveryInformationSpecific(e.target.checked);
    const deliveryInfo = order.deliveryInfo;
    dispatch(
      setOrderDeliveryInfo(orderIndex, {
        ...deliveryInfo,
        deliverySpecific: e.target.checked
      })
    );
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    setDeliveryInfo({
      ...deliveryInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (orderIndex === undefined) {
      dispatch(
        setDeliveryInfoState({
          ...deliveryInfoState,
          ...deliveryInfo,
        })
      );
    } else {
      dispatch(setOrderDeliveryInfo(orderIndex, { ...deliveryInfoState, ...deliveryInfo }));
    }
    handleClose();
  };

  // On format addresses pour react-select
  const addressesFormat = addresses.map((item) => ({ value: item.id, label: item.name_address, ...item }));
  const [optionSelected, setSelectedOptions] = useState([]);

  return (
    <>
      {orderIndex !== undefined && <h5><input type="checkbox" id="deliveryInformationSpecific" name="deliveryInformationSpecific" required onChange={handleDeliveryInformationSpecific} checked={deliveryInformationSpecific} /> Information de livraison spécifique</h5>}
      {(deliveryInformationSpecific || orderIndex === undefined) && (
        <>
          <Modal show={show} onHide={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Informations de livraison</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Label>Carnet d'adresses</Form.Label>
                  <Select
                    options={addressesFormat}
                    closeMenuOnSelect={true}
                    onChange={handleSelectAddressChange}
                    value={optionSelected}
                    name={"address"}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Nom</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastname"
                    value={deliveryInfo.lastname}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Prénom</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstname"
                    value={deliveryInfo.firstname}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Entreprise</Form.Label>
                  <Form.Control
                    type="text"
                    name="company"
                    value={deliveryInfo.company}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Téléphone</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone"
                    value={deliveryInfo.phone}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Téléphone secondaire</Form.Label>
                  <Form.Control
                    type="text"
                    name="phoneSecondary"
                    value={deliveryInfo.phoneSecondary}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Numéro de rue</Form.Label>
                  <Form.Control
                    type="text"
                    name="streetNumber"
                    value={deliveryInfo.streetNumber}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Rue</Form.Label>
                  <Form.Control
                    type="text"
                    name="street"
                    value={deliveryInfo.street}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Code Postal</Form.Label>
                  <Form.Control
                    type="text"
                    name="postalCode"
                    value={deliveryInfo.postalCode}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Ville</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    value={deliveryInfo.city}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Complément d'adresse</Form.Label>
                  <Form.Control
                    type="text"
                    name="addressComplement"
                    value={deliveryInfo.addressComplement}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Button variant="primary" type="submit">
                  Enregistrer
                </Button>
              </Form>
            </Modal.Body>
          </Modal>

          <>
            <Row>
              <Col xs={7} sm={7} md={7}>
                <h5>{title}</h5>
              </Col>
              <Col xs={5} sm={5} md={5} className="text-right">
                <Button variant="success" size="sm" onClick={handleShow}>
                  <FontAwesomeIcon icon={faPen} />
                </Button>
              </Col>
            </Row>
            
            <p>
              {deliveryInfo.lastname || "Nom"}{" "}
              {deliveryInfo.firstname || "Prénom"}
              <br />
              {deliveryInfo.company || "Entreprise"}
              <br />
              {deliveryInfo.phone || "Tel."}
              <br />
              {deliveryInfo.phoneSecondary || "Tel. sec."}
              <br />
              {deliveryInfo.streetNumber || "Num."} {deliveryInfo.street || "Rue"}
              <br />
              {deliveryInfo.postalCode || "Code postal"}{" "}
              {deliveryInfo.city || "Ville"}
              <br />
              {deliveryInfo.addressComplement || "Complém."}
            </p>
          </>
        </>
      )}
    </>
  );
};

export default DeliveryInfoComponent;
