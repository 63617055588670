import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import { setBillingInfo as setBillingInfoState } from "state/orderEdition/actions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faPen } from '@fortawesome/free-solid-svg-icons'
import Select from "react-select";

const BillingInfoComponent = ({ title, showCompany, dispatchAddress }) => {
  const dispatch = useDispatch();
  const deliveryInfoState = useSelector(
    (state) => state.orderEdition.deliveryInfo
  );
  const billingInfoState = useSelector(
    (state) => state.orderEdition.billingInfo
  );
  const addresses = useSelector((state) => state?.adminData?.addresses || []);
  const [show, setShow] = useState(false);
  const [billingInfo, setBillingInfo] = useState({
    lastname: billingInfoState?.lastname || "",
    firstname: billingInfoState?.firstname || "",
    phone: billingInfoState?.phone || "",
    streetNumber: billingInfoState?.streetNumber || "",
    street: billingInfoState?.street || "",
    postalCode: billingInfoState?.postalCode || "",
    city: billingInfoState?.city || "",
    addressComplement: billingInfoState?.addressComplement || "",
    company: billingInfoState?.company || "",
  });

  useEffect(() => {
    if (billingInfoState) {
      setBillingInfo(billingInfoState);
    }
  }, [billingInfoState]);

  const copyDeliveryInfo = () => {
    const fullAddress = deliveryInfoState?.streetNumber + " " + deliveryInfoState?.street + " " + deliveryInfoState?.postalCode + " " + deliveryInfoState?.city;
    setBillingInfo({
      ...billingInfo,
      phone: deliveryInfoState?.phone|| "",
      addressComplement: deliveryInfoState?.addressComplement || "",
      fullAddress: fullAddress,
      company: deliveryInfoState?.company || "",
      streetNumber: deliveryInfoState?.streetNumber || "",
      street: deliveryInfoState?.street || "",
      postalCode: deliveryInfoState?.postalCode || "",
      city: deliveryInfoState?.city || "",
    });
    dispatch(
      setBillingInfoState({
        ...billingInfo,
        phone: deliveryInfoState?.phone|| "",
        addressComplement: deliveryInfoState?.addressComplement || "",
        fullAddress: fullAddress,
        company: deliveryInfoState?.company || "",
        streetNumber: deliveryInfoState?.streetNumber || "",
        street: deliveryInfoState?.street || "",
        postalCode: deliveryInfoState?.postalCode || "",
        city: deliveryInfoState?.city || "",
      })
    );
  };

  const handleSelectAddressChange = (event) => {
    const selectedAddress = addresses.find(
      (adresse) => adresse.id === Number(event.value)
    );
    if (selectedAddress) {
      const fullAddress = selectedAddress?.street_number + " " + selectedAddress?.street + " " + selectedAddress?.postal_code + " " + selectedAddress?.city;
      setBillingInfo({
        ...billingInfo,
        phone: selectedAddress?.phone|| "",
        addressComplement: selectedAddress?.address_complement || "",
        fullAddress: fullAddress,
        company: selectedAddress?.company || "",
        streetNumber: selectedAddress?.street_number || "",
        street: selectedAddress?.street || "",
        postalCode: selectedAddress?.postal_code || "",
        city: selectedAddress?.city || "",
        addressComplement: selectedAddress?.address_complement || "",
      });
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    setBillingInfo({
      ...billingInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    if (e){
      e.preventDefault();
    }
    dispatch(
      setBillingInfoState({
        ...billingInfoState,
        ...billingInfo,
      })
    );
    handleClose();
  };

  // On format addresses pour react-select
  const addressesFormat = addresses.map((item) => ({ value: item.id, label: item.name_address, ...item }));
  const [optionSelected, setSelectedOptions] = useState([]);

  return (
    <>
      <Modal show={show} onHide={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Informations de facturation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Carnet d'adresses</Form.Label>
              <Select
                options={addressesFormat}
                closeMenuOnSelect={true}
                onChange={handleSelectAddressChange}
                value={optionSelected}
                name={"address"}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Nom</Form.Label>
              <Form.Control
                type="text"
                name="lastname"
                value={billingInfo.lastname}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Prénom</Form.Label>
              <Form.Control
                type="text"
                name="firstname"
                value={billingInfo.firstname}
                onChange={handleChange}
              />
            </Form.Group>
            {showCompany && <Form.Group>
              <Form.Label>Entreprise</Form.Label>
              <Form.Control
                type="text"
                name="company"
                value={billingInfo.company}
                onChange={handleChange}
              />
            </Form.Group>}
            <Form.Group>
              <Form.Label>Téléphone</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={billingInfo.phone}
                onChange={handleChange}
              />
            </Form.Group>
            {dispatchAddress ?
            <>
              <Form.Group>
                <Form.Label>Numéro de rue</Form.Label>
                <Form.Control
                  type="text"
                  name="streetNumber"
                  value={billingInfo.streetNumber}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Rue</Form.Label>
                <Form.Control
                  type="text"
                  name="street"
                  value={billingInfo.street}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Code Postal</Form.Label>
                <Form.Control
                  type="text"
                  name="postalCode"
                  value={billingInfo.postalCode}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Ville</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  value={billingInfo.city}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Complément d'adresse</Form.Label>
                <Form.Control
                  type="text"
                  name="addressComplement"
                  value={billingInfo.addressComplement}
                  onChange={handleChange}
                />
              </Form.Group>
            </>
            :
            <>
              <Form.Group>
                <Form.Label>Adresse complète</Form.Label>
                <Form.Control
                  type="text"
                  name="fullAddress"
                  value={billingInfo.fullAddress}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Complément d'adresse</Form.Label>
                <Form.Control
                  type="text"
                  name="addressComplement"
                  value={billingInfo.addressComplement}
                  onChange={handleChange}
                />
              </Form.Group>
            </>
            }
            <Button variant="primary" type="submit">
              Enregistrer
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <>
        <Row>
          <Col xs={6} sm={6} md={6}>
            <h5>{title}</h5>
          </Col>
          <Col xs={6} sm={6} md={6} className="text-right">
            <Button variant="success" size="sm" onClick={copyDeliveryInfo}>
              <FontAwesomeIcon icon={faCopy} />
            </Button> &nbsp;
            <Button variant="success" size="sm" onClick={handleShow}>
            <FontAwesomeIcon icon={faPen} />
            </Button>
          </Col>
        </Row>
        <p>
          {billingInfo.lastname || "Nom"} {billingInfo.firstname || "Prénom"}
          <br />
          {billingInfo.company || "Entreprise"}
          <br />
          {billingInfo.phone || "Tel."}
          <br />
          {dispatchAddress ?
            <>
              {billingInfo.streetNumber || "N."}{" "}
              {billingInfo.street || "Rue"}
              <br />
              {billingInfo.postalCode || "Code postal"}{" "}
              {billingInfo.city || "Ville"}
            </>
          :
            <>
              {billingInfo.fullAddress || "Adresse"}
            </>
          }
          <br />
          {billingInfo.addressComplement || "Complém."}
        </p>
      </>
    </>
  );
};

export default BillingInfoComponent;
