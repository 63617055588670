import React  from 'react'
import {Container, Row, Col} from 'react-bootstrap'

import Orders from 'app/orderTool/pages/manageOrders/Orders'
import OrderSidebar from 'app/orderTool/components/sidebar/OrderSidebar'
import Layout from 'app/universal/layout/Layout'
import SidebarInfo from 'app/universal/layout/sidebar/SidebarInfo'

import OrdersProvider from 'app/orderTool/context/OrdersProvider'

const ManageOrders = (props) => {

  return (
    <OrdersProvider>
      <Layout reduxIsUsed={true}>
        <SidebarInfo component={<OrderSidebar />} >
          <Container fluid={true} >
            <Row noGutters>
              <Col xs={12} sm={12} >
                <Orders router={props.history}/>
              </Col>
            </Row>
          </Container>
        </SidebarInfo>
      </Layout>
    </OrdersProvider>

  )

}

export default ManageOrders
