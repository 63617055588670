import React from 'react';
import CurrencyComponent from '../../CurrencyComponent';
import { centimesToCurrency } from "helper/globalHelper";

const EditableCell = ({ value, onChange, editable }) => {

  const editableStyle = {
    cursor: 'pointer',
    backgroundColor: '#f7f9fc',
    border: '1px dashed #007bff',
    padding: '5px',
  };

  const greyCellStyle = {
    backgroundColor: '#d3d3d3',
    padding: '5px',
  };

  const cellStyle = value === null ? greyCellStyle : (editable ? editableStyle : {});

  return (
    editable ?
    <td style={cellStyle}>
      <CurrencyComponent
        initialPrice={value}
        onPriceChange={onChange}
        autoFocus={true}
      />
    </td>
    :
    <td style={cellStyle}>
      {value !== null ? centimesToCurrency(value) : ''}
    </td>
  );
};

export default EditableCell;