import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import { addCustomer } from "state/customer/actions";
import {
  setCustomer,
  setCompany,
  setBillingInfo,
  setDeliveryInfo,
} from "state/orderEdition/actions";
import Select from "react-select";
import swal from "sweetalert";
import { ATSCustomer } from "transformer/customer/customerTransformer";

const AddCustomerComponent = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const billingInfo = useSelector((state) => state.orderEdition.billingInfo);

  const companies = useSelector((state) =>
    state.adminData.companies ? state.adminData.companies : []
  );

  const addresses = useSelector((state) =>
    state.adminData.addresses ? state.adminData.addresses : []
  );

  const companiesFormat = companies.map((company) => ({
    value: company.id,
    label: company.name,
    ...company,
  }));

  const customer = {};

  const [customerInfo, setCustomerInfo] = useState({
    disableUnpaidReminder: false,
    type: "normal",
    email: customer?.email || "",
    companyBis: customer?.companyBis?.id || "",
    companyBisName: customer?.companyBis?.name || "",
    contactFirstname: customer?.contactFirstname || "",
    contactLastname: customer?.contactLastname || "",
    contactPhone: customer?.contactPhone || "",
    contactPhoneDirect: customer?.contactPhoneDirect || "",
    firstname: customer?.firstname || "",
    lastname: customer?.lastname || "",
    phone: customer?.phone || "",
    company: customer?.company || "",
    street_number: customer?.street_number || "",
    street: customer?.street || "",
    address_complement: customer?.address_complement || "",
    postal_code: customer?.postal_code || "",
    city: customer?.city || "",
    delivery_firstname: customer?.delivery_firstname || "",
    delivery_lastname: customer?.delivery_lastname || "",
    delivery_phone: customer?.delivery_phone || "",
    delivery_phone_secondary: customer?.delivery_phone_secondary || "",
    delivery_company: customer?.delivery_company || "",
    delivery_street_number: customer?.delivery_street_number || "",
    delivery_street: customer?.delivery_street || "",
    delivery_address_complement: customer?.delivery_address_complement || "",
    delivery_postal_code: customer?.delivery_postal_code || "",
    delivery_city: customer?.delivery_city || "",
    delivery_more_information: customer?.delivery_more_information || ""
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    setCustomerInfo({
      ...customerInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectAddressChange = (event) => {
    const selectedAddress = addresses.find(
      (adresse) => adresse.id === Number(event.value)
    );
    if (selectedAddress) {
      setCustomerInfo({
        ...customerInfo,
        street_number: selectedAddress?.street_number || "",
        street: selectedAddress?.street || "",
        postal_code: selectedAddress?.postal_code || "",
        city: selectedAddress?.city || "",
        address_complement: selectedAddress?.address_complement || "",
      });
    }
  };

  const handleSelectDeliveryAddressChange = (event) => {
    const selectedAddress = addresses.find(
      (adresse) => adresse.id === Number(event.value)
    );
    if (selectedAddress) {
      setCustomerInfo({
        ...customerInfo,
        delivery_street_number: selectedAddress?.street_number || "",
        delivery_street: selectedAddress?.street || "",
        delivery_postal_code: selectedAddress?.postal_code || "",
        delivery_city: selectedAddress?.city || "",
        delivery_address_complement: selectedAddress?.address_complement || "",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await dispatch(addCustomer(customerInfo)).then(async (resp) => {
        setIsLoading(false);
        let msg = "Le client a été ajouté avec succès";
        if (resp?.result?.success === false || resp?.success === false) {
          msg = resp?.result?.message || resp?.message || "Une erreur est survenue";
          swal({
            title: "Attention ",
            text: msg,
            icon: "warning",
            dangerMode: true,
          });
        } else {
          // Si ok, récupère le dernier client dans le listing
          const customer = ATSCustomer(resp);
          await handleCustomerSelect(customer, customerInfo);
          handleClose();
          swal({
            title: "Information",
            text: "Le client a été ajouté avec succès",
            icon: "success",
            dangerMode: false,
          });
        }
      });
    } catch (err) {
      setIsLoading(false);
      swal({
        title: "Attention ",
        text: err?.message || "Une erreur est survenue",
        icon: "warning",
        dangerMode: true,
      });
    }
  };

  // Dispatch également l'entreprise et les infos de facturation
  const handleCustomerSelect = async (customer, customerInfo) => {
    dispatch(setCustomer(customer));
    let company = companies.find(
      (company) => company.id === customer.company_id
    );
    if (company) {
      dispatch(setCompany(company));
    }
    if (customer) {
      const fullAddress =
      customerInfo?.street_number +
        " " +
        customerInfo?.street +
        " " +
        customerInfo?.postal_code +
        " " +
        customerInfo?.city;
      dispatch(
        setBillingInfo({
          ...billingInfo,
          lastname: customerInfo?.lastname,
          firstname: customerInfo?.firstname,
          phone: customerInfo?.phone,
          addressComplement: customerInfo?.address_complement || "",
          fullAddress: fullAddress,
          company: company?.name || customerInfo.company,
          streetNumber: customerInfo?.street_number || "",
          street: customerInfo?.street || "",
          postalCode: customerInfo?.postal_code || "",
          city: customerInfo?.city || "",
        })
      );
      dispatch(
        setDeliveryInfo({
          lastname: customerInfo?.delivery_lastname || "",
          firstname: customerInfo?.delivery_firstname || "",
          company: customerInfo?.delivery_company || "",
          phone: customerInfo?.delivery_phone || "",
          phoneSecondary: customerInfo?.delivery_phone_secondary || "",
          streetNumber: customerInfo?.delivery_street_number || "",
          street: customerInfo?.delivery_street || "",
          postalCode: customerInfo?.delivery_postal_code || "",
          city: customerInfo?.delivery_city || "",
          addressComplement: customerInfo?.delivery_address_complement || "",
        })
      );
    }
  };

  // On format addresses pour react-select
  const addressesFormat = addresses.map((item) => ({
    value: item.id,
    label: item.name_address,
    ...item,
  }));
  const [optionSelected, setSelectedOptions] = useState([]);

  const copyDeliveryInfo = () => {
    setCustomerInfo({
      ...customerInfo,
      firstname: customerInfo.delivery_firstname,
      lastname: customerInfo.delivery_lastname,
      phone: customerInfo.delivery_phone,
      company: customerInfo.delivery_company,
      street_number: customerInfo.delivery_street_number,
      street: customerInfo.delivery_street,
      address_complement: customerInfo.delivery_address_complement,
      postal_code: customerInfo.delivery_postal_code,
      city: customerInfo.delivery_city
    });
  };

  const copyCommercialToDelivery = () => {
    setCustomerInfo({
      ...customerInfo,
      delivery_firstname: customerInfo.contactFirstname,
      delivery_lastname: customerInfo.contactLastname,
      delivery_phone: customerInfo.contactPhone,
      delivery_company: customerInfo?.companyBisName || "",
      delivery_phone_secondary: customerInfo.contactPhoneDirect,
    });
  }

  return (
    <>
      <Button variant="success" onClick={handleShow}>
        +
      </Button>

      <Modal show={show} onHide={handleClose} dialogClassName="modal-xl">
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? 
            <div>Enregistrement en cours...</div>
          :
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={4}>
                  <h3>Contact commercial</h3>
                  <Form.Group>
                    <Form.Label>Entreprise*</Form.Label>
                    <Select
                      options={companiesFormat}
                      closeMenuOnSelect={true}
                      onChange={(selectedOption) => {
                        setCustomerInfo({
                          ...customerInfo,
                          companyBis: selectedOption.value,
                          companyBisName: selectedOption.label,
                        });
                      }}
                      value={companiesFormat.find(
                        (company) => company.value === customerInfo.companyBis
                      )}
                      name={"company"}
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                  <Form.Label>Client d'appel d'offre*</Form.Label>
                    <Form.Control
                      as="select"
                      name="type"
                      value={customerInfo.type}
                      onChange={handleChange}
                      required
                    >
                      <option value="normal">Normal</option>
                      <option value="tender">Appel d'offre</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Check 
                      type="checkbox" 
                      label="Désactiver les relances d'impayées ?"
                      name="disableUnpaidReminder"
                      value={customerInfo.disableUnpaidReminder}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Email*</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={customerInfo.email}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Prénom du contact*</Form.Label>
                    <Form.Control
                      type="text"
                      name="contactFirstname"
                      value={customerInfo.contactFirstname}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Nom du contact*</Form.Label>
                    <Form.Control
                      type="text"
                      name="contactLastname"
                      value={customerInfo.contactLastname}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Téléphone principal*</Form.Label>
                    <Form.Control
                      type="text"
                      name="contactPhone"
                      value={customerInfo.contactPhone}
                      onChange={handleChange}
                      pattern="^(0|\+33)[1-9]([\-\. ]?[0-9]{2}){4}$"
                      title="Mauvais format de téléphone"
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Téléphone secondaire</Form.Label>
                    <Form.Control
                      type="text"
                      name="contactPhoneDirect"
                      value={customerInfo.contactPhoneDirect}
                      onChange={handleChange}
                      pattern="^(0|\+33)[1-9]([\-\. ]?[0-9]{2}){4}$"
                    />
                  </Form.Group>
                  <Button variant="primary" onClick={copyCommercialToDelivery}>
                    Copier les informations commerciales dans livraison
                  </Button>
                </Col>
                <Col md={4}>
                  <h3>Adresse de livraison</h3>
                  <Form.Group>
                    <Form.Label>Carnet d'adresses</Form.Label>
                    <Select
                      options={addressesFormat}
                      closeMenuOnSelect={true}
                      onChange={handleSelectDeliveryAddressChange}
                      value={optionSelected}
                      name={"delivery_address"}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Prénom*</Form.Label>
                    <Form.Control
                      type="text"
                      name="delivery_firstname"
                      value={customerInfo.delivery_firstname}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Nom*</Form.Label>
                    <Form.Control
                      type="text"
                      name="delivery_lastname"
                      value={customerInfo.delivery_lastname}
                      onChange={handleChange}
                      pattern=".{2,25}"
                      title=""
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Portable*</Form.Label>
                    <Form.Control
                      type="text"
                      name="delivery_phone"
                      value={customerInfo.delivery_phone}
                      onChange={handleChange}
                      pattern="^(0|\+33)[1-9]([\-\. ]?[0-9]{2}){4}$"
                      title="Mauvais format de téléphone"
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Téléphone secondaire</Form.Label>
                    <Form.Control
                      type="text"
                      name="delivery_phone_secondary"
                      value={customerInfo.delivery_phone_secondary}
                      onChange={handleChange}
                      pattern="^(0|\+33)[1-9]([\-\. ]?[0-9]{2}){4}$"
                      title="Mauvais format de téléphone"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Entreprise*</Form.Label>
                    <Form.Control
                      type="text"
                      name="delivery_company"
                      value={customerInfo.delivery_company}
                      onChange={handleChange}
                      pattern=".{2,50}"
                      title="form.validation.length_2_50"
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Numéro de rue*</Form.Label>
                    <Form.Control
                      type="text"
                      name="delivery_street_number"
                      value={customerInfo.delivery_street_number}
                      onChange={handleChange}
                      pattern=".{1,7}"
                      title="form.validation.length_1_7"
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Rue*</Form.Label>
                    <Form.Control
                      type="text"
                      name="delivery_street"
                      value={customerInfo.delivery_street}
                      onChange={handleChange}
                      pattern=".{2,50}"
                      title="form.validation.length_2_50"
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Complément d'adresse (Bâtiment, code d'accès, interphone, étage)</Form.Label>
                    <Form.Control
                      type="text"
                      name="delivery_address_complement"
                      value={customerInfo.delivery_address_complement}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Code postal*</Form.Label>
                    <Form.Control
                      type="text"
                      name="delivery_postal_code"
                      value={customerInfo.delivery_postal_code}
                      onChange={handleChange}
                      minLength="5"
                      maxLength="5"
                      title="form.validation.postal_code"
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Ville*</Form.Label>
                    <Form.Control
                      type="text"
                      name="delivery_city"
                      value={customerInfo.delivery_city}
                      onChange={handleChange}
                      pattern=".{2,50}"
                      title="form.validation.length_2_50"
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      Informations supplémentaires (livraison)
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="delivery_more_information"
                      value={customerInfo.delivery_more_information}
                      onChange={handleChange}
                      style={{ overflowX: "hidden" }}
                    />
                  </Form.Group>
                  <Button variant="primary" onClick={copyDeliveryInfo}>
                    Copier les informations de livraison dans facturation
                  </Button>
                </Col>
                <Col md={4}>
                  <h3>Adresse de facturation</h3>
                  <Form.Group>
                    <Form.Label>Carnet d'adresses</Form.Label>
                    <Select
                      options={addressesFormat}
                      closeMenuOnSelect={true}
                      onChange={handleSelectAddressChange}
                      value={optionSelected}
                      name={"address"}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Prénom*</Form.Label>
                    <Form.Control
                      type="text"
                      name="firstname"
                      value={customerInfo.firstname}
                      onChange={handleChange}
                      pattern=".{2,25}"
                      title="form.validation.length_2_25"
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Nom*</Form.Label>
                    <Form.Control
                      type="text"
                      name="lastname"
                      value={customerInfo.lastname}
                      onChange={handleChange}
                      pattern=".{2,25}"
                      title="form.validation.length_2_25"
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Portable*</Form.Label>
                    <Form.Control
                      type="text"
                      name="phone"
                      value={customerInfo.phone}
                      onChange={handleChange}
                      pattern="^(0|\+33)[1-9]([\-\. ]?[0-9]{2}){4}$"
                      title="form.validation.phone"
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Numéro de rue</Form.Label>
                    <Form.Control
                      type="text"
                      name="street_number"
                      value={customerInfo.street_number}
                      onChange={handleChange}
                      pattern=".{1,7}"
                      title="form.validation.length_1_7"
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Rue*</Form.Label>
                    <Form.Control
                      type="text"
                      name="street"
                      value={customerInfo.street}
                      onChange={handleChange}
                      pattern=".{2,50}"
                      title="form.validation.length_2_50"
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Complément d'adresse (Bâtiment, code d'accès, interphone, étage)</Form.Label>
                    <Form.Control
                      type="text"
                      name="address_complement"
                      value={customerInfo.address_complement}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Code postal*</Form.Label>
                    <Form.Control
                      type="text"
                      name="postal_code"
                      value={customerInfo.postal_code}
                      onChange={handleChange}
                      minLength="5"
                      maxLength="5"
                      title="form.validation.postal_code"
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Ville*</Form.Label>
                    <Form.Control
                      type="text"
                      name="city"
                      value={customerInfo.city}
                      onChange={handleChange}
                      pattern=".{2,50}"
                      title="form.validation.length_2_50"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Button variant="success" type="submit" block size="lg" className="mt-3">
                Enregistrer
              </Button>
            </Form>
          }
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddCustomerComponent;
