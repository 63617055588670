export const UPDATE_MODAL = "UPDATE_MODAL";
export const INITIALIZE_MULTI_DEVIS = "INITIALIZE_MULTI_DEVIS";
export const INITIALIZE_MULTI_DEVIS_COMPLETED =
  "INITIALIZE_MULTI_DEVIS_COMPLETED";
export const INITIALIZE_MULTI_DEVIS_FAILED = "INITIALIZE_MULTI_DEVIS_FAILED";

export const GET_BREAKFAST_PRODUCTS = "GET_BREAKFAST_PRODUCTS";
export const GET_BREAKFAST_PRODUCTS_COMPLETED = "GET_BREAKFAST_PRODUCTS_COMPLETED";
export const GET_BREAKFAST_PRODUCTS_FAILED = "GET_BREAKFAST_PRODUCTS_FAILED";

export const GET_BREAKFAST_FORMULAS = "GET_BREAKFAST_FORMULAS";
export const GET_BREAKFAST_FORMULAS_COMPLETED = "GET_BREAKFAST_FORMULAS_COMPLETED";
export const GET_BREAKFAST_FORMULAS_FAILED = "GET_BREAKFAST_FORMULAS_FAILED";

export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCTS_COMPLETED = "GET_PRODUCTS_COMPLETED";
export const GET_PRODUCTS_FAILED = "GET_PRODUCTS_FAILED";

export const GET_PRODUCT_GROUPS = "GET_PRODUCT_GROUPS";
export const GET_PRODUCT_GROUPS_COMPLETED = "GET_PRODUCT_GROUPS_COMPLETED";
export const GET_PRODUCT_GROUPS_FAILED = "GET_PRODUCT_GROUPS_FAILED";

export const GET_PRODUCT_PACKAGES = "GET_PRODUCT_PACKAGES";
export const GET_PRODUCT_PACKAGES_COMPLETED = "GET_PRODUCT_PACKAGES_COMPLETED";
export const GET_PRODUCT_PACKAGES_FAILED = "GET_PRODUCT_PACKAGES_FAILED";

export const GET_PRODUCT_CLASSIFICATIONS = "GET_PRODUCT_CLASSIFICATIONS";
export const GET_PRODUCT_CLASSIFICATIONS_COMPLETED = "GET_PRODUCT_CLASSIFICATIONS_COMPLETED";
export const GET_PRODUCT_CLASSIFICATIONS_FAILED = "GET_PRODUCT_CLASSIFICATIONS_FAILED";

export const GET_TENDERS = "GET_TENDERS";
export const GET_TENDERS_COMPLETED = "GET_TENDERS_COMPLETED";
export const GET_TENDERS_FAILED = "GET_TENDERS_FAILED";

export const GET_PRODUCT_PARTS = "GET_PRODUCT_PARTS";
export const GET_PRODUCT_PARTS_COMPLETED = "GET_PRODUCT_PARTS_COMPLETED";
export const GET_PRODUCT_PARTS_FAILED = "GET_PRODUCT_PARTS_FAILED";

export const updateModal = (modal) => ({
  type: UPDATE_MODAL,
  payload: modal,
});

export const initialize = () => ({
  type: INITIALIZE_MULTI_DEVIS,
  meta: {
    async: true,
    blocking: true,
    path: `/api/pro/orders/initialize.json`,
    method: "GET",
    params: {},
  },
});

export const getBreakfastProducts = () => ({
  type: GET_BREAKFAST_PRODUCTS,
  meta: {
    async: true,
    blocking: true,
    path: `/api/pro/breakfasts/products.json`,
    method: "GET",
    params: {},
  },
});

export const getBreakfastFormulas = () => ({
  type: GET_BREAKFAST_FORMULAS,
  meta: {
    async: true,
    blocking: true,
    path: `/api/pro/breakfasts/formulas.json`,
    method: "GET",
    params: {},
  },
});

export const getProducts = () => ({
  type: GET_PRODUCTS,
  meta: {
    async: true,
    blocking: true,
    path: `/api/pro/offer/products.json`,
    method: "GET",
    params: {},
  },
});

export const getProductGroups = () => ({
  type: GET_PRODUCT_GROUPS,
  meta: {
    async: true,
    blocking: true,
    path: `/api/pro/offer/productgroups.json`,
    method: "GET",
    params: {},
  },
});

export const getProductPackages = () => ({
  type: GET_PRODUCT_PACKAGES,
  meta: {
    async: true,
    blocking: true,
    path: `/api/pro/offer/productpackages.json`,
    method: "GET",
    params: {},
  },
});

export const getProductClassifications = () => ({
  type: GET_PRODUCT_CLASSIFICATIONS,
  meta: {
    async: true,
    blocking: true,
    path: `/api/pro/offer/productclassifications.json`,
    method: "GET",
    params: {},
  },
});

export const getProductParts = () => ({
  type: GET_PRODUCT_PARTS,
  meta: {
    async: true,
    blocking: true,
    path: `/api/pro/offer/productparts.json`,
    method: "GET",
    params: {},
  },
});