import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Accordion, Card, Button, Dropdown, Form, Alert } from "react-bootstrap";
import moment from "moment";
import { centimesToCurrency, toFrenchType } from "helper/globalHelper";
import { multiOrdersStoreToApi } from "helper/transformDataHelper";
import OrderApiSchema from "schema/Order/OrderApiSchema";
import {
  saveGroupedOrder,
  setCurrentOrderIndex,
  removeOrder,
  setGroupedOrderStatus,
  duplicatePrestation
} from "state/orderEdition/actions";
import { updateModal } from "state/adminData/actions";
import swal from 'sweetalert';
import { faTrash, faSave, faUser, faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation, useHistory } from 'react-router-dom';

const OrderComponent = ({ orderIndex }) => {
  const dispatch = useDispatch();

  const order = useSelector(
    (state) => state.orderEdition.orders[orderIndex] || {}
  );

  const totalHT = order?.priceInfo?.totalHT || 0;
  const location = useLocation();

  const handleToggleClick = (orderIndex) => {
    dispatch(setCurrentOrderIndex(orderIndex));
    // Ajoute l'order index dans l'url également
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('order_index', orderIndex);
    const newUrl = `${location.pathname}?${queryParams.toString()}`;
    window.history.pushState(null, '', newUrl);
};

  const handleRemoveOrder = (orderIndex) => {
    swal({
      title: "Etes-vous sûre de vouloir supprimer cette prestation ?",
      text: "Merci de sauvegarder votre commande pour que la suppression prennent effet",
      icon: "warning",
      buttons: ["Ne pas supprimer", "Je suis sûre"],
    })
    .then((confirm) => {
      if(confirm){
        dispatch(removeOrder(orderIndex));
      }
    });
    
  };

  const handleDuplicate = (orderIndex) => {
    swal({
      title: "Etes-vous sûre de vouloir dupliquer cette prestation ?",
      text: "Merci de sauvegarder votre commande après la duplication pour que cela prenne effet",
      icon: "warning",
      buttons: ["Ne pas dupliquer", "Je suis sûre"],
    })
    .then((confirm) => {
      if(confirm){
        dispatch(duplicatePrestation(orderIndex));
      }
    });
    
  };

  return (
    <>
      <Card key={orderIndex}>
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey={orderIndex.toString()}
            onClick={() => handleToggleClick(orderIndex)}
            style={{ textAlign: 'left', color: 'black' }}
          >
            
           {toFrenchType(order.prestationType, true)} du {order.deliveryInfo.deliveryDate ? moment(order.deliveryInfo.deliveryDate).format("DD/MM/YY") : "A RENSEIGNER"} pour {order.deliveryInfo.person ? order.deliveryInfo.person : "A RENSEIGNER"} <FontAwesomeIcon icon={faUser} /><br/>
           <small>{order?.info?.orderNumber && order.info.orderNumber} - {centimesToCurrency(totalHT)} HT</small>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={orderIndex.toString()}>
          <Card.Body>
            {order?.id ?
              <Dropdown style={{ display: 'inline-block', marginRight: '10px' }}>
                <Dropdown.Toggle
                  variant="outline-secondary"
                  size="sm"
                  id="dropdown-custom-components"
                >
                  Documents
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {order?.info?.document_urls && order.info.document_urls.length > 0 ? (
                    order.info.document_urls.map((document, index) => (
                      <Dropdown.Item
                        key={index * 10}
                        href={document.url}
                        target="_blank"
                      >
                        {document.name}
                      </Dropdown.Item>
                    ))
                  ) : (
                    <Dropdown.Item
                      eventKey="10"
                      href={order?.info?.generate_document_url}
                      target="_blank"
                    >
                      Générer le document manquant
                    </Dropdown.Item>
                  )}
                  {!order?.cancel_date && (
                    <>
                      <hr />
                      <Dropdown.Item
                        eventKey="1"
                        href={order?.info?.quotation_url}
                        target="_blank"
                      >
                        Fiche de production
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="2"
                        href={order?.info?.propose_url}
                        target="_blank"
                      >
                        Le Moulin vous propose
                      </Dropdown.Item>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
              : <Button
                  variant="outline-secondary"
                  size="sm"
                  style={{ marginRight: '10px' }}
                  disabled
                >
                  Non sauvegardé
                </Button>
            }
            <Button
              onClick={(e) => handleRemoveOrder(orderIndex)}
              variant="outline-danger"
              size="sm"
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
            <Button
              onClick={(e) => handleDuplicate(orderIndex)}
              variant="outline-info"
              size="sm"
              style={{ marginLeft: '10px' }}
            >
              <FontAwesomeIcon icon={faCopy} />
            </Button>
            <hr />
            <ul>
            {order.format && <li style={{ color: "grey" }}><small>Format : {order.format}</small></li>}
              {order.items.map((item, index) => (
                <li key={index} style={{ color: "grey" }}><small>{item.quantity} x {item?.caterer_name && order.prestationType === 'caterer' ? item.caterer_name : item.name}</small></li>
              ))}
              <li style={{ color: "grey" }}><small>Livraison : {centimesToCurrency(order.deliveryCost)}</small></li>
            </ul>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  );
};

const ListOrdersComponent = () => {
  const routerHistory = useHistory();
  const orders = useSelector((state) => state.orderEdition.orders);
  const groupedOrderUpdatedAt = useSelector(
    (state) => state.orderEdition.groupedOrderUpdatedAt
  );
  const currentOrderIndex = useSelector(
    (state) => state.orderEdition.currentOrderIndex
  );
  const groupedOrderStatus = useSelector(
    (state) => state.orderEdition.groupedOrderStatus
  );

  const dispatch = useDispatch();

  const [totalHT, setTotalHT] = useState(0);
  const [totalHTWithoutReduction, setTotalHTWithoutReduction] = useState(0);
  const [status, setStatus] = useState(groupedOrderStatus || "");

  const orderEdition = useSelector((state) => state.orderEdition);

  const groupedOrderId = useSelector(
    (state) => state.orderEdition.groupedOrderId
  );

  useEffect(() => {
    let total = 0;
    let totalWithoutReduction = 0;
    
    total = orders.reduce((acc, order) => acc + (order?.priceInfo?.totalHT ? order.priceInfo.totalHT : 0), 0);
    totalWithoutReduction = orders.reduce((acc, order) => acc + (order?.priceInfo?.totalHTWithoutReduction ? order.priceInfo.totalHTWithoutReduction : 0), 0);

    setTotalHT(total);
    setTotalHTWithoutReduction(totalWithoutReduction);
  }, [orders]);

  const handleSave = async () => {
    try {
      const data = multiOrdersStoreToApi(orderEdition);
      await Promise.all(data.map(orderData => OrderApiSchema.validate(orderData)));
      
      // Dispatch l'action et attendre qu'elle soit terminée
      const result = await dispatch(saveGroupedOrder({orders: data}, groupedOrderId)).then((res, a) => {
        if (res?.groupedOrder?.id) {
          const url = new URL(window.location);
          url.searchParams.set('order_id', res.groupedOrder.id);
          url.searchParams.set('order_type', 'v2');
          window.history.pushState({}, '', url);
          swal({
            title: "Enregistrement fait",
            text: "Votre commande a bien été sauvegardé et le devis généré",
            icon: "success",
            buttons: ["Rester ici", "Retour aux commandes"],
          })
          .then((confirm) => {
            if(confirm){
              routerHistory.push({
                pathname: `/`,
                search: window.location.search
              });
            }
          });
        }
        if(res.success === false){
          swal({
            title: "Oups",
            text: res.message,
            icon: "error",
          })
        }
      });

      // Récupérer le nouvel groupedOrderId depuis la réponse ou l'état mis à jour
      const newGroupedOrderId = result?.groupedOrder?.id || groupedOrderId;
  
      // Ajouter le nouvel groupedOrderId à l'URL
      if (newGroupedOrderId) {
        const url = new URL(window.location);
        url.searchParams.set('order_id', newGroupedOrderId);
        window.history.pushState({}, '', url);
      }
    }
    catch (error) {
      dispatch(updateModal({
        isOpen: true,
        title: "Erreur de validation",
        message: error.message,
      }));
    }
  };

  // Assumons que vous avez une liste de statuts
  const statusOptions = [
    {
      option: "101",
      traduction: "En attente",
    },
    {
      option: "102",
      traduction: "Validé",
    },
    {
      option: "401",
      traduction: "Refusé par le client",
    },
  ];

  const statusCheckOptions = [
    {
      option: "103",
      traduction: "A vérifier avant facturation",
    },
    {
      option: "201",
      traduction: "A payer",
    },
  ];

  const handleStatusChange = (e) => {
    const newStatus = e.target.value;
    setStatus(newStatus);
    dispatch(setGroupedOrderStatus(newStatus));
  };
  return (
    
      <>
        <Accordion activeKey={currentOrderIndex ? currentOrderIndex.toString() : "0"}>
          {orders.map((order, index) => (
            <OrderComponent
              key={index}
              orderIndex={index}
            />
          ))}
        </Accordion>
        
        <Alert variant="secondary" className="mt-2">
          
          Total HT : {centimesToCurrency(totalHT)}
      
        </Alert>

        <div className="d-grid gap-2">
          <Form.Group controlId="statusSelect">
            <Form.Label>Statut*</Form.Label>
            <Form.Control
              as="select"
              value={status}
              onChange={handleStatusChange}
            >
              <option value="" disabled>Sélectionnez un statut</option>
              {console.log(status)}
              {status === "103" || status === "201" ?
                statusCheckOptions.map((sta, index) => (
                  <option key={index} value={sta.option}>
                    {sta.traduction}
                  </option>
                ))
                :
                statusOptions.map((sta, index) => (
                  <option key={index} value={sta.option}>
                    {sta.traduction}
                  </option>
                ))
              }
            </Form.Control>
          </Form.Group>
          <Button onClick={handleSave} variant="success" size="xl" style={{ marginRight: "5px", width: "100%" }}>
            <FontAwesomeIcon icon={faSave} /> Sauvegarder<br/>
            <small>{groupedOrderUpdatedAt && `Sauvegardé le : ${moment(groupedOrderUpdatedAt).format('DD/MM/YY à HH:mm')}`}</small>
          </Button>
        </div>
        
      </>
    
  );
};

export default ListOrdersComponent;
