import {
  GET_TENDERS,
  GET_TENDERS_COMPLETED,
  GET_TENDERS_FAILED,
  GET_TENDER_CATALOG,
  GET_TENDER_CATALOG_COMPLETED,
  GET_TENDER_CATALOG_FAILED,
  UPDATE_TENDER_CATALOG,
  UPDATE_TENDER_CATALOG_COMPLETED,
  UPDATE_TENDER_CATALOG_FAILED,
  GET_TENDER_CATALOG_RANGES,
  GET_TENDER_CATALOG_RANGES_COMPLETED,
  GET_TENDER_CATALOG_RANGES_FAILED,
  UPDATE_TENDER_CATALOG_RANGES,
  UPDATE_TENDER_CATALOG_RANGES_COMPLETED,
  UPDATE_TENDER_CATALOG_RANGES_FAILED,
  RESET_TENDER_CATALOG,
  RESET_TENDER_CATALOG_RANGES,
} from "./actions";
import { ATSTenderCatalogRanges, ATSTenderCatalog } from "transformer/tender/tenderCatalogTransformer";

const initialState = {
  tenders: {
    data: [],
    isLoading: false,
    isInitialized: false,
    hasFailed: false,
  },
  // Contient le catalogue d'un AO
  tenderCatalog: {
    data: {},
    isLoading: false,
    hasFailed: false,
    isInitialized: false,
  },
  // Contient les ranges d'un AO
  tenderCatalogRanges: {
    data: [],
    isLoading: false,
    hasFailed: false,
    isInitialized: false,
  },
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_TENDERS:
      return {
        ...state,
        tenders: {
          ...state.tenders,
          isLoading: true,
        },
      };
    case GET_TENDERS_COMPLETED:
      return {
        ...state,
        tenders: {
          data: action.payload.result,
          isLoading: false,
          isInitialized: true,
          hasFailed: false,
        },
      };
    case GET_TENDERS_FAILED:
      return {
        ...state,
        tenders: {
          ...state.tenders,
          isLoading: false,
          isInitialized: true,
          hasFailed: true,
        },
      };
    case GET_TENDER_CATALOG:
      return {
        ...state,
        tenderCatalog: {
          ...state.tenderCatalog,
          isLoading: true,
        },
      };
    case GET_TENDER_CATALOG_COMPLETED:
      return {
        ...state,
        tenderCatalog: {
          data: action.payload.result,
          isLoading: false,
          isInitialized: true,
          hasFailed: false,
        },
      };
    case GET_TENDER_CATALOG_FAILED:
      return {
        ...state,
        tenderCatalog: {
          ...state.tenderCatalog,
          isLoading: false,
          hasFailed: true,
          isInitialized: true,
        },
      };
    case UPDATE_TENDER_CATALOG:
      return {
        ...state,
        tenderCatalog: {
          ...state.tenderCatalog,
          isLoading: true,
        },
      };
    case UPDATE_TENDER_CATALOG_COMPLETED:
      return {
        ...state,
        tenderCatalog: {
          data: ATSTenderCatalog(action.payload.result),
          isLoading: false,
          isInitialized: true,
        },
      };
    case UPDATE_TENDER_CATALOG_FAILED:
      return {
        ...state,
        tenderCatalog: {
          ...state.tenderCatalog,
          isLoading: false,
          isInitialized: true,
          hasFailed: true,
        },
      };
    case GET_TENDERS:
      return {
        ...state,
        tenders: {
          ...state.tenders,
          isLoading: true,
        },
      };
    case GET_TENDERS_COMPLETED:
      return {
        ...state,
        tenders: {
          data: action.payload.result,
          isLoading: false,
          isInitialized: true,
        },
      };
    case GET_TENDERS_FAILED:
      return {
        ...state,
        tenders: {
          ...state.tenders,
          isLoading: false,
          isInitialized: true,
          hasFailed: true,
        },
      };
    case GET_TENDER_CATALOG_RANGES:
      return {
        ...state,
        tenderCatalogRanges: {
          ...state.tenderCatalogRanges,
          isLoading: true,
        },
      };
    case GET_TENDER_CATALOG_RANGES_COMPLETED:
      return {
        ...state,
        tenderCatalogRanges: {
          data: ATSTenderCatalogRanges(action.payload?.result),
          isLoading: false,
          isInitialized: true,
          hasFailed: false,
        },
      };
    case GET_TENDER_CATALOG_RANGES_FAILED:
      return {
        ...state,
        tenderCatalogRanges: {
          ...state.tenderCatalogRanges,
          isLoading: false,
          hasFailed: true,
          isInitialized: true,
        },
      };
    case UPDATE_TENDER_CATALOG_RANGES:
      return {
        ...state,
        tenderCatalogRanges: {
          ...state.tenderCatalogRanges,
          isLoading: true,
        },
      };
    case UPDATE_TENDER_CATALOG_RANGES_COMPLETED:
      return {
        ...state,
        tenderCatalogRanges: {
          data: ATSTenderCatalogRanges(action.payload.result),
          isLoading: false,
          isInitialized: true,
          hasFailed: false,
        },
      };
    case UPDATE_TENDER_CATALOG_RANGES_FAILED:
      return {
        ...state,
        tenderCatalogRanges: {
          ...state.tenderCatalogRanges,
          isLoading: false,
          hasFailed: true,
          isInitialized: true,
        },
      };
      case RESET_TENDER_CATALOG:
        return {
          ...state,
          tenderCatalog: {
            ...initialState.tenderCatalog,
          }
        };
      case RESET_TENDER_CATALOG_RANGES:
        return {
          ...state,
          tenderCatalogRanges: {
            ...initialState.tenderCatalogRanges,
          }
        };
    default:
      return state;
  }
};

export default reducers;
