import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import EditableCell from "./EditableCell";
import { Table } from "react-bootstrap";
import { updatePriceInfo } from "state/orderEdition/actions";

const initialData = {
  forfaitPiecesHT: {
    label: "Forfait Pièces HT",
    parPersonne: 0,
    parPersonneEstimation: 0,
    totalClient: 0,
    totalEstimation: 0,
  },
  forfaitSoftsHT: {
    label: "Forfait Softs HT",
    parPersonne: 0,
    parPersonneEstimation: 0,
    totalClient: 0,
    totalEstimation: 0,
  },
  forfaitAlcoolsHT: {
    label: "Forfait Alcools HT",
    parPersonne: 0,
    parPersonneEstimation: 0,
    totalClient: 0,
    totalEstimation: 0,
  },
  forfaitSubTotal: {
    label: "Sous total",
    parPersonne: 0,
    parPersonneEstimation: 0,
    totalClient: 0,
    totalEstimation: 0,
  },
  materielService: {
    label: "Matériel et Service",
    parPersonne: null,
    parPersonneEstimation: null,
    totalClient: 0,
    totalEstimation: 0,
  },
  auDetail: {
    label: "Au détail",
    parPersonne: null,
    parPersonneEstimation: null,
    totalClient: 0,
    totalEstimation: 0,
  },
  livraison: {
    label: "Livraison",
    parPersonne: null,
    parPersonneEstimation: null,
    totalClient: 0,
    totalEstimation: 0,
  },
  totalHT: {
    label: "Total HT",
    parPersonne: null,
    parPersonneEstimation: null,
    totalClient: 0,
    totalEstimation: 0,
  },
};

const PricingTable = ({ orderIndex }) => {
  const dispatch = useDispatch();
  const order = useSelector(
    (state) => state.orderEdition.orders[orderIndex] || {}
  );
  const items = order.items || [];
  const numberOfPersons = useSelector(
    (state) =>
      state.orderEdition.orders[orderIndex]?.deliveryInfo?.person || 1
  );
  const priceInfo = order.priceInfo || {};
  const [data, setData] = useState(initialData);

  // Fonction pour initialiser les données à partir de priceInfo et des items
  const initializeData = (row, column, value) => {
    let newData = {
      ...initialData,
      forfaitPiecesHT: {
        ...initialData.forfaitPiecesHT,
        parPersonne: priceInfo.catererPackagePiecesPriceHtPerParticipant || 0,
        parPersonneEstimation: 0,
        totalClient:
          (priceInfo.catererPackagePiecesPriceHtPerParticipant || 0) *
          numberOfPersons,
        totalEstimation: 0,
      },
      forfaitSoftsHT: {
        ...initialData.forfaitSoftsHT,
        parPersonne: priceInfo.catererPackageSoftsPriceHtPerParticipant || 0,
        parPersonneEstimation: 0,
        totalClient:
          (priceInfo.catererPackageSoftsPriceHtPerParticipant || 0) *
          numberOfPersons,
        totalEstimation: 0,
      },
      forfaitAlcoolsHT: {
        ...initialData.forfaitAlcoolsHT,
        parPersonne: priceInfo.catererPackageAlcoholsPriceHtPerParticipant || 0,
        parPersonneEstimation: 0,
        totalClient:
          (priceInfo.catererPackageAlcoholsPriceHtPerParticipant || 0) *
          numberOfPersons,
        totalEstimation: 0,
      },
      materielService: {
        ...initialData.materielService,
        totalClient: 0,
        totalEstimation: 0,
      },
      auDetail: {
        ...initialData.auDetail,
        totalClient: 0,
        totalEstimation: 0,
      },
      livraison: {
        ...initialData.livraison,
        totalClient: 0,
        totalEstimation: 0,
      },
      totalHT: {
        ...initialData.totalHT,
        totalClient: 0,
        totalEstimation: 0,
      },
    };

    if (row) {
      newData = { ...newData, [row]: { ...newData[row], parPersonne: value } };
      if (column === "parPersonne") {
        newData[row].totalClient = value * numberOfPersons;
      }
    }

    // Prix pour les forfaits
    const packageItems = items.filter((item) => item.type === "productPackage");
    packageItems.forEach((packageItem) => {
      const catererPriceGroup = packageItem?.caterer_price_group;
      const priceGroup = catererPriceGroup === "alcohol" ? "forfaitAlcoolsHT" : catererPriceGroup === "soft" ? "forfaitSoftsHT" : "forfaitPiecesHT";
      newData[priceGroup].parPersonneEstimation += ((packageItem.price * packageItem.quantity) / numberOfPersons);
      newData[priceGroup].totalEstimation = newData[priceGroup].parPersonneEstimation * numberOfPersons;
    });

    // Sous total pour les forfaits
    newData.forfaitSubTotal.parPersonneEstimation = [
      "forfaitPiecesHT",
      "forfaitSoftsHT",
      "forfaitAlcoolsHT",
    ].reduce((total, key) => total + newData[key].parPersonneEstimation, 0);
    newData.forfaitSubTotal.totalEstimation = [
      "forfaitPiecesHT",
      "forfaitSoftsHT",
      "forfaitAlcoolsHT",
    ].reduce((total, key) => total + newData[key].totalEstimation, 0);

    // Prix Matériel et Service
    const materialItems = items.filter(
      (item) => item.classification_name === "Matériels et services"
    );

    materialItems.forEach((materialItem) => {
      const { price, quantity } = materialItem;
      newData.materielService.totalClient += (price * quantity);
      newData.materielService.totalEstimation = newData.materielService.totalClient;
    });

    // Prix Au détail
    const detailItems = items.filter(
      (item) =>
        item.classification_name !== "Matériels et services" &&
        item.type !== "productPackage"
    );
    detailItems.forEach((detailItem) => {
      const { price, quantity } = detailItem;
      const perPerson = (price * quantity);
      newData.auDetail.totalClient += perPerson;
      newData.auDetail.totalEstimation = newData.auDetail.totalClient;
    });

    // Livraison
    newData.livraison.totalClient = order?.deliveryCost || 0;
    newData.livraison.totalEstimation = newData.livraison.totalClient;

    // TotalHT
    newData.totalHT.totalClient = [
      "forfaitPiecesHT",
      "forfaitSoftsHT",
      "forfaitAlcoolsHT",
      "materielService",
      "auDetail",
      "livraison",
    ].reduce((total, key) => total + newData[key].totalClient, 0);
    newData.totalHT.totalEstimation = [
      "forfaitPiecesHT",
      "forfaitSoftsHT",
      "forfaitAlcoolsHT",
      "materielService",
      "auDetail",
      "livraison",
    ].reduce((total, key) => total + newData[key].totalEstimation, 0);

    return newData;
  };

  useEffect(() => {
    const newData = initializeData();
    setData(newData);
    dispatch(
      updatePriceInfo(orderIndex, {
        ...order.priceInfo,
        catererPackagePiecesPriceHtPerParticipant:
          newData.forfaitPiecesHT.parPersonne,
        catererPackageSoftsPriceHtPerParticipant: newData.forfaitSoftsHT.parPersonne,
        catererPackageAlcoholsPriceHtPerParticipant:
          newData.forfaitAlcoolsHT.parPersonne,
      })
    );
  }, [orderIndex, items, numberOfPersons, dispatch]);

  const handleCellChange = (row, column, value) => {

    let newData = initializeData(row, column, value);
    setData(newData);
    dispatch(
      updatePriceInfo(orderIndex, {
        ...order.priceInfo,
        catererPackagePiecesPriceHtPerParticipant: newData.forfaitPiecesHT.parPersonne,
        catererPackageSoftsPriceHtPerParticipant: newData.forfaitSoftsHT.parPersonne,
        catererPackageAlcoholsPriceHtPerParticipant: newData.forfaitAlcoolsHT.parPersonne,
      })
    );
    
  };

  const renderRow = (rowKey, rowData, index) => (
    <tr key={rowKey}>
      <td>{rowData.label}</td>
      {Object.keys(rowData).map(
        (colKey) =>
          colKey !== "label" && (
            <EditableCell
              key={colKey}
              value={rowData[colKey]}
              onChange={(value) => handleCellChange(rowKey, colKey, value)}
              editable={colKey === "parPersonne" && index < 3}
            />
          )
      )}
    </tr>
  );

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Tarif</th>
          <th>Par personne</th>
          <th>Par personne estimation</th>
          <th>Total client</th>
          <th>Total estimation</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(data).map((rowKey, index) =>
          renderRow(rowKey, data[rowKey], index)
        )}
      </tbody>
    </Table>
  );
};

export default PricingTable;
