import {
  UPDATE_MODAL,
  INITIALIZE_MULTI_DEVIS,
  INITIALIZE_MULTI_DEVIS_FAILED,
  INITIALIZE_MULTI_DEVIS_COMPLETED,
  GET_BREAKFAST_PRODUCTS,
  GET_BREAKFAST_PRODUCTS_FAILED,
  GET_BREAKFAST_PRODUCTS_COMPLETED,
  GET_BREAKFAST_FORMULAS,
  GET_BREAKFAST_FORMULAS_FAILED,
  GET_BREAKFAST_FORMULAS_COMPLETED,
  GET_PRODUCTS,
  GET_PRODUCTS_FAILED,
  GET_PRODUCTS_COMPLETED,
  GET_PRODUCT_GROUPS,
  GET_PRODUCT_GROUPS_FAILED,
  GET_PRODUCT_GROUPS_COMPLETED,
  GET_PRODUCT_PACKAGES,
  GET_PRODUCT_PACKAGES_FAILED,
  GET_PRODUCT_PACKAGES_COMPLETED,
  GET_PRODUCT_CLASSIFICATIONS,
  GET_PRODUCT_CLASSIFICATIONS_FAILED,
  GET_PRODUCT_CLASSIFICATIONS_COMPLETED,
  GET_PRODUCT_PARTS,
  GET_PRODUCT_PARTS_FAILED,
  GET_PRODUCT_PARTS_COMPLETED,
} from "./actions";

import {
  ADD_CUSTOMER_COMPLETED,
} from "state/customer/actions";

import {
  ADD_COMPANY_COMPLETED,
} from "state/company/actions";

import { ATSCustomer } from "transformer/customer/customerTransformer";

import { ATSCompany } from "transformer/company/companyTransformer";

const initialState = {
  modal: {
    isOpen: false,
    title: "",
    message: "",
    type: "",
    confirmText: "",
    cancelText: "",
    onConfirm: null
  },
  isLoading: false,
  isInitialized: false,
  hasFailed: false,
  loadedData: {
    companies: false,
    customers: false,
    tenders: false,
    formats: false,
  },
  companies: [],
  customers: [],
  addresses: [],
  formats: {},
  breakfastProducts: {
    data: [],
    isLoading: false,
    isInitialized: false,
    hasFailed: false,
  },
  breakfastFormulas: {
    data: [],
    isLoading: false,
    isInitialized: false,
    hasFailed: false,
  },
  products: {
    data: [],
    isLoading: false,
    isInitialized: false,
    hasFailed: false,
  },
  productGroups: {
    data: [],
    isLoading: false,
    isInitialized: false,
    hasFailed: false,
  },
  productPackages: {
    data: [],
    isLoading: false,
    isInitialized: false,
    hasFailed: false,
  },
  productClassifications: {
    data: [],
    isLoading: false,
    isInitialized: false,
    hasFailed: false,
  },
  tenders: {
    data: [],
    isLoading: false,
    isInitialized: false,
    hasFailed: false,
  },
  productParts: {
    data: [],
    isLoading: false,
    isInitialized: false,
    hasFailed: false,
  },
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_MODAL:
      return {
        ...state,
        modal: {
          isOpen: action.payload.isOpen,
          title: action.payload.title,
          message: action.payload.message,
          type: action.payload.type,
          confirmText: action.payload.confirmText,
          cancelText: action.payload.cancelText,
          onConfirm: action.payload.onConfirm,
        }
      }
    case INITIALIZE_MULTI_DEVIS:
      return {
        ...state,
        isLoading: true,
      };
    case INITIALIZE_MULTI_DEVIS_COMPLETED:
      return {
        ...state,
        isLoading: false,
        isInitialized: true,
        companies: action.payload.result.companies,
        customers: action.payload.result.customers,
        tenders: action.payload.result.tenders,
        formats: action.payload.result.formats,
        addresses: action.payload.result.addresses,
        loadedData: {
          ...state.loadedData,
          companies: true,
          customers: true,
          tenders: true,
          formats: true,
          addresses: true,
        },
      };
    case INITIALIZE_MULTI_DEVIS_FAILED:
      return {
        ...state,
        isLoading: false,
        isInitialized: true,
        hasFailed: true,
      };
    case GET_BREAKFAST_PRODUCTS:
      return {
        ...state,
        breakfastProducts : {
          ...state.breakfastProducts,
          isLoading: true,
        }
      };
    case GET_BREAKFAST_PRODUCTS_COMPLETED:
      return {
        ...state,
        breakfastProducts: {
          data: action.payload.result,
          isLoading: false,
          isInitialized: true,
        }
      };
    case GET_BREAKFAST_PRODUCTS_FAILED:
      return {
        ...state,
        breakfastProducts: {
          ...state.breakfastProducts,
          isLoading: false,
          isInitialized: true,
          hasFailed: true,
        }
      };
    case GET_BREAKFAST_FORMULAS:
      return {
        ...state,
        breakfastFormulas: {
          ...state.breakfastFormulas,
          isLoading: true,
        }
      };
    case GET_BREAKFAST_FORMULAS_COMPLETED:
      return {
        ...state,
        breakfastFormulas: {
          data: action.payload.result,
          isLoading: false,
          isInitialized: true,
        }
      };
    case GET_BREAKFAST_FORMULAS_FAILED:
      return {
        ...state,
        breakfastFormulas: {
          ...state.breakfastFormulas,
          isLoading: false,
          isInitialized: true,
          hasFailed: true,
        }
      };
    case GET_PRODUCTS:
      return {
        ...state,
        products: {
          ...state.products,
          isLoading: true,
        }
      };
    case GET_PRODUCTS_COMPLETED:
      return {
        ...state,
        products: {
          data: action.payload.result,
          isLoading: false,
          isInitialized: true,
        }
      };
    case GET_PRODUCTS_FAILED:
      return {
        ...state,
        products: {
          ...state.products,
          isLoading: false,
          isInitialized: true,
          hasFailed: true,
        }
      };
    case GET_PRODUCT_GROUPS:
      return {
        ...state,
        productGroups: {
          ...state.productGroups,
          isLoading: true,
        }
      };
    case GET_PRODUCT_GROUPS_COMPLETED:
      return {
        ...state,
        productGroups: {
          data: action.payload.result,
          isLoading: false,
          isInitialized: true,
        }
      };
    case GET_PRODUCT_GROUPS_FAILED:
      return {
        ...state,
        productGroups: {
          ...state.productGroups,
          isLoading: false,
          isInitialized: true,
          hasFailed: true,
        }
      };
    case GET_PRODUCT_PACKAGES:
      return {
        ...state,
        productPackages: {
          ...state.productPackages,
          isLoading: true,
        }
      };
    case GET_PRODUCT_PACKAGES_COMPLETED:
      return {
        ...state,
        productPackages: {
          data: action.payload.result,
          isLoading: false,
          isInitialized: true,
        }
      };
    case GET_PRODUCT_PACKAGES_FAILED:
      return {
        ...state,
        productPackages: {
          ...state.productPackages,
          isLoading: false,
          isInitialized: true,
          hasFailed: true,
        }
      };
    case GET_PRODUCT_CLASSIFICATIONS:
      return {
        ...state,
        productClassifications: {
          ...state.productClassifications,
          isLoading: true,
        }
      };
    case GET_PRODUCT_CLASSIFICATIONS_COMPLETED:
      return {
        ...state,
        productClassifications: {
          data: action.payload.result,
          isLoading: false,
          isInitialized: true,
        }
      };
    case GET_PRODUCT_CLASSIFICATIONS_FAILED:
      return {
        ...state,
        productClassifications: {
          ...state.productClassifications,
          isLoading: false,
          isInitialized: true,
          hasFailed: true,
        }
      };
      case GET_PRODUCT_PARTS:
        return {
          ...state,
          productParts: {
            ...state.productParts,
            isLoading: true,
          }
        };
      case GET_PRODUCT_PARTS_COMPLETED:
        return {
          ...state,
          productParts: {
            data: action.payload.result,
            isLoading: false,
            isInitialized: true,
          }
        };
      case GET_PRODUCT_PARTS_FAILED:
        return {
          ...state,
          productParts: {
            ...state.productParts,
            isLoading: false,
            isInitialized: true,
            hasFailed: true,
          }
        };
      case ADD_CUSTOMER_COMPLETED:
        return {
          ...state,
          customers: [...state.customers, ATSCustomer(action.payload.result)],
        };
      case ADD_COMPANY_COMPLETED:
        return {
          ...state,
          companies: [...state.companies, ATSCompany(action.payload.result)],
        };
    default:
      return state;
  }
};

export default reducers;
