import axios from "axios";
import { toast } from "react-toastify";
import { css } from "emotion";
import Qs from "qs";
import param from "jquery-param";  // Assurez-vous que ce module est installé

const baseUrl = process.env.REACT_APP_API_URL;
const appEnv = process.env.REACT_APP_ENV;
const basciClientID = process.env.REACT_APP_BASIC_ID;
const basicClientSecret = process.env.REACT_APP_BASIC_SECRET;
const tokenUrl = baseUrl + "/api/login/bo";
const apiKey = process.env.REACT_APP_LEMOULIN_API_KEY;
const refreshTokenUrl = baseUrl + "/api/token/refresh";

let isRefreshing = false;
let refreshSubscribers = [];

function onRefreshed(token) {
  refreshSubscribers.forEach((callback) => callback(token));
  refreshSubscribers = [];
}

function addRefreshSubscriber(callback) {
  refreshSubscribers.push(callback);
}

const launchSuccessNotif = (notif, msg) => {
  toast.update(notif, {
    render: msg,
    type: toast.TYPE.SUCCESS,
    className: css({
      transition: "transform 0.6s",
      transform: "rotateY(360deg)",
    }),
  });
};

const launchFailNotif = (notif, msg) => {
  toast.update(notif, {
    render: msg,
    type: toast.TYPE.ERROR,
    className: css({
      transition: "transform 0.6s",
      transform: "rotateY(360deg)",
    }),
  });
};

function handleErrors(err, action, next) {
  let payload = {};
  if (action.payload) {
    payload = action.payload;
  }

  payload.error = err;

  if (err?.response?.status === 401 && !action.meta._retry) {
    const originalRequest = err.config;
    if (!isRefreshing) {
      isRefreshing = true;
      const refreshToken = localStorage.getItem('refresh_token');
      const axiosInstance = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      });
      if (appEnv === 'staging') {
        const basicAuthToken = btoa(`${basciClientID}:${basicClientSecret}`);
        axiosInstance.defaults.headers['Authorization'] = `Basic ${basicAuthToken}`;
      }
      return axiosInstance.post(refreshTokenUrl, { refresh_token: refreshToken }).then(
        (res) => {
          isRefreshing = false;
          localStorage.setItem('token', res.data.token);
          localStorage.setItem('refresh_token', res.data.refresh_token);
          onRefreshed(res.data.token);
          originalRequest.headers['X-Custom-Authorization'] = `Bearer ${res.data.token}`;
          return axios(originalRequest);
        },
        (refreshError) => {
          isRefreshing = false;
          handleErrors(refreshError, action, next);
          localStorage.removeItem("token");
          localStorage.removeItem("refresh_token");
          window.location.href = "/";
        }
      );
    } else {
      return new Promise((resolve) => {
        addRefreshSubscriber((token) => {
          originalRequest.headers['X-Custom-Authorization'] = `Bearer ${token}`;
          resolve(axios(originalRequest));
        });
      });
    }
  }

  next({
    type: `${action.type}_FAILED`,
    payload: payload,
    meta: action.meta,
  });

  return err;
}

function handleResponse(res, action, next) {
  let payload = {};
  if (action.payload) {
    payload = action.payload;
  }
  payload.result = res;

  next({
    type: `${action.type}_COMPLETED`,
    payload: payload,
    meta: action.meta,
  });
  return res;
}

function requestHeaders() {
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  if (appEnv === 'staging') {
    const token = btoa(`${basciClientID}:${basicClientSecret}`);
    headers.Authorization = `Basic ${token}`;
  }

  return headers;
}

const apiService = () => (next) => (action) => {
  const result = next(action);
  if (!action.meta || !action.meta.async) {
    return result;
  }

  if (action.meta.auth) {
    let axiosInstance = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    });
    if (appEnv === 'staging') {
      const basicAuthToken = btoa(`${basciClientID}:${basicClientSecret}`);
      axiosInstance = axios.create({
        headers: {
          'Authorization': `Basic ${basicAuthToken}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      });
    }

    const body = action.meta.body;
    const data = {
      username: body.username,
      password: body.password
    }

    const credentials = axiosInstance.post(tokenUrl, data);
    credentials.then(
      (res) => {
        window.localStorage.setItem("token", res.data.token);
        window.localStorage.setItem("refresh_token", res.data.refresh_token);
        handleResponse(
          {
            data: {
              result: res.data.token,
            },
          },
          action,
          next
        );
      },
      (err) => {
        handleErrors(err, action, next);
      }
    );
  } else {
    let { path, method, body, params, transformResponse } = action.meta;

    if (!path) {
      throw new Error(`'path' not specified for async action ${action.type}`);
    }

    let url = `${baseUrl}${path}`;

    if (method === "GET") {
      body = params;
    }

    return axios({
      method: method,
      url: method === "GET" && body !== undefined ? `${url}?${param(body)}` : url,
      headers: requestHeaders(),
      data: method !== "GET" ? JSON.stringify(body) : null,
      transformResponse: [(data) => {
        if (transformResponse) {
          return transformResponse(data);
        }
        return JSON.parse(data);
      }],
    })
    .then(
      (res) => {
        if (!res || (res.success !== undefined && !res.success) || (res.error)) {
          return handleErrors(res, action, next);
        } else {
          return handleResponse(res, action, next);
        }
      },
      (err) => {
        return handleErrors(err, action, next);
      }
    );
  }
};

let notif = null;

axios.interceptors.request.use(
  function (config) {
    // notif = toast.info("Chargement ...");

    const url = config.url;
    if (url.includes("?")) {
      config.url = config.url + `&apiKey=${apiKey}`;
    } else {
      config.url = config.url + `?apiKey=${apiKey}`;
    }

    config.paramsSerializer = (params) => {
      return Qs.stringify(params, {
        arrayFormat: "brackets",
        encode: false,
      });
    };

    if (appEnv === 'staging') {
      const basicAuthToken = btoa(`${basciClientID}:${basicClientSecret}`);
      config.headers = {...config.headers,
        Authorization: `Basic ${basicAuthToken}`
      }
    }

    let token = window.localStorage.getItem("token");

    if (token) {
      config.headers = {...config.headers,
        'X-Custom-Authorization': `Bearer ${token}`
      }
    } else {
      window.localStorage.removeItem("token");
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    let msg = "Mise à jour réussie";
    if (response.data && response.data.success === false) {
      msg = response.data.message
        ? response.data.message
        : "Une erreur est survenue";
      launchFailNotif(notif, msg);
      return response.data;
    }
    if (response.data && response.data.success && response.data.message) {
      msg = response.data.message;
    }
    // launchSuccessNotif(notif, msg);
    return response.data;
  },
  function (error) {
    let errorMsg = "Une erreur est survenue";
    if (error.response && error.response.data && error.response.data.message) {
      errorMsg = error.response.data.message;
    }
    launchFailNotif(notif, errorMsg);

    if (error.response) {
      // Erreur d'authentification
      if (error.response && error.response.status === 401) {
        const originalRequest = error.config;
        if (!isRefreshing) {
          isRefreshing = true;
          const refreshToken = localStorage.getItem('refresh_token');
          const axiosInstance = axios.create({
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
            }
          });
          if (appEnv === 'staging') {
            const basicAuthToken = btoa(`${basciClientID}:${basicClientSecret}`);
            axiosInstance.defaults.headers['Authorization'] = `Basic ${basicAuthToken}`;
          }
          return axiosInstance.post(refreshTokenUrl, { refresh_token: refreshToken }).then(
            (res) => {
              isRefreshing = false;
              localStorage.setItem('token', res.data.token);
              localStorage.setItem('refresh_token', res.data.refresh_token);
              onRefreshed(res.data.token);
              originalRequest.headers['X-Custom-Authorization'] = `Bearer ${res.data.token}`;
              return axios(originalRequest);
            },
            (refreshError) => {
              isRefreshing = false;
              localStorage.removeItem("token");
              localStorage.removeItem("refresh_token");
              Promise.reject(refreshError);
              return window.location.href = "/";
            }
          );
        } else {
          return new Promise((resolve) => {
            addRefreshSubscriber((token) => {
              originalRequest.headers['X-Custom-Authorization'] = `Bearer ${token}`;
              resolve(axios(originalRequest));
            });
          });
        }
      }
      if (
        error.response &&
        error.response.data &&
        error.response.status === 400
      ) {
        let data = error.response.data;
        data.error = true;
        return data;
      }
    } else {
      console.log("Error", error.message);
    }
    return Promise.reject(error);
  }
);

export default apiService;
