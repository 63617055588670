export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const ADD_CUSTOMER_COMPLETED = "ADD_CUSTOMER_COMPLETED";
export const ADD_CUSTOMER_FAILED = "ADD_CUSTOMER_FAILED";

export const addCustomer = (customerInfo) => ({
  type: ADD_CUSTOMER,
  meta: {
    async: true,
    blocking: true,
    path: `/api/pro/customers`,
    method: "POST",
    body: {customer: customerInfo}
  },
});
