import { validateAndTransformTenderCatalog } from "utils/tender/tenderCatalogUtils";
import { STATenderCatalog, STATenderCatalogRanges } from "transformer/tender/tenderCatalogTransformer";

export const GET_TENDERS = "GET_TENDERS";
export const GET_TENDERS_COMPLETED = "GET_TENDERS_COMPLETED";
export const GET_TENDERS_FAILED = "GET_TENDERS_FAILED";

export const GET_TENDER_CATALOG = "GET_TENDER_CATALOG";
export const GET_TENDER_CATALOG_COMPLETED =
  "GET_TENDER_CATALOG_COMPLETED";
export const GET_TENDER_CATALOG_FAILED = "GET_TENDER_CATALOG_FAILED";

export const GET_TENDER_CATALOG_PRODUCTS = "GET_TENDER_CATALOG_PRODUCTS";
export const GET_TENDER_CATALOG_PRODUCTS_COMPLETED = "GET_TENDER_CATALOG_PRODUCTS_COMPLETED";
export const GET_TENDER_CATALOG_PRODUCTS_FAILED = "GET_TENDER_CATALOG_PRODUCTS_FAILED";

export const UPDATE_TENDER_CATALOG = "UPDATE_TENDER_CATALOG";
export const UPDATE_TENDER_CATALOG_COMPLETED =
  "UPDATE_TENDER_CATALOG_COMPLETED";
export const UPDATE_TENDER_CATALOG_FAILED = "UPDATE_TENDER_CATALOG_FAILED";

export const GET_TENDER_CATALOG_RANGES = "GET_TENDER_CATALOG_RANGES";
export const GET_TENDER_CATALOG_RANGES_COMPLETED = "GET_TENDER_CATALOG_RANGES_COMPLETED";
export const GET_TENDER_CATALOG_RANGES_FAILED = "GET_TENDER_CATALOG_RANGES_FAILED";

export const UPDATE_TENDER_CATALOG_RANGES = "UPDATE_TENDER_CATALOG_RANGES";
export const UPDATE_TENDER_CATALOG_RANGES_COMPLETED = "UPDATE_TENDER_CATALOG_RANGES_COMPLETED";
export const UPDATE_TENDER_CATALOG_RANGES_FAILED = "UPDATE_TENDER_CATALOG_RANGES_FAILED";

export const RESET_TENDER_CATALOG = "RESET_TENDER_CATALOG";

export const RESET_TENDER_CATALOG_RANGES = "RESET_TENDER_CATALOG_RANGES";

export const getTenders = () => ({
  type: GET_TENDERS,
  meta: {
    async: true,
    blocking: true,
    path: `/api/pro/offer/tenders.json`,
    method: "GET",
    params: {},
  },
});

export const getTenderCatalog = (tenderId) => ({
  type: GET_TENDER_CATALOG,
  meta: {
    async: true,
    blocking: true,
    path: `/api/pro/offer/tenders/${tenderId}/catalog.json`,
    method: "GET",
    transformResponse: async (data) => {
      const parsedData = JSON.parse(data);
      return await validateAndTransformTenderCatalog(parsedData);
    },
  },
});

export const updateTenderCatalog = (tenderId, tender_catalog) => ({
  type: UPDATE_TENDER_CATALOG,
  meta: {
    async: true,
    blocking: true,
    path: `/api/pro/offer/tenders/${tenderId}/catalog.json`,
    method: "PUT",
    params: {},
    body: { tender_catalog: STATenderCatalog(tender_catalog) },
  },
});

export const getTenderCatalogRanges = (tenderId) => ({
  type: GET_TENDER_CATALOG_RANGES,
  meta: {
    async: true,
    blocking: true,
    path: `/api/pro/offer/tenders/${tenderId}/catalog/ranges.json`,
    method: "GET",
  },
});

export const updateTenderCatalogRanges = (tenderId, ranges) => ({
  type: UPDATE_TENDER_CATALOG_RANGES,
  meta: {
    async: true,
    blocking: true,
    path: `/api/pro/offer/tenders/${tenderId}/catalog/ranges.json`,
    method: "PUT",
    body: STATenderCatalogRanges(ranges),
  },
});

export const resetTenderCatalog = () => ({
  type: RESET_TENDER_CATALOG,
  payload: {},
});

export const resetTenderCatalogRanges = () => ({
  type: RESET_TENDER_CATALOG_RANGES,
  payload: {},
});
