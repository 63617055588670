import { TenderCatalogSchema } from "schema/Tender/TenderCatalogSchema";
import { ATSTenderCatalog } from "transformer/tender/tenderCatalogTransformer";

// Valide et transforme les données de tarification des appels d'offres de l'api pour le store
export const validateAndTransformTenderCatalog = async (data) => {
  try {
    const catalog =  ATSTenderCatalog(data);
    const validData = await TenderCatalogSchema.validate(catalog, {
      abortEarly: false,
    });
    return validData;
  } catch (error) {
    console.log(error)
    throw error;
  }
};
