import {
  ADD_CUSTOMER,
  ADD_CUSTOMER_FAILED,
} from "./actions";

const initialState = {
  customers: [],
  isLoading: false,
  error: null,
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CUSTOMER:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_CUSTOMER_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default customerReducer;