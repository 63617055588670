import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import CurrencyComponent from '../CurrencyComponent';

const ProductItem = ({
  product,
  index,
  onRemove,
  onUpdate,
  prestationType,
}) => {
  // État local pour gérer la valeur du prix
  const [priceValue, setPriceValue] = useState(product.price || 0);
  useEffect(() => {
    setPriceValue(product.price || 0);
  }, [product.price]);

  const handleQuantityChange = (e) => {
    const quantity = e.target.value <= 0 ? 1 : e.target.value;
    onUpdate({ ...product, quantity: quantity }, index);
  };

  const handlePriceChange = (value) => {
    const price = value;
    setPriceValue(value);
    onUpdate({ ...product, price: price }, index);
  };

  const productName = product?.renammed 
  ?? (prestationType === 'caterer' && product?.catererName 
      ? product.catererName 
      : product.name);

  return (
    <Row key={index} className="my-2">
      <Col md={3}>
        <Form.Control
          type="number"
          value={product.quantity}
          onChange={handleQuantityChange}
          step="0.01"
        />
      </Col>
      <Col md={3}>
        <CurrencyComponent
          initialPrice={priceValue}
          onPriceChange={handlePriceChange}
        />
      </Col>
      <Col md={5}>
        <Form.Control type="text" value={
          (!product?.product_id && !product?.product_part_id)
            ? `${productName} (élément supprimé)`
            : productName
        } readOnly />
      </Col>
      <Col md={1}>
        <Button variant="outline-danger" onClick={() => onRemove(index)}>
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </Col>
    </Row>
  );
};

export default ProductItem;
