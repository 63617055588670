import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateOrderDeliveryCost } from "state/orderEdition/actions";
import CurrencyComponent from './CurrencyComponent';

const DeliveryFeeComponent = ({ orderIndex }) => {
  const dispatch = useDispatch();
  const order = useSelector((state) => state.orderEdition.orders[orderIndex]);
  const [deliveryCost, setDeliveryCost] = useState(order?.deliveryCost || 0);

  const handleValueChange = (value) => {
    setDeliveryCost(value);
    dispatch(updateOrderDeliveryCost(orderIndex, value));
  };
  useEffect(() => {
    setDeliveryCost(order?.deliveryCost || 0);
  }
  , [order]);

  return (
    <div>
      <label>Frais de livraison HT</label>
      <CurrencyComponent
        initialPrice={deliveryCost}
        onPriceChange={handleValueChange}
      />
    </div>
  );
};

export default DeliveryFeeComponent;
