import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import {
  getBreakfastFormulas,
} from "state/adminData/actions";
import { updateOrderItems } from "state/orderEdition/actions";
import { getBreakfastFormulaItemsByOrderIndex } from "state/orderEdition/selectors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTrash, faPen } from "@fortawesome/free-solid-svg-icons";
import CurrencyInput from "react-currency-input-field";
import Select from "react-select";

const BreakfastFormulasComponent = ({ title, orderIndex, products }) => {
  const dispatch = useDispatch();

  const formulasState = useSelector((state) => state.adminData.breakfastFormulas);
  const formulas      = formulasState.data;
  const formulasForSelect = formulas.map((formula) => ({ value: formula.id, label: formula.name, ...formula }));

  const person = useSelector((state) => state.orderEdition.deliveryInfo.person);
  const items = useSelector((state) =>
    getBreakfastFormulaItemsByOrderIndex(state, orderIndex)
  );
  const [selectedFormulas, setSelectedFormulas] = useState(items);
  const [selectedFormula, setSelectedFormula]   = useState([]);

  const [numberOfPeople, setNumberOfPeople] = useState(person ? person : 1);

  const addFormula = (formulaId, quantity) => {
    const formula = formulas.find((f) => f.id === Number(formulaId));
    const updatedFormula = {
      ...formula,
      quantity: quantity,
      formula_elements: formula.formula_elements.map((element) => ({
        ...element,
        // Arrondi à l'entier supérieur
        quantity_per_person: Math.ceil(element.quantity_per_person * quantity),
      })),
    };
    setSelectedFormulas([...selectedFormulas, updatedFormula]);
  };

  const updateFormulaProducts = (formulaIndex, elementIndex, productId) => {
    const newFormulas = [...selectedFormulas];
    newFormulas[formulaIndex].formula_elements[elementIndex].breakfast_product =
      products.find((p) => p.id === Number(productId));
    setSelectedFormulas(newFormulas);
  };

  const updateFormulaProductsQuantity = (
    formulaIndex,
    elementIndex,
    quantity
  ) => {
    const newFormulas = [...selectedFormulas];
    newFormulas[formulaIndex].formula_elements[
      elementIndex
    ].quantity_per_person = Number(quantity);
    setSelectedFormulas(newFormulas);
  };

  const removeFormulaElement = (formulaIndex, productIndex) => {
    const newFormulas = [...selectedFormulas];
    newFormulas[formulaIndex].formula_elements.splice(productIndex, 1);
    setSelectedFormulas(newFormulas);
  };

  const addFormulaElement = (formulaIndex) => {
    const newFormulas = [...selectedFormulas];
    newFormulas[formulaIndex].formula_elements.push({
      quantity_per_person: 1,
      breakfast_product: products[0],
    });
    setSelectedFormulas(newFormulas);
  };

  // S'assure que les formules BF sont bien chargés sinon lance leur récupération à l'API
  useEffect(() => {
    if (!formulasState.isLoading && !formulasState.isInitialized) {
      dispatch(getBreakfastFormulas());
    }
  }, [dispatch, formulasState]);

  useEffect(() => {
    dispatch(updateOrderItems(orderIndex, selectedFormulas, "formula"));
  }, [selectedFormulas, orderIndex, dispatch]);

  const handleSelectChange = (selectedItem) => {
    setSelectedFormula(selectedItem);
  };

  const handleAddFormula = () => {
    if (selectedFormula.value) {
      addFormula(selectedFormula.value, numberOfPeople);
    }
  };

  // const clearContent = () => {
  //   setSelectedFormulas([]);
  // };

  const updateFormulaName = (formulaIndex, newName) => {
    const newFormulas = [...selectedFormulas];
    newFormulas[formulaIndex].name = newName;
    setSelectedFormulas(newFormulas);
  };

  const updateFormulaPrice = (formulaIndex, newPrice) => {
    const newFormulas = [...selectedFormulas];
    newFormulas[formulaIndex].price = newPrice;
    setSelectedFormulas(newFormulas);
  };

  const updateFormulaQuantity = (formulaIndex, newQuantity) => {
    if (newQuantity < 1) {
      newQuantity = 1;
    }
    const newFormulas = [...selectedFormulas];
    newFormulas[formulaIndex].quantity = newQuantity;
    setSelectedFormulas(newFormulas);
  };

  const removeSelectedFormula = (formulaIndex) => {
    const newFormulas = [...selectedFormulas];
    newFormulas.splice(formulaIndex, 1);
    setSelectedFormulas(newFormulas);
  };

  return (
    <Container>
      <Row>
        <Col sm={12} md={12}>
          <h5>{title}</h5>
        </Col>
        {/* <Col sm={12} md={6} align="right">
          <Button variant="danger" onClick={clearContent}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </Col> */}
      </Row>

      {selectedFormulas.map((selectedFormula, formulaIndex) => (
        <div key={formulaIndex}>
          <Col
            md={12}
            className="formula"
            style={{
              backgroundColor: "white",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <Row
              md={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "lightgrey",
                padding: "10px",
              }}
            >
              <Col md={10}>
                <h5>
                  <input
                    type="text"
                    value={selectedFormula?.name ? selectedFormula.name : ""}
                    onChange={(e) =>
                      updateFormulaName(formulaIndex, e.target.value)
                    }
                    style={{
                      backgroundColor: "#f8f8f6",
                      border: "grey",
                      borderRadius: "4px",
                      width: "100%",
                    }}
                  />
                </h5>
              </Col>
              <Col md={2} align="right">
                <button
                  className="btn btn-danger"
                  onClick={() => removeSelectedFormula(formulaIndex)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </Col>
              <Col md={4}>
                <div>
                  Prix par personne :
                  <CurrencyInput
                    id="formulaPrice"
                    name="formulaPrice"
                    placeholder="Montant"
                    className="form-control"
                    defaultValue={
                      Number.isNaN(selectedFormula.price)
                        ? 0
                        : selectedFormula.price / 100
                    }
                    decimalSeparator="."
                    decimalsLimit={2}
                    decimalScale={2}
                    allowNegativeValue={false}
                    intlConfig={{ locale: "fr-FR", currency: "EUR" }}
                    onValueChange={(value) =>
                      updateFormulaPrice(formulaIndex, parseFloat(value * 100))
                    }
                  />
                </div>
              </Col>
              <Col md={4}>
                <div>
                  Nb personnes{" "}
                  <input
                    type="number"
                    defaultValue={selectedFormula.quantity}
                    onChange={(e) =>
                      updateFormulaQuantity(formulaIndex, e.target.value)
                    }
                    style={{
                      fontWeight: "bold",
                    }}
                    className={"form-control"}
                  />{" "}
                </div>
              </Col>
              <Col md={4}>
                <b>
                  soit{" "}
                  {(selectedFormula.price *
                    (selectedFormula.quantity
                      ? selectedFormula.quantity
                      : numberOfPeople)) /
                    100}{" "}
                  €
                </b>
              </Col>
            </Row>
            <hr />
            {selectedFormula.formula_elements.map((element, elementIndex) => (
              <Row
                key={`{${elementIndex}-${element.breakfast_product.id}}`}
                style={{
                  margin: "10px 0",
                }}
              >
                <Col md={3}>
                  <input
                    type="number"
                    className="form-control"
                    defaultValue={element.quantity_per_person}
                    onChange={(e) =>
                      updateFormulaProductsQuantity(
                        formulaIndex,
                        elementIndex,
                        e.target.value
                      )
                    }
                  />
                </Col>
                <Col md={8}>
                  <select
                    className="form-control"
                    onChange={(e) =>
                      updateFormulaProducts(
                        formulaIndex,
                        elementIndex,
                        e.target.value
                      )
                    }
                    defaultValue={element.breakfast_product.id}
                  >
                    {products.map((product, productIndex) => (
                      <option key={productIndex} value={product.id}>
                        {product.name}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col md={1}>
                  <button
                    className="btn btn-danger"
                    onClick={() =>
                      removeFormulaElement(formulaIndex, elementIndex)
                    }
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </Col>
              </Row>
            ))}
            <Col md={12} style={{ textAlign: "left" }}>
              <button
                className="btn btn-primary"
                onClick={() => addFormulaElement(formulaIndex)}
              >
                Ajouter un élément
              </button>
            </Col>
          </Col>
        </div>
      ))}
      <hr />
      <Form>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="statusSelect">
            <Form.Label>Formule</Form.Label>
            <Select
              placeholder="Chercher une formule"
              options={formulasForSelect}
              closeMenuOnSelect={true}
              onChange={handleSelectChange}
              value={selectedFormula}
              name={"formulaId"}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="numberOfPeople">
            <Form.Label>Nb personnes*</Form.Label>
            <Form.Control
              type="number"
              value={numberOfPeople}
              onChange={(e) => setNumberOfPeople(e.target.value)}
              required
            />
          </Form.Group>
        </Row>
        <Button variant="success" onClick={handleAddFormula}>
          Ajouter cette formule
        </Button>
      </Form>
    </Container>
  );
};

export default BreakfastFormulasComponent;
