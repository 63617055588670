import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Form } from "react-bootstrap";
import { getProductGroups } from "state/adminData/actions";
import { updateOrderItems } from "state/orderEdition/actions";
import { getProductGroupsItemsByOrderIndex } from "state/orderEdition/selectors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import CurrencyComponent from '../CurrencyComponent';

const ProductGroupsComponent = ({ title, orderIndex, products, tenderCatalog }) => {
  const dispatch = useDispatch();
  const order = useSelector((state) => state.orderEdition.orders[orderIndex]);
  const prestationType = order?.prestationType;
  const productGroupsState = useSelector((state) => state.adminData.productGroups);
  const productGroups = productGroupsState.data;
  const [isActive, setIsActive] = useState(true);
  const productGroupsForSelect = productGroups.filter((group) => group.types.includes(prestationType) && group.active === isActive).map((group) => (
  {
    value: group.id,
    label: group.name,
    ...group,
  }));

  const productGroupsForSelectWithCatalogName= productGroupsForSelect.map((group) => {
    const tenderCatalogItem = tenderCatalog?.items && tenderCatalog.items.find((item) => item.group_id === group.id);
    if (tenderCatalogItem) {
      const label = tenderCatalogItem.renammed ? tenderCatalogItem.renammed : group.name;
      return { ...group, label: label };
    }
    return group;
  });

  const person = useSelector((state) => state.orderEdition.orders[orderIndex].deliveryInfo.person);
  const items = useSelector((state) =>
    getProductGroupsItemsByOrderIndex(state, orderIndex)
  );
  const [selectedProductGroups, setSelectedProductGroups] = useState(items);
  const [selectedProductGroup, setSelectedProductGroup] = useState([]);
  const [numberOfPeople, setNumberOfPeople] = useState(person ? person : 1);
  const [launchDispatch, setLaunchDispatch] = useState(false);

  // Synchronise l'état local avec le store, mais seulement après le chargement initial
  useEffect(() => {
    if (launchDispatch) {
      dispatch(updateOrderItems(orderIndex, selectedProductGroups, "productGroup"));
      setLaunchDispatch(false);
    }
  }, [selectedProductGroups, orderIndex, dispatch, launchDispatch]);

  // Les prix des groupes peuvent être mise à jour si l'AO est activée, il faut donc mettre selectedProductGroups à jour
  useEffect(() => {
    // Si selectedProductGroups est différent de items, on met à jour
    if (JSON.stringify(selectedProductGroups) !== JSON.stringify(items)) {
      setSelectedProductGroups(items);
      setLaunchDispatch(false);
    }
  }, [items]);

  // Quand l'index change, on charge dans le composant les items de la commande associée à cette index
  useEffect(() => {
    setSelectedProductGroups(items);
    setSelectedProductGroup([]);
    setLaunchDispatch(false);
  }, [orderIndex]);

  useEffect(() => {
    if (!productGroupsState.isLoading && !productGroupsState.isInitialized) {
      dispatch(getProductGroups());
    }
  }, [dispatch, productGroupsState]);

  // Quand le nombre de personnes change au niveau de la commande, change le champ de nombre de personnes par défaut pour les futurs groupes
  // Met à jour également les prix des articles si AO
  useEffect(() => {
    if (person && parseInt(numberOfPeople) !== parseInt(person)) {
      setNumberOfPeople(person ? person : 1);
    }

  }, [person]);

  const addProductGroup = (groupId, quantity) => {
    const group = productGroups.find((g) => g.id === Number(groupId));
    const tenderCatalogItem = tenderCatalog?.items && tenderCatalog.items.find((item) => item.group_id === group.id);
    const renammed = tenderCatalogItem ? tenderCatalogItem?.renammed : null;

    let tenderCatalogItemPricing = tenderCatalogItem?.pricings?.find((pricing) => 
      pricing.min_persons <= person &&
      pricing.max_persons >= person &&
      pricing.price !== null && // Vérifie que le palier a un prix valide
      pricing.prestation_types.includes(prestationType)
    );
    
    // Si aucun palier direct ne correspond avec un prix valide
    if (!tenderCatalogItemPricing) {
      const pricingsWithPrice = tenderCatalogItem?.pricings?.filter((pricing) => 
        pricing.price !== null && // Prend seulement les paliers avec un prix valide
        pricing.prestation_types.includes(prestationType) &&
        pricing.min_persons <= person // Exclut les paliers où person est inférieur au minimum
      );
    
      // Trouve le palier le plus proche par distance absolue
      const closestPricing = pricingsWithPrice?.reduce((closest, pricing) => {
        const currentDistance = Math.abs(pricing.max_persons - person);
        const closestDistance = Math.abs(closest.max_persons - person);
        return currentDistance < closestDistance ? pricing : closest;
      }, pricingsWithPrice[0]);
    
      // Utilise le palier le plus proche si trouvé
      if (closestPricing) {
        tenderCatalogItemPricing = closestPricing;
      }
    }
    
    // Si dans le catalogue, il n'y a pas de prix pour le nombre de personnes, on prend le prix hors palier
    let groupPrice = tenderCatalogItemPricing ? tenderCatalogItemPricing.price : group.price;
    if (!tenderCatalogItemPricing && tenderCatalogItem) {
      if (tenderCatalogItem.price) {
        groupPrice = tenderCatalogItem.price;
      }
    }
    if (groupPrice === null) {
      groupPrice = group.price;
    }
    const updatedGroup = {
      name: group.name,
      price: groupPrice,
      quantity: quantity,
      type: "productGroup",
      group_id: group.id,
      products: group.products.map((product) => ({
        quantity: product.quantity,
        product_id: product.product_id,
      })),
      renammed: renammed,
    };
    setSelectedProductGroups([...selectedProductGroups, updatedGroup]);
    setLaunchDispatch(true);
  };
  const updateProductGroupProductsQuantity = (
    groupIndex,
    productIndex,
    quantity
  ) => {
    const newGroups = [...selectedProductGroups];
    newGroups[groupIndex].products[productIndex].quantity = Number(quantity);
    setSelectedProductGroups(newGroups);
    setLaunchDispatch(true);
  };

  const handleSelectChange = (selectedItem) => {
    setSelectedProductGroup(selectedItem);
  };

  const handleAddProductGroup = () => {
    if (selectedProductGroup.value) {
      addProductGroup(selectedProductGroup.value, numberOfPeople);
      setSelectedProductGroup([]);
    }
  };

  const updateProductGroupName = (groupIndex, newName) => {
    const newGroups = [...selectedProductGroups];
    newGroups[groupIndex].name = newName;
    setSelectedProductGroups(newGroups);
    setLaunchDispatch(true);
  };

  const updateProductGroupPrice = (groupIndex, newPrice) => {
    const newGroups = [...selectedProductGroups];
    newGroups[groupIndex].price = newPrice;
    setSelectedProductGroups(newGroups);
    setLaunchDispatch(true);
  };

  const updateProductGroupQuantity = (groupIndex, newQuantity) => {
    if (newQuantity < 1) {
      newQuantity = 1;
    }
    const newGroups = [...selectedProductGroups];
    newGroups[groupIndex].quantity = newQuantity;
    setSelectedProductGroups(newGroups);
    setLaunchDispatch(true);
  };

  const removeSelectedProductGroup = (groupIndex) => {
    const newGroups = [...selectedProductGroups];
    newGroups.splice(groupIndex, 1);
    setSelectedProductGroups(newGroups);
    setLaunchDispatch(true);
  };
  
  return (
    <>
      <Row>
        <Col sm={12} md={6}>
          <h5>{title}</h5>
        </Col>
      </Row>
      {selectedProductGroups.map((selectedGroup, groupIndex) => (
        <div key={groupIndex}>
          <Col
            md={12}
            className="group"
            style={{
              backgroundColor: "white",
              padding: "10px",
              marginBottom: "10px"
            }}
          >
            <Row
              md={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#eee",
                border: "solid 1px #ccc",
                padding: "20px 8px 10px 8px",
                borderRadius: "4px",
                position: "sticky",
                top: "0",
                zIndex: "1",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
              }}
            >
              <Col md={4}>
                <Form.Group>
                  <Form.Label><b>GROUPE DE PRODUIT :</b></Form.Label>
                  <Form.Control
                    type="text"
                    className="form-control"
                    value={selectedGroup?.renammed ?? selectedGroup?.name ?? ""}
                    onChange={(e) =>
                      updateProductGroupName(groupIndex, e.target.value)
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <div>
                  Prix unitaire :
                  <CurrencyComponent
                    initialPrice={selectedGroup.price}
                    onPriceChange={(newPrice) => updateProductGroupPrice(groupIndex, newPrice)}
                  />
                </div>
              </Col>
              <Col md={3}>
                <div>
                  Quantité{" "}
                  <input
                    type="number"
                    value={selectedGroup.quantity}
                    onChange={(e) =>
                      updateProductGroupQuantity(groupIndex, e.target.value)
                    }
                    className={"form-control"}
                    step="0.01"
                  />{" "}
                </div>
              </Col>
              <Col md={2}>
                <b>
                  soit{" "}
                  {(selectedGroup.price *
                    (selectedGroup.quantity
                      ? selectedGroup.quantity
                      : numberOfPeople)) /
                    100}{" "}
                  €
                </b>
              </Col>
              <Button
                variant="outline-danger"
                onClick={() => removeSelectedProductGroup(groupIndex)}
                style={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </Row>
            <hr />
            {selectedGroup.products.map((selectedGroupProduct, selectedGroupProductIndex) => (
              <Row
                key={`{${selectedGroupProductIndex}-${selectedGroupProduct.id}}`}
                style={{
                  margin: "10px 0",
                }}
              >
                <Col md={3}>
                  <input
                    type="number"
                    className="form-control"
                    value={selectedGroupProduct.quantity}
                    disabled="disabled"
                    step="0.01"
                    onChange={(e) =>
                      updateProductGroupProductsQuantity(
                        groupIndex,
                        selectedGroupProductIndex,
                        e.target.value
                      )
                    }
                  />
                </Col>
                <Col md={9}>
                <Form.Control
                  type="text"
                  value={
                  (!selectedGroupProduct?.product_id)
                    ? `${selectedGroupProduct.name} (élément supprimé)`
                    : products
                    .map((product) => ({
                      value: product.id,
                      label: product.name
                    }))
                    .find(option => option.value === selectedGroupProduct.product_id)?.label
                  }
                  readOnly
                />
                </Col>
              </Row>
            ))}
          </Col>
        </div>
      ))}
      <hr />
      <Form>
        <Row className="mb-3">
          <Form.Group as={Col} md="4" controlId="statusSelect">
            <Form.Label>Nom du groupe de produits</Form.Label>
            <Select
              placeholder="Chercher un groupe de produits"
              options={productGroupsForSelectWithCatalogName}
              closeMenuOnSelect={true}
              onChange={handleSelectChange}
              value={selectedProductGroup}
              name={"groupId"}
              menuPortalTarget={document.body} 
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            />
            <div
              style={{
                color: 'hsl(0, 0%, 40%)',
                display: 'inline-block',
                fontSize: 12,
                fontStyle: 'italic',
                marginTop: '1em',
              }}
            >
              <label style={{ marginRight: '1em' }}>
                <input type="checkbox" checked={isActive} onChange={() => setIsActive((state) => !state)}/> Groupe de produits actifs
              </label>
            </div>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="numberOfPeople">
            <Form.Label>Quantité *</Form.Label>
            <Form.Control
              type="number"
              value={numberOfPeople}
              onChange={(e) => setNumberOfPeople(e.target.value)}
              required
              step="any"
            />
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label style={{ color: 'white' }}>-</Form.Label><br/>
            <Button variant="info" onClick={handleAddProductGroup} className="w-100">
              Ajouter ce groupe
            </Button>
          </Form.Group>
        </Row>
      </Form>
    </>
  );
};

export default ProductGroupsComponent;
