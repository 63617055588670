import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { getProductPackages, getProductParts } from "state/adminData/actions";
import { updateOrderItems } from "state/orderEdition/actions";
import { getProductPackagesItemsByOrderIndex } from "state/orderEdition/selectors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTrash, faPen } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import CurrencyComponent from '../CurrencyComponent';

const ProductPackagesComponent = ({ title, orderIndex, products, tenderCatalog }) => {
  const dispatch = useDispatch();
  const order = useSelector((state) => state.orderEdition.orders[orderIndex]);
  const prestationType = order?.prestationType;

  const productPackagesState = useSelector((state) => state.adminData.productPackages);
  const productPackages = productPackagesState.data;

  const productPartsState = useSelector((state) => state.adminData.productParts);
  const productParts = productPartsState.data;
  const [isActive, setIsActive] = useState(true);
  const productPackagesForSelect = productPackages
    .filter((productPackage) => productPackage.types.includes(prestationType) && productPackage.active === isActive)
    .map((productPackage) => ({
      value: productPackage.id,
      label: productPackage.name,
      ...productPackage,
    }));

  const productPackagesForSelectWithCatalogName = productPackagesForSelect.map((productPackage) => {
    const tenderCatalogItem = tenderCatalog?.items?.find((item) => item.package_id === productPackage.id);
    if (tenderCatalogItem) {
      const label = tenderCatalogItem.renammed || productPackage.name;
      return { ...productPackage, label };
    }
    return productPackage;
  });

  const person = useSelector((state) => state.orderEdition.orders[orderIndex].deliveryInfo.person);
  const items = useSelector((state) => getProductPackagesItemsByOrderIndex(state, orderIndex));
  const [selectedProductPackages, setSelectedProductPackages] = useState(items);
  const [selectedProductPackage, setSelectedProductPackage] = useState([]);
  const [numberOfPeople, setNumberOfPeople] = useState(person || 1);
  const [tempQuantity, setTempQuantity] = useState({});
  const [launchDispatch, setLaunchDispatch] = useState(false);

  // Synchronise l'état local avec le store, mais seulement après le chargement initial
  useEffect(() => {
    if (launchDispatch) {
      dispatch(updateOrderItems(orderIndex, selectedProductPackages, "productPackage"));
      setLaunchDispatch(false);
    }
  }, [selectedProductPackages, orderIndex, dispatch, launchDispatch]);
  
  // Les prix des packages peuvent être mise à jour si l'AO est activée, il faut donc mettre selectedProductPackages à jour
  useEffect(() => {
    // Si selectedProductPackages est différent de items, on met à jour
    if (JSON.stringify(selectedProductPackages) !== JSON.stringify(items)) {
      setSelectedProductPackages(items);
      setLaunchDispatch(false);
    }
  }, [items]);

  // Quand l'index change, on charge dans le composant les items de la commande associée à cette index
  useEffect(() => {
    setSelectedProductPackages(items);
    setSelectedProductPackage([]);
    setLaunchDispatch(false);
  }, [orderIndex]);

  useEffect(() => {
    if (!productPackagesState.isLoading && !productPackagesState.isInitialized) {
      dispatch(getProductPackages());
    }
  }, [dispatch, productPackagesState]);

  useEffect(() => {
    if (!productPartsState.isLoading && !productPartsState.isInitialized) {
      dispatch(getProductParts());
    }
  }, [dispatch, productPartsState]);

  // Quand le nombre de personnes change au niveau de la commande, change le champ de nombre de personnes par défaut pour les futurs forfaits
  // Met à jour également les prix des articles si AO
  useEffect(() => {
    setNumberOfPeople(person ? person : 1);
  }, [person]);

  const addProductPackage = (packageId, quantity) => {
    const productPackage = productPackages.find((g) => g.id === Number(packageId));
    const tenderCatalogItem = tenderCatalog?.items && tenderCatalog.items.find((item) => item.package_id === productPackage.id);
    const renammed = tenderCatalogItem ? tenderCatalogItem?.renammed : null;

    let tenderCatalogItemPricing = tenderCatalogItem?.pricings?.find((pricing) => 
      pricing.min_persons <= person &&
      pricing.max_persons >= person &&
      pricing.price !== null && // Vérifie que le palier a un prix valide
      pricing.prestation_types.includes(prestationType)
    );
    
    // Si aucun palier direct ne correspond avec un prix valide
    if (!tenderCatalogItemPricing) {
      const pricingsWithPrice = tenderCatalogItem?.pricings?.filter((pricing) => 
        pricing.price !== null && // Prend seulement les paliers avec un prix valide
        pricing.prestation_types.includes(prestationType) &&
        pricing.min_persons <= person // Exclut les paliers où person est inférieur au minimum
      );
    
      // Trouve le palier le plus proche par distance absolue
      const closestPricing = pricingsWithPrice?.reduce((closest, pricing) => {
        const currentDistance = Math.abs(pricing.max_persons - person);
        const closestDistance = Math.abs(closest.max_persons - person);
        return currentDistance < closestDistance ? pricing : closest;
      }, pricingsWithPrice[0]);
    
      // Utilise le palier le plus proche si trouvé
      if (closestPricing) {
        tenderCatalogItemPricing = closestPricing;
      }
    }

    let finalUnitPrice = tenderCatalogItemPricing ? tenderCatalogItemPricing.price : tenderCatalogItem?.price ? tenderCatalogItem.price : productPackage.price;
    if (finalUnitPrice === null) {
      finalUnitPrice = productPackage.price;
    }
    const packageParticipantsNumber = productPackage.participants_number || 1;
    const updatedPackage = {
      name: productPackage.name,
      price: (finalUnitPrice / packageParticipantsNumber),
      quantity: quantity,
      type: "productPackage",
      package_id: productPackage.id,
      products: productPackage.products.map((product) => ({
        quantity: Math.ceil(product.quantity * ( quantity / packageParticipantsNumber)),
        product_id: product.product_id
      })),
      product_parts: productPackage.product_parts.map((productPart) => ({
        quantity: Math.ceil(productPart.quantity * ( quantity / packageParticipantsNumber)),
        product_part_id: productPart.product_part_id
      })),
      renammed: renammed,
      caterer_price_group: productPackage.caterer_price_group,
    };
    setSelectedProductPackages([...selectedProductPackages, updatedPackage]);
    setLaunchDispatch(true);
  };

  const handleConfirmQuantity = (packageIndex) => {
    updateProductPackageQuantity(packageIndex, tempQuantity[packageIndex] || selectedProductPackages[packageIndex].quantity);
    setTempQuantity(() => ({})); // Reset après la confirmation
  };

  const handleQuantityChange = (packageIndex, newValue) => {
    setTempQuantity((prev) => ({ ...prev, [packageIndex]: newValue }));
  };

  const updateProductPackageProducts = (packageIndex, productIndex, productId, type) => {
    const newPackages = [...selectedProductPackages];
    if (type === 'product') {
      newPackages[packageIndex].products[productIndex].product_id = productId;
    } else if (type === 'productPart') {
      newPackages[packageIndex].product_parts[productIndex].product_part_id = productId;
    }
    setSelectedProductPackages(newPackages);
    setLaunchDispatch(true);
  };

  const updateProductPackageProductsQuantity = (
    packageIndex,
    productIndex,
    quantity,
    type
  ) => {
    const newPackages = [...selectedProductPackages];
    if (type === 'product') {
      newPackages[packageIndex].products[productIndex].quantity = Number(quantity);
    } else if (type === 'productPart') {
      newPackages[packageIndex].product_parts[productIndex].quantity = Number(quantity);
    }
    setSelectedProductPackages(newPackages);
    setLaunchDispatch(true);
  };

  const removeProductPackageElement = (packageIndex, productIndex, type) => {
    const newPackages = [...selectedProductPackages];
    if (type === 'product') {
      newPackages[packageIndex].products.splice(productIndex, 1);
    }
    else if (type === 'productPart') {
      newPackages[packageIndex].product_parts.splice(productIndex, 1);
    }
    setSelectedProductPackages(newPackages);
    setLaunchDispatch(true);
  };

  const addProductPackageElement = (packageIndex, type) => {
    const newPackages = [...selectedProductPackages];
    if (type === 'product') {
      newPackages[packageIndex].products.push({
        quantity: 1,
        product_id: products[0].id,
      });
    }
    else if (type === 'productPart') {
      newPackages[packageIndex].product_parts.push({
        quantity: 1,
        product_part_id: productParts[0].id
      });
    }
    setSelectedProductPackages(newPackages);
    setLaunchDispatch(true);
  };

  const handleSelectChange = (selectedItem) => {
    setSelectedProductPackage(selectedItem);
  };

  const handleAddProductPackage = () => {
    if (selectedProductPackage.value) {
      addProductPackage(selectedProductPackage.value, numberOfPeople);
      setSelectedProductPackage([]);
    }
  };

  const updateProductPackageName = (packageIndex, newName) => {
    const newPackages = [...selectedProductPackages];
    newPackages[packageIndex].name = newName;
    setSelectedProductPackages(newPackages);
    setLaunchDispatch(true);
  };

  const updateProductPackagePrice = (packageIndex, newPrice) => {
    const newPackages = [...selectedProductPackages];
    newPackages[packageIndex].price = newPrice;
    setSelectedProductPackages(newPackages);
    setLaunchDispatch(true);
  };

  /**
   * Met à jour le nombre de personnes d'un forfait et ajuste la quantité des produits en conséquence
   */
  const updateProductPackageQuantity = (packageIndex, newQuantity) => {
    if (newQuantity < 1) {
      return;
    }
    // Savoir si c'est un string ou un nombre
    if (typeof newQuantity === 'string') {
    }
    newQuantity = (newQuantity).replace(',', '.');

    const newPackages = [...selectedProductPackages];
    // L'ancienne quantité du forfait
    const oldPackageQuantity = newPackages[packageIndex].quantity;
    // La nouvelle quantité du forfait
    const newPackageQuantity = newQuantity;
    // Le forfait dans le panier concerné
    const selectedPackage = newPackages[packageIndex];
    // Le forfait dans l'offre
    const selectedPackageInOffer = productPackages.find((productPackage) => productPackage.id === selectedPackage.package_id);
    // La quantité initiale du forfait dans l'offre
    const packageInitialQuantity = selectedPackageInOffer ? selectedPackageInOffer.participants_number : null;

    newPackages[packageIndex].quantity = newPackageQuantity;
    if (newPackages[packageIndex] && newPackages[packageIndex]?.products) {
      newPackages[packageIndex].products.map((product) => {
        // La quantité initiale du produit dans le forfait
        let initialProductQuantity = null;
        const initialProductInPackage = selectedPackage && selectedPackageInOffer?.products && selectedPackageInOffer.products.find((p) => p.product_id === product.product_id);
        if (initialProductInPackage) {
          initialProductQuantity = initialProductInPackage.quantity;
        }

        let productQuantity = (product.quantity * newPackageQuantity) / oldPackageQuantity;

        // Se base sur la quantité initiale du produit dans le forfait pour recalculer la quantité si on a la quantité initiale du forfait
        if (packageInitialQuantity && initialProductQuantity) {
          productQuantity = (initialProductQuantity * newPackageQuantity) / packageInitialQuantity;
        }
        // Arrondir à l'entier supérieur
        productQuantity = Math.ceil(productQuantity);
        product.quantity = productQuantity;
        return product;
      });
    }
    if (newPackages[packageIndex] && newPackages[packageIndex]?.product_parts) {
      newPackages[packageIndex].product_parts.map((productPart) => {

        // La quantité initiale de la part de produit dans le forfait
        let initialProductPartQuantity = null;
        const initialProductPartInPackage = selectedPackage && selectedPackageInOffer?.products && selectedPackageInOffer.products.find((p) => p.product_id === productPart.product_part_id);
        if (initialProductPartInPackage) {
          initialProductPartQuantity = initialProductPartInPackage.quantity;
        }

        let productPartQuantity = (productPart.quantity * newPackageQuantity) / oldPackageQuantity;

        // Se base sur la quantité initiale du produit dans le forfait pour recalculer la quantité si on a la quantité initiale du forfait
        if (packageInitialQuantity && initialProductPartQuantity) {
          productPartQuantity = (initialProductPartQuantity * newPackageQuantity) / packageInitialQuantity;
        }
        // Arrondir à l'entier supérieur
        productPartQuantity = Math.ceil(productPartQuantity);
        productPart.quantity = productPartQuantity;
        return productPart;
      });
    }
    setSelectedProductPackages(newPackages);
    setLaunchDispatch(true);
  };

  const removeSelectedProductPackage = (packageIndex) => {
    const newPackages = [...selectedProductPackages];
    newPackages.splice(packageIndex, 1);
    setSelectedProductPackages(newPackages);
    setLaunchDispatch(true);
  };

  const setNumberOfPeopleTest = (newNumberOfPeople) => {
    setNumberOfPeople(newNumberOfPeople);
  }
  return (
    <>
      <Row>
        <Col sm={12} md={6}>
          <h5>{title}</h5>
        </Col>
      </Row>
      {selectedProductPackages.map((selectedPackage, packageIndex) => (
        <div key={packageIndex}>
          <Col md={12} className="package" style={{ backgroundColor: "white", padding: "10px", marginBottom: "10px" }}>
            <Row md={12} className="align-items-center" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "#eee", border: "solid 1px #ccc", padding: "20px 8px 10px 8px", borderRadius: "4px", position: "sticky", top: "0", zIndex: "1", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
              <Col md={4}>
                <Form.Group>
                  <Form.Label><b>FORFAIT :</b></Form.Label>
                  <Form.Control type="text" className="form-control" value={selectedPackage?.renammed ?? selectedPackage?.name ?? ""} onChange={(e) => updateProductPackageName(packageIndex, e.target.value)} />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>Prix par personne :</Form.Label>
                  <CurrencyComponent initialPrice={selectedPackage.price} onPriceChange={(newPrice) => updateProductPackagePrice(packageIndex, newPrice)} />
                </Form.Group>
              </Col>
              <Col md={3}>
              <Form.Group controlId={`${packageIndex}-quantity`}>
                <Form.Label>Nb personnes</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="number"
                    value={tempQuantity[packageIndex] ?? selectedPackage.quantity}
                    onChange={(e) => handleQuantityChange(packageIndex, e.target.value)}
                    className={"form-control"}
                    step="0.01"
                  />
                  { Object.keys(tempQuantity).length > 0 &&
                    <InputGroup.Append>
                      <Button variant="primary" onClick={() => handleConfirmQuantity(packageIndex)}>
                        Recalculer
                      </Button>
                    </InputGroup.Append>
                   }
                </InputGroup>
              </Form.Group>
              </Col>
              <Col md={2}>
                <b>
                  soit{" "}
                  {(selectedPackage.price * (selectedPackage.quantity || numberOfPeople)) / 100} €
                </b>
              </Col>
              <Button variant="outline-danger" onClick={() => removeSelectedProductPackage(packageIndex)} style={{ position: "absolute", top: "5px", right: "5px" }}>
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </Row>
            <hr />
            
            { (tempQuantity, Object.keys(tempQuantity).length === 0) ?
              <>
                {selectedPackage?.products && selectedPackage.products.map((selectedPackageProduct, selectedPackageProductIndex) => (
                  <Row
                    key={`{${selectedPackageProductIndex}-${selectedPackageProduct.id}}`}
                    style={{
                      margin: "10px 0",
                    }}
                  >
                    <Col md={3}>
                      <input
                        type="number"
                        className="form-control"
                        value={selectedPackageProduct.quantity}
                        step="0.01"
                        onChange={(e) =>
                          updateProductPackageProductsQuantity(
                            packageIndex,
                            selectedPackageProductIndex,
                            e.target.value,
                            'product'
                          )
                        }
                      />
                    </Col>
                    <Col md={8}>
                      {selectedPackageProduct.product_id ?
                        <Select
                          options={products.map((product) => ({
                            value: product.id,
                            label: product.name
                          }))}
                          closeMenuOnSelect={true}
                          onChange={(selectedOption) =>
                            updateProductPackageProducts(
                              packageIndex,
                              selectedPackageProductIndex,
                              selectedOption.value,
                              'product'
                            )
                          }
                          value={products
                            .map((product) => ({
                              value: product.id,
                              label: product.name
                            }))
                            .find(option => option.value === selectedPackageProduct.product_id)}
                        />
                      :
                        <Form.Control
                          type="text"
                          value={`${selectedPackageProduct.name} (élément supprimé)`}
                          readOnly
                        />
                    }
                    </Col>
                    <Col md={1}>
                      <Button
                        variant="outline-danger"
                        onClick={() =>
                          removeProductPackageElement(packageIndex, selectedPackageProductIndex, 'product')
                        }
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </Button>
                    </Col>
                  </Row>
                ))}
                <Col md={6} style={{ textAlign: "left" }}>
                  <button
                    className="btn btn-secondary"
                    onClick={() => addProductPackageElement(packageIndex, 'product')}
                  >
                    Ajouter un produit
                  </button>
                </Col>

                {selectedPackage?.product_parts && selectedPackage.product_parts.map((selectedPackageProductPart, selectedPackageProductPartIndex) => (
                  <Row
                    key={`{${selectedPackageProductPartIndex}-${selectedPackageProductPart.id}}`}
                    style={{
                      margin: "10px 0",
                    }}
                  >
                    <Col md={3}>
                      <input
                        type="number"
                        className="form-control"
                        value={selectedPackageProductPart.quantity}
                        step="0.01"
                        onChange={(e) =>
                          updateProductPackageProductsQuantity(
                            packageIndex,
                            selectedPackageProductPartIndex,
                            e.target.value,
                            'productPart'
                          )
                        }
                      />
                    </Col>
                    <Col md={8}>
                    {selectedPackageProductPart.product_part_id ?
                    <Select
                      options={productParts.map((productPart) => ({
                        value: productPart.id,
                        label: productPart.name
                      }))}
                      closeMenuOnSelect={true}
                      onChange={(selectedOption) =>
                        updateProductPackageProducts(
                          packageIndex,
                          selectedPackageProductPartIndex,
                          selectedOption.value,
                          'productPart'
                        )
                      }
                      value={productParts
                        .map((productPart) => ({
                          value: productPart.id,
                          label: productPart.name
                        }))
                        .find(option => option.value === selectedPackageProductPart.product_part_id)}
                    />
                    :
                    <Form.Control
                      type="text"
                      value={`${selectedPackageProductPart.name} (élément supprimé)`}
                      readOnly
                    />
                    }
                    </Col>
                    <Col md={1}>
                      <Button
                        variant="outline-danger"
                        onClick={() =>
                          removeProductPackageElement(packageIndex, selectedPackageProductPartIndex, 'productPart')
                        }
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </Button>
                    </Col>
                  </Row>
                ))}
                <Col md={6} style={{ textAlign: "left" }}>
                  <button
                    className="btn btn-secondary mt-2"
                    onClick={() => addProductPackageElement(packageIndex, 'productPart')}
                  >
                    Ajouter une pièce
                  </button>
                </Col>
              </>
            :
              <p style={{ textAlign: "center", color: "red", marginBottom: "0rem" }}>Merci de recalculer votre forfait avec le bouton dédié car vous avez effectué un changement de nombre de personne. Le calcul se fait sur la base initial du prix par personne.</p>
            }
          </Col>
        </div>
      ))}
      <hr />
      <Form>
        <Row className="mb-3">
          <Form.Group as={Col} md="4" controlId="statusSelect">
            <Form.Label>Nom du forfait</Form.Label>
            <Select
              placeholder="Chercher un forfait"
              options={productPackagesForSelectWithCatalogName}
              closeMenuOnSelect={true}
              onChange={handleSelectChange}
              value={selectedProductPackage}
              name={"packageId"}
              menuPortalTarget={document.body} 
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            />
            <div
              style={{
                color: 'hsl(0, 0%, 40%)',
                display: 'inline-block',
                fontSize: 12,
                fontStyle: 'italic',
                marginTop: '1em',
              }}
            >
              <label style={{ marginRight: '1em' }}>
                <input type="checkbox" checked={isActive}  onChange={() => setIsActive((state) => !state)}/> Forfaits actifs
              </label>
            </div>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="numberOfPeople">
            <Form.Label>Nombre de personnes *</Form.Label>
            <Form.Control
              type="number"
              value={numberOfPeople}
              onChange={(e) => setNumberOfPeopleTest(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label style={{ color: 'white' }}>-</Form.Label><br/>
            <Button variant="dark" onClick={handleAddProductPackage} className="w-100">
              Ajouter ce forfait
            </Button>
          </Form.Group>
        </Row>
      </Form>
    </>
  );
};

export default ProductPackagesComponent;
