import React from "react";
import { Button, Modal } from "react-bootstrap";
import { updateModal } from "state/adminData/actions";
import { useSelector, useDispatch } from "react-redux";


const ModalComponent = () => {

  const dispatch = useDispatch();
  const modalIsOpen = useSelector((state) => state?.adminData?.modal?.isOpen || false);
  const modalMessage = useSelector((state) => state?.adminData?.modal?.message || null);

  const closeModal = () => {
    dispatch(updateModal({ isOpen: false, message: ""}));
  };

  return (
    <Modal show={modalIsOpen} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Oups, on a un problème</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{modalMessage}</p>
        <Button onClick={closeModal} variant="warning">Fermer</Button>
      </Modal.Body>
    </Modal>
  );
};

export default ModalComponent;
