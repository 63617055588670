import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import moment from 'moment'
import {Container, Row, Col, Dropdown} from 'react-bootstrap'
import { css } from 'emotion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faExclamationTriangle, faFileInvoice, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import Select from 'app/universal/inputs/Select'
import LeMoulinApi from 'app/universal/utils/LeMoulinApi'
import UpdateDataApi from 'app/universal/utils/UpdateDataApi'
import swal from 'sweetalert';

const Order = (props) => {

  const { order, handleOpenModal, handleSelectOrder, handleDeselectOrder, isSelected, nbOrders } = props

  const [isLoading, setIsLoading]     = useState(false);
  const [status, setStatus]           = useState(['400', '401', '402', '411', '412'].includes(order?.grouped_order_status ? order.grouped_order_status : order.status) ? '401' : order?.grouped_order_status ? order.grouped_order_status : order.status);
  const [isCancelled, setIsCancelled] = useState(order.cancel_date ? true : false);
  const [cancelDate, setCancelDate]   = useState(order.cancel_date);
  const [info, setInfo]               = useState(order.info);

  const currentUrlEncode = encodeURIComponent(window.location.href);

  const {
    company,
    company_old,
    firstname,
    lastname,
    format,
    participants_number,
    invoice_number,
    confirmed_participants_number,
    modified_since_last_printed,
    id,
    updated_at
  } = order

  const delivery_date = order.delivery ? order.delivery.delivery_date : order.delivery_date;

  let statusOptions = [
    {
      "option"      : "101",
      "traduction"  : 'En attente'
    },
    {
      "option"      : "102",
      "traduction"  : "Validé"
    },
    {
      "option"      : "401",
      "traduction"  : "Refusé par le client"
    }
  ];

  // if (process.env.NODE_ENV !== 'production') {
    statusOptions.push({
      "option"      : "103",
      "traduction"  : "A vérifier avant facturation"
    });
  // }

  let statusOptionsInvoiced = [
    {
      "option"      : "103",
      "traduction"  : "A vérifier avant facturation"
    },
    {
      "option"      : "201",
      "traduction"  : "Créer la facture - A payer"
    }
  ];

  const updateStatus = (event) => {
    setIsLoading(true);
    const statusValue = event.target.value;
    const api = info.order_type;
    const reloadAfterUpdate = order?.grouped_order_id ? true : false;
    if(event.target.value !== status) {
      if (event.target.value === '201') {
        swal({
          title: "Attention ",
          text: "Vous allez transformer votre devis en facture, cette action est irreversible.",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then((confirm) => {
          if(confirm){
            const put = order?.grouped_order_id ? LeMoulinApi.request.grouped.status(order.grouped_order_id, {status : statusValue}) : LeMoulinApi.request[api].put(id, {status : statusValue});
            return put.then(resp => {
              setStatus(['400', '401', '402', '411', '412'].includes(resp.status) ? '401' : resp.status);
              setIsLoading(false);
              if(reloadAfterUpdate) {
                window.location.reload();
              }
            });
          }else{
            setIsLoading(false);
          }
        });
      }else{
        const put = order?.grouped_order_id ? LeMoulinApi.request.grouped.status(order.grouped_order_id, {status : event.target.value}) : LeMoulinApi.request[api].put(id, {status : event.target.value});
        return put.then(resp => {
          setStatus(['400', '401', '402', '411', '412'].includes(resp.status) ? '401' : resp.status);
          setIsLoading(false);
          if(reloadAfterUpdate) {
            window.location.reload();
          }
        });
      }
      
    }
  }

  const cancelOrder = () => {
    setIsLoading(true);
    const api = info.order_type;
    const reloadAfterUpdate = order?.grouped_order_id ? true : false;
    swal({
      title: "Attention ",
      text: "Annuler cette commande ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((confirm) => {
      if(confirm){
        let cancelDate = moment().format('YYYY-MM-DD HH:mm:ss');
        const put = order?.grouped_order_id ? LeMoulinApi.request.grouped.put(order.grouped_order_id, {cancel_date : cancelDate}) : LeMoulinApi.request[api].put(id, {cancel_date : cancelDate});
        return put.then(resp => {
          if (resp.success !== undefined && resp.success === false) {
            let msg = "Une erreur est survenue lors de l'annulation de la facture";
            if (resp.message) {
              msg = resp.message;
            }
            swal({
              title: "Attention ",
              text: msg,
              icon: "warning",
              dangerMode: true,
            })           
          }
          else {
            setCancelDate(resp.cancel_date);
            setIsCancelled(resp.cancel_date ? true : false); 
            setInfo(resp.info);
          }
          setIsLoading(false);
          if(reloadAfterUpdate) {
            window.location.reload();
          }
        });
      }else{
        setIsLoading(false);
      }
    });
  }

  const uncancelOrder = () => {
    setIsLoading(true);
    const api = info.order_type;
    const reloadAfterUpdate = order?.grouped_order_id ? true : false;
    swal({
      title: "Attention ",
      text: "Attention cette commande sera de nouveau valide.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((confirm) => {
      if(confirm){
        const put = order?.grouped_order_id ? LeMoulinApi.request.grouped.put(order.grouped_order_id, {cancel_date : null}) : LeMoulinApi.request[api].put(id, {cancel_date : null});
        return put.then(resp => {
          if (resp.success !== undefined &&  resp.success === false) {
            let msg = "Une erreur est survenue lors de l'annulation de la facture";
            if (resp.message) {
              msg = resp.message;
            }
            swal({
              title: "Attention ",
              text: msg,
              icon: "warning",
              dangerMode: true,
            })           
          }
          else {
            setCancelDate(resp.cancel_date);
            setIsCancelled(resp.cancel_date ? true : false); 
            setInfo(resp.info);
          }
          setIsLoading(false);
          if(reloadAfterUpdate) {
            window.location.reload();
          }
        });
      }else{
        setIsLoading(false);
      }
    });
  }

  const themeColor = () => {
    if(info.order_type === 'mealtray'){
      return '#a18b6e'
    }
    else if(info.order_type === 'caterer' || order?.prestation_type === 'caterer'){
      return 'black'
    }
    else if(info.order_type === 'breakfast' || order?.prestation_type === 'breakfast'){
      return '#439b5c'
    }
    else if(info.order_type === 'v2' || order?.prestation_type === 'mealtray'){
      return '#2b7ed6'
    }
  }

  const styles = {
    delivery: {
      background: 'white',
      margin: '0.5rem 0',
      border: '1px solid #ddd',
      borderLeft: '10px solid '+themeColor()+'',
      transition: '300ms',
      position: 'relative'
    },
    statut: {
      textTransform: 'uppercase',
      fontSize: '0.6rem',
      marginBottom: '0rem'
    },
    col:{
      padding: '0.5rem',
      borderRight: '1px #dedede solid',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    verticalAlign:{
      display: 'flex',
      flexDirection: 'row',
    },
    para:{
      marginBottom: '0rem',
      fontSize: '0.9rem',
      lineHeight: '1.3'
    },
    center:{
      textAlign: 'center',
      '@media (max-width: 767px)':{
        textAlign: 'left'
      }
    },
    divisor: {
      height: '1px',
      backgroundColor: '#dedede',
      margin: '0.7rem 0'
    },
    borderRight: {
      '@media (max-width: 767px)':{
        borderRight: '1px #dedede solid',
      }
    },
    mobileDivisor: {
      '@media (max-width: 767px)':{
        display: 'none'
      }
    },
    borderBottomMobile:{
      '@media (max-width: 767px)':{
        borderBottom: '1px #dedede solid',
      }
    },
    borderTopMobile:{
      '@media (max-width: 767px)':{
        borderTop: '1px #dedede solid',
      }
    },
    company:{
      textTransform: 'uppercase',
      color: themeColor(),
      fontWeight: 'bold',
      fontSize: '0.8rem',
      margin: '0'
    },
    label2Col:{
      width: '80%',
      display:'inline-block',
      margin:'auto'
    },
    checkbox2Col:{
      width: '20%',
      display:'inline-block',
      borderLeft: '1px solid #dedede',
      margin:'auto'
    },
    button:{
      textWrap: 'wrap',
      width: '100%',
      color: '#222d32',
      textAlign: 'center',
      fontSize: '0.8rem',
      transition: '300ms',
      display: 'block',
      padding: '4px',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      wordBreak: 'break-word',
      ':hover':{
        textDecoration: 'none',
        backgroundColor: '#222d32',
        color: 'white',
      }
    },
    theme:{
      backgroundColor: themeColor(),
      color: 'white',
    },
    grey:{
      backgroundColor: '#d7d7d7',
      color: 'black',
    },
    red:{
      color: 'red',
    },
    lightGrey:{
      backgroundColor: '#ebebeb',
      color: 'black',
    },
    ultraLightGrey:{
      backgroundColor: '#f4f4f4',
      color: 'black',
    },
    action:{
      padding: '0',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    fixed:{
      position: 'absolute',
      right: '0',
      top: '0'
    },
    buttonToggle:{
      borderRadius: '0px !important',
      padding: '.25rem .5rem',
      border: 'none',
      fontSize: '0.8rem',
      backgroundColor: '#f4f4f4',
      color: 'black',
      width: '100%',
      textWrap: 'wrap',
      '::after':{
        display: 'inline-block',
        marginLeft: '.255em',
        verticalAlign: '0',
        content: '"···"',
        border: 'none'
      }
    },
    startTitle:{
      textTransform: 'uppercase',
      fontSize: '0.6rem'
    },
    document: {
      display: 'flex'
    },
    borderRight: {
      ':first-of-type':{
        borderRight: 'solid #fff 1px;'
      },
      ':last-of-type':{
        borderRight: 'none'
      }
    },
    borderTopBottom: {
      borderTop: 'solid #fff 1px;',
      borderBottom: 'solid #fff 1px;'
    },
    loader: {
      position: 'absolute',
      top: '0',
      right: '0',
      left: '0',
      bottom: '0',
      backgroundColor: 'white',
      opacity : 0.8,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      textTransform: 'uppercase'
    },
    multipresta: {
      position: 'absolute',
      top: '0',
      left: '0',
      color: 'white',
      paddingRight: '0.5rem',
      backgroundColor: themeColor(),
    }
  }
  
  return (
    
    <Container className={css(styles.delivery)} fluid>
      <Row className={css(styles.verticalAlign)}>
        <Col xs={12} md={4} className={css(styles.col, styles.borderBottomMobile)}>
        {order?.info?.groupedOrderNumber && <div className={css(styles.multipresta)}><small>{order?.info?.groupedOrderNumber && order.info.groupedOrderNumber}</small></div>}
          <p className={css(styles.para)}>
            <input
              type="checkbox"
              checked={isSelected}
              onChange={(e) => {
                if (e.target.checked) {
                  handleSelectOrder(order);
                } else {
                  handleDeselectOrder(order.id);
                }
              }}
            /> <small><b>{order.info.orderNumber}</b></small>
          </p>
          <p className={css(styles.company)}>{company && company.name ? company.name : company_old} <br/> {firstname} {lastname}</p>
        </Col>
        <Col xs={6} md={2} className={css(styles.col)}>
          <p className={css(styles.para, styles.center)}>{moment(delivery_date).format('DD/MM/YYYY')}</p>
          <div className={css(styles.divisor, styles.mobileDivisor)}></div>
          <p className={css(styles.para, styles.center)}>{format === 'mealtray' ? 'Plateaux repas' : format}</p>
          <div className={css(styles.divisor, styles.mobileDivisor)}></div>
          <div className={css(styles.para, styles.center)}>
            <span className={css(styles.label2Col, styles.center)}>{participants_number} pers</span>
            {!cancelDate &&
              <span className={css(styles.checkbox2Col, styles.center)}>
                <UpdateDataApi
                  value={confirmed_participants_number}
                  id={id} name={'confirmed_participants_number'}
                  api={order?.grouped_order_id ? 'v2' : info.order_type}
                  input={'checkbox'}
                  handler={() => {}} />
              </span>
            }
          </div>
        </Col>
        <Col xs={6} md={2} className={css(styles.col)}>
          <div className={css(styles.para, styles.center)}>
            <span className={css(styles.startTitle)}>Début prestation</span><br/>
            {isCancelled ? 
              info.start_at
            :
              <UpdateDataApi value={info.start_at} id={id} typeFormat="stringify" name={'{"delivery":{"start_at":"value"}}'} api={order?.grouped_order_id ? 'v2' : info.order_type} input={'times'} handler={() => {}}/>
            }

          </div>
          <div className={css(styles.divisor, styles.mobileDivisor)}></div>
          
          <div className={css(styles.para, styles.center)}>
            {isCancelled ? 
                <>Commande annulée le {moment(cancelDate).format('D/M/Y à HH:mm')}</>
              :
              <>
                 {order?.info?.groupedOrderNumber && <small><FontAwesomeIcon icon={faInfoCircle} color="orange"/> Statut commun à tout {order?.info?.groupedOrderNumber}</small>}
                <Select label={''} value={status} handleChange={updateStatus} data={status == 103 ? statusOptionsInvoiced : statusOptions } color={status === '101' && 'orange'}/>
              </>
            }
          </div>
          
        </Col>
        <Col xs={12} md={2} className={css(styles.col, styles.borderTopMobile)}>
          <p className={css(styles.para, styles.center)}>
            Horaire livraison :<br/>
            {order.delivery.arrived_at_start ? moment(order.delivery.arrived_at_start).format('HH:mm') : "NC"} / {order.delivery.arrived_at_end ? moment(order.delivery.arrived_at_end).format('HH:mm') : "NC"} 
          </p>
          <div className={css(styles.divisor, styles.mobileDivisor)}></div>
          <div className={css(styles.para, styles.center)}>
            <span className={css(styles.label2Col, styles.center)}>
              <small><FontAwesomeIcon icon={faEdit} /></small> {moment(updated_at).format('DD/MM/YYYY HH:mm')}
            </span>
            {!cancelDate &&
              <span className={css(styles.checkbox2Col, styles.center)}>
                <UpdateDataApi
                  id={id}
                  name={'modified_since_last_printed'}
                  api={order?.grouped_order_id ? 'v2' : info.order_type}
                  input={'checkbox'}
                  value={modified_since_last_printed}
                  handler={() => {}} />
                {modified_since_last_printed && <FontAwesomeIcon icon={faExclamationTriangle} color="red"/>}
              </span>
            }
          </div>
        </Col>
        <Col xs={12} md={2} className={css(styles.action)}>
          {!cancelDate &&
            <>
              {info.editionVersion === 'v2' ? 
                <Link  to={{
                  pathname: order?.grouped_order_id ? `/commande` : `/dej_multi_format`,
                  search: `${window.location.search}&order_id=${order?.grouped_order_id ? order.grouped_order_id : order.id}&order_type=${info.order_type}&order_index=${info?.order_index}`
                }} className={css(styles.button, styles.theme)}>{status === 103 ? "Vérifier" : "Modifier"}</Link>
              :
              <>
                <Link target="_parent" to={{
                    pathname: `${info.edit_url}?redirect_url_encode=${currentUrlEncode}`,
                  }} className={css(styles.button, styles.theme)}>{status == 103 ? "Vérifier" : "Modifier"}</Link>
                <Link target="_parent" to={{
                    pathname: `${info.clone_url}?redirect_url_encode=${currentUrlEncode}`,
                  }} className={css(styles.button, styles.grey, styles.borderTopBottom)}>Dupliquer</Link>
              </>
              }
            </>
          }
          <Dropdown>
            <Dropdown.Toggle variant="secondary" size="sm" id="dropdown-custom-components" className={css(styles.buttonToggle)}>
              Documents & Annulation
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {info.document_urls.length > 0 ?
                info.document_urls.map((document, index) => 
                  <Dropdown.Item
                    key={index*10}
                    href={document.url}
                    target="_blank"
                  >
                    {document.name}
                  </Dropdown.Item>
                )  
                :
                <Dropdown.Item eventKey="10" href={info.generate_document_url} target="_blank">Générer le document manquant</Dropdown.Item>            
              }
              {!cancelDate &&
                <>
                  <hr/>
                  <Dropdown.Item eventKey="1" href={info.quotation_url} target="_blank">Fiche de production</Dropdown.Item>
                  <Dropdown.Item eventKey="2" href={info.propose_url} target="_blank">Le Moulin vous propose</Dropdown.Item>
                </>
              }
              <hr/>
              {!cancelDate ?
                <Dropdown.Item 
                  eventKey="4"
                  className={css(styles.red)}
                  onSelect={cancelOrder}
                >
                  Annuler la commande<br/><small>(en cas d'erreur uniquement)</small>
                </Dropdown.Item>
              :
                <Dropdown.Item 
                  eventKey="4" 
                  className={css(styles.red)}
                  onSelect={uncancelOrder}
                >
                  Désannuler la commande<br/><small>(en cas d'erreur uniquement)</small>
                </Dropdown.Item>
              }
            </Dropdown.Menu>
          </Dropdown>
        </Col>
       
      </Row>
      {isLoading && <div className={css(styles.loader)}>
        <div>Chargement...</div>
      </div>}
      {status == 201 && <div className={css(styles.loader)}>
        <div>Commande facturée<br/>(disponible depuis l'onglet "Facture")</div>
      </div>}
    </Container>

  )

}

Order.propTypes = {
  order: PropTypes.object
}

export default Order
