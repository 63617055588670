import React, { useState, useEffect } from 'react';

const CurrencyComponent = ({ initialPrice, onPriceChange, nullable, style, disabled }) => {
  const [value, setValue] = useState(initialPrice !== null ? initialPrice / 100 : initialPrice === null && nullable ? '' : 0);
  useEffect(() => {
    setValue(initialPrice !== null ? initialPrice / 100 : initialPrice === null && nullable ? '' : 0);
  }, [initialPrice, nullable]);

  const handleOnChange = (event) => {
    const inputValue = event.target.value;
    const formattedValue = inputValue.replace(',', '.');
    setValue(inputValue);

    if (formattedValue === '' && nullable) {
      // Autoriser le null si nullable est vrai
      onPriceChange(null);
    } else if (/^\d+(\.\d{1,2})?$/.test(formattedValue)) {
      // Si c'est un nombre valide, envoyer la valeur formatée
      onPriceChange(parseFloat(formattedValue) * 100);
    } else if (formattedValue === '0') {
      // Autoriser explicitement la valeur zéro
      onPriceChange(0);
    }
  };

  return (
    <div className="input-group">
      <input
        type="number"
        placeholder="Montant en €"
        className="form-control"
        value={value}
        onChange={handleOnChange}
        style={style}
        disabled={disabled}
        step="0.01"
      />
      <div className="input-group-append">
        <span className="input-group-text" id="basic-addon2">€</span>
      </div>
    </div>
  );
};

export default CurrencyComponent;