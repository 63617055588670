import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Alert, InputGroup, Form } from "react-bootstrap";
import Layout from "app/universal/layout/Layout";
import {
  initialize,
  getProducts,
  getProductGroups,
  getProductPackages,
  getProductClassifications,
  getProductParts
} from "state/adminData/actions";
import {
  addOrder,
  fetchGroupedOrder,
  setCurrentOrderIndex
} from "state/orderEdition/actions";
import { getTenderCatalog } from "state/tender/actions";
import CustomerSelectComponent from "./CustomerSelectComponent";
import AddCustomerComponent from "./AddCustomerComponent";
import CompanySelectComponent from "./CompanySelectComponent";
import AddCompanyComponent from "./AddCompanyComponent";
import TenderSelectComponent from "./TenderSelectComponent";
import FormatSelectComponent from "./FormatSelectComponent";
import DeliveryInfoComponent from "./DeliveryInfoComponent";
import BillingInfoComponent from "./BillingInfoComponent";
import ProductGroupsComponent from "./offer/ProductGroupsComponent";
import ProductPackagesComponent from "./offer/ProductPackagesComponent";
import ProductsComponent from "./offer/ProductsComponent";
import DeliveryFeeComponent from "./DeliveryFeeComponent";
import ReductionComponent from "./ReductionComponent";
import HeaderOrderInfoComponent from "./HeaderOrderInfoComponent";
import CommentaryOrderInfoComponent from "./CommentaryOrderInfoComponent";
import DiversOrderInfoComponent from "./DiversOrderInfoComponent";
import ListOrdersComponent from "./ListOrdersComponent";
import Loader from "app/deliveryTool/components/delivery/FakeDelivery";
import { useHistory, useLocation } from "react-router-dom";
import PricingTable from "./offer/caterer/PricingTable";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { toFrenchType } from "helper/globalHelper";

const MultiOrders = () => {
  const dispatch = useDispatch();
  const isAdminDataLoading = useSelector((state) => state.adminData.isLoading);
  const isAdminDataInialized = useSelector(
    (state) => state.adminData.isInitialized
  );
  const isOrderEditionLoading = useSelector(
    (state) => state.orderEdition.isLoading
  );
  const productClassificationsState = useSelector(
    (state) => state.adminData.productClassifications
  );
  const productsState = useSelector((state) => state.adminData.products);
  const products = productsState.data;

  const productGroupsState = useSelector((state) => state.adminData.productGroups);
  const productGroups = productGroupsState.data;

  const productPackagesState = useSelector((state) => state.adminData.productPackages);
  const productPackages = productPackagesState.data;

  const tender = useSelector((state) => state.orderEdition.tender);
  const tenderCatalogState = useSelector((state) => state.tender.tenderCatalog);
  const tenderCatalog = useSelector((state) => state.tender.tenderCatalog.data);
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const groupedOrderId = searchParams.get("order_id");
  let orderIndex = searchParams.get('order_index');

  const [isLoading, setIsLoading] = useState(
    isAdminDataLoading || isOrderEditionLoading
  );

  // Gére le loading
  useEffect(() => {
    if (isAdminDataLoading === false && isAdminDataInialized === false) {
      dispatch(initialize());
    } else {
      if (!productsState.isInitialized && !productsState.isLoading) {
        dispatch(getProducts());
      } 
      if (!productGroupsState.isInitialized && !productGroupsState.isLoading) {
        dispatch(getProductGroups());
      } 
      if (!productPackagesState.isInitialized && !productPackagesState.isLoading) {
        dispatch(getProductPackages());
      } 
      if (!productClassificationsState.isInitialized && !productClassificationsState.isLoading) {
        dispatch(getProductClassifications());
      }
    }
    setIsLoading(
      isAdminDataLoading || isOrderEditionLoading || productsState.isLoading || productGroupsState.isLoading || productPackagesState.isLoading || productClassificationsState.isLoading
        ? true
        : false
    );
  }, [
    isAdminDataLoading,
    isOrderEditionLoading,
    isAdminDataInialized,
    productsState,
    productClassificationsState,
    productGroupsState,
    productPackagesState,
  ]);

  // La commande
  useEffect(() => {
    if (groupedOrderId) {
      dispatch(fetchGroupedOrder(groupedOrderId));
    }
  }, [dispatch, groupedOrderId]);

  // Les prix AO
  useEffect(() => {
    if (tender?.id && tenderCatalogState.isLoading === false) {
      dispatch(getTenderCatalog(tender.id));
    }
  }, [dispatch, tender]);

  const currentOrderIndex = useSelector(
    (state) => state.orderEdition.currentOrderIndex
  );

  const orders = useSelector((state) => state.orderEdition.orders);

  // Si orderIndex n'est pas présent, ajoutez-le avec une valeur de 0
  useEffect(() => {
    if (orderIndex === null) {
      searchParams.set('order_index', '0');
      history.push({ search: searchParams.toString() });
      orderIndex = 0;
    }
  }, [orderIndex, history, searchParams]);

  // L'index de la commande
  useEffect(() => {
    if (orderIndex !== null && currentOrderIndex === null && !isLoading && orders.length > 0) {
      if (orders[orderIndex]) {
        dispatch(setCurrentOrderIndex(orderIndex));
      }
      else {
        dispatch(setCurrentOrderIndex(0));
      }
    }
  }, [dispatch, orderIndex, isLoading, currentOrderIndex, orders]);

  const order = useSelector(
    (state) => state.orderEdition.orders[currentOrderIndex]
  );

  const prestationType = order?.prestationType;

  const handleAddOrder = (prestationType) => {
    dispatch(addOrder(prestationType));
  };

  const reloadOffer = () => {
    dispatch(initialize());
    dispatch(getProducts());
    dispatch(getProductGroups());
    dispatch(getProductPackages());
    dispatch(getProductClassifications());
    dispatch(getProductParts());
  };

  return (
    <Layout reduxIsUsed={true}>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : order && (order.version !== "v2" || (order.status > 200 && order.status < 300)) ? (
        <div style={{ padding: "15px", textAlign: "center" }}>
          <h1>Commande non modifiable</h1>
          <p>
            {order.status > 200 && order.status < 400
              ? "Cette commande a été passé en facturation et n'est plus modifiable." 
              : order.status >= 400 ?
                "Cette commande a été annulée et ne peut plus être modifiée."
              : "Cette commande a été créée avec une version antérieure de l'outil de commande et ne peut pas être modifiée."}
          </p>
        </div>
      ) : (
        <div
          id="multiorder"
        >
          <div
            id="customer"
          >
            <div>
              <label>Sélection du client*</label>
              <InputGroup>
                <CustomerSelectComponent />
                <InputGroup.Append>
                  <AddCustomerComponent />
                </InputGroup.Append>
              </InputGroup>
              <br />
              <label>Sélection de l'entreprise</label>
              <InputGroup>
                <CompanySelectComponent />
                <InputGroup.Append>
                  <AddCompanyComponent />
                </InputGroup.Append>
              </InputGroup>
              <br />
              <TenderSelectComponent />
              <br />
              <hr />
              <br />
              <DeliveryInfoComponent title="Livraison :" />

              <BillingInfoComponent
                title="Facturation :"
                showCompany={true}
                dispatchAddress={false}
              />
            </div>
          </div>

          {currentOrderIndex === null ? (
            <div
              id="main"
              style={{
                textAlign: "center",
              }}
            >
              <br />
              <br />
              <h2>Choisissez un type de prestation pour démarrer</h2>
              <br />
              <Button
                variant="primary"
                onClick={(e) => handleAddOrder("mealtray")}
              >
                + Prestation Plateaux-repas
              </Button>
              <br />
              <br />
              <Button
                variant="dark"
                onClick={(e) => handleAddOrder("caterer")}
              >
                + Prestation cocktail
              </Button>
              <br />
              <br />
              <Button
                variant="success"
                onClick={(e) => handleAddOrder("breakfast")}
              >
                + Prestation petit-déjeuner
              </Button>
            </div>
          ) : (
            <div
              id="main"
            >
              <Row
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  border: "1px solid lightgray",
                  borderRadius: "10px",
                  margin: "10px 0",
                }}
              >
                <Col xs={12} sm={12} md={12} style={{ margin: "15px 0" }}>
                  <Alert variant="secondary">
                    {toFrenchType(order.prestationType, true)} du{" "}
                    {order.deliveryInfo.deliveryDate
                      ? moment(order.deliveryInfo.deliveryDate).format(
                          "DD/MM/YY"
                        )
                      : "A RENSEIGNER"}{" "}
                    pour{" "}
                    {order.deliveryInfo.person
                      ? order.deliveryInfo.person
                      : "A RENSEIGNER"}{" "}
                    <FontAwesomeIcon icon={faUser} />
                    <br />
                  </Alert>
                  <FormatSelectComponent orderIndex={currentOrderIndex} />
                  {currentOrderIndex !== null ? (
                    <HeaderOrderInfoComponent orderIndex={currentOrderIndex} showStatus={false} tenderCatalog={tenderCatalog} products={products} productPackages={productPackages} productGroups={productGroups} />
                  ) : (
                    <Button variant="success" onClick={handleAddOrder}>
                      Ajouter une commande
                    </Button>
                  )}
                </Col>
              </Row>
              <Row
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  border: "1px solid lightgray",
                  borderRadius: "10px",
                  margin: "10px 0",
                }}
              >
                <Col xs={12} sm={12} md={12} style={{ margin: "15px 0" }}>
                  <DeliveryInfoComponent
                    orderIndex={currentOrderIndex}
                    title="Livraison spécifique :"
                  />
                </Col>
              </Row>
              <Row
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  border: "1px solid lightgray",
                  borderRadius: "10px",
                  margin: "10px 0",
                }}
              >
                <Col xs={12} sm={12} md={12} style={{ margin: "15px 0" }}>
                  <h5>Commentaires</h5>
                  <CommentaryOrderInfoComponent
                    orderIndex={currentOrderIndex}
                  />
                </Col>
              </Row>
              {currentOrderIndex === null ? (
                <Row
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    border: "1px solid lightgray",
                    borderRadius: "10px",
                    margin: "10px 0",
                  }}
                >
                  <Col xs={12} sm={12} md={12} style={{ margin: "15px 0" }}>
                    <Button variant="success" onClick={handleAddOrder}>
                      Ajouter une commande
                    </Button>
                  </Col>
                </Row>
              ) : (
                <>
                  <Row>
                    <Col xs={12} sm={12} md={12} style={{ textAlign: "right", margin: "5px 0" }}>
                      <Button onClick={reloadOffer} variant="warning">
                        Recharger l'offre
                      </Button>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      border: "1px solid lightgray",
                      borderRadius: "10px",
                      margin: "10px 0",
                    }}
                  >
                    <Col xs={12} sm={12} md={12} style={{ margin: "15px 0" }}>
                      <ProductPackagesComponent
                        title="Les forfaits"
                        orderIndex={currentOrderIndex}
                        products={products}
                        tenderCatalog={tenderCatalog}
                      />
                    </Col>
                  </Row>
                  <Row
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      border: "1px solid lightgray",
                      borderRadius: "10px",
                      margin: "10px 0",
                    }}
                  >
                    <Col xs={12} sm={12} md={12} style={{ margin: "15px 0" }}>
                      <ProductGroupsComponent
                        title="Les groupes de produit"
                        orderIndex={currentOrderIndex}
                        products={products}
                        tenderCatalog={tenderCatalog}
                      />
                    </Col>
                  </Row>
                  <Row
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      border: "1px solid lightgray",
                      borderRadius: "10px",
                      margin: "10px 0",
                    }}
                  >
                    <Col xs={12} sm={12} md={12} style={{ margin: "15px 0" }}>
                      <ProductsComponent
                        title="Autres prestations au détail"
                        orderIndex={currentOrderIndex}
                        products={products}
                        excludedClassifications={["Matériels et services"]}
                        prestationType={prestationType}
                        tenderCatalog={tenderCatalog}
                      />
                    </Col>
                  </Row>

                  <Row
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      border: "1px solid lightgray",
                      borderRadius: "10px",
                      margin: "10px 0",
                    }}
                  >
                    <Col xs={12} sm={12} md={12} style={{ margin: "15px 0" }}>
                      <ProductsComponent
                        title="Matériel & services"
                        orderIndex={currentOrderIndex}
                        products={products.filter(
                          (product) =>
                            product.classificationName ===
                            "Matériels et services"
                        )}
                        classificationName="Matériels et services"
                        prestationType={prestationType}
                        tenderCatalog={tenderCatalog}
                      />
                    </Col>
                  </Row>
                </>
              )}

              {prestationType === "caterer" && (
                <>
                  <Row
                    style={{
                      margin: "10px 0",
                    }}
                  >
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      style={{
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                        border: "1px solid lightgray",
                        borderRadius: "10px",
                        padding: "15px",
                      }}
                    >
                      <PricingTable
                        orderIndex={currentOrderIndex}
                      />
                    </Col>
                  </Row>
                </>
              )}

              <Row
                className="justify-content-between"
                style={{
                  margin: "10px 0",
                }}
              >
                <Col
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    border: "1px solid lightgray",
                    borderRadius: "10px",
                    padding: "15px",
                    maxWidth: "49%",
                  }}
                >
                  <h5>Frais de livraison</h5>
                  <DeliveryFeeComponent orderIndex={currentOrderIndex} />
                </Col>
                <Col
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    border: "1px solid lightgray",
                    borderRadius: "10px",
                    padding: "15px",
                    maxWidth: "49%",
                  }}
                >
                  <h5>Réduction</h5>
                  <ReductionComponent orderIndex={currentOrderIndex} />
                </Col>
              </Row>
            </div>
          )}

          <div
            id="cart"
          >
            {currentOrderIndex !== null && (
              <Row>
                <Col xs={12} sm={12} md={12} style={{ margin: "10px 0" }}>
                  <Button
                    variant="primary"
                    onClick={(e) => handleAddOrder("mealtray")}
                    className="mr-1"
                  >
                    + PR
                  </Button>
                  <Button
                    variant="dark"
                    onClick={(e) => handleAddOrder("caterer")}
                    className="mr-1"
                  >
                    + Ckt
                  </Button>
                  <Button
                    variant="success"
                    onClick={(e) => handleAddOrder("breakfast")}
                  >
                    + PDJ
                  </Button>
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={12} sm={12} md={12} style={{ margin: "10px 0" }}>
                {currentOrderIndex !== null ? (
                  <ListOrdersComponent />
                ) : (
                  <p>
                    <i>Aucune prestation</i>
                  </p>
                )}
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xs={12} sm={12} md={12} style={{ margin: "10px 0" }}>
                <h5>Info complémentaires</h5>
                <DiversOrderInfoComponent />
              </Col>
            </Row>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default MultiOrders;
