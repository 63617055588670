export function centimesToCurrency(amount) {
  return (amount / 100).toFixed(2) + ' €';
}

/**
 * Traduit en français le type de prestation en ajoutant une majuscule à la première lettre
 */
export function toFrenchType(type, shortcut = false) {
  let translatedType = type;
  if (type === 'mealtray') {
    translatedType = 'plateau repas';
    if(shortcut){
      translatedType = 'PR';
    }
  }
  if (type === 'breakfast') {
    translatedType = 'petit déjeuner';
    if(shortcut){
      translatedType = 'PDJ';
    }
  }
  if (type === 'caterer') {
    translatedType = 'cocktail';
    if(shortcut){
      translatedType = 'Ckt';
    }
  }
  translatedType = translatedType ? translatedType.charAt(0).toUpperCase() + translatedType.slice(1) : translatedType;
  return translatedType;
}
