import React, { useState, useEffect } from "react";
import { Container, Col, Form } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { css } from "emotion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import Helmet from "react-helmet";
import moment from "moment";
import LeMoulinApi from "app/universal/utils/LeMoulinApi";
import FakeDelivery from "app/deliveryTool/components/delivery/FakeDelivery";

const CardProdMultipoleV2 = (props) => {
  // Parametre pour la requete API (date & status)
  const queryString = require("query-string");

  const prm = queryString.parse(window.location.search);

  const [filter] = useState({
    startDate     : prm.startDate,
    endDate       : prm.endDate,
    status        : prm.status,
    activity      : prm.activity,
    selectedOrders: prm.selectedOrders
  });

  const [dataCompositions, setDataCompositions] = useState({
    dates: [],
    categories: [],
    products: [],
  });
  const [loading, setLoading] = useState(false);

  // Permet de filtrer par groupe prod
  const [selectedProdGroups, setSelectedProdGroups] = useState(new Set());

  // Fonction qui gère la prise en charge du filtre des groupes de prod
  const handleCheckboxChange = (event) => {
    const { name } = event.target;
    if (selectedProdGroups.has(name)) {
      selectedProdGroups.delete(name);
    } else {
      selectedProdGroups.add(name);
    }
    setSelectedProdGroups(new Set(selectedProdGroups));
  };

  useEffect(() => {
    setLoading(true);
    const fetchDataCompositions = async () => {
      const dataCompositionsResult =
        await LeMoulinApi.request.order.compositionSheetsV2.gets({
          dates: {
            start: filter.startDate,
            end: filter.endDate,
            status: filter.format,
          },
          activity: filter.activity,
          selectedOrders: filter.selectedOrders
        });
      setDataCompositions(dataCompositionsResult);
      setLoading(false);
    };

    fetchDataCompositions();
  }, [filter]);

  const getProductsByCategory = (category) => {
    // Les produits
    let products = dataCompositions.products.filter(
      (data) => data.prodCategory === category
    );
    let productsFormatted = [];
    products.forEach((item, i) => {
      let index = productsFormatted.findIndex(
        (pf) => pf.productName === item.productName
      );
      if (index !== -1) {
        let indexDate = productsFormatted[index].quantities.findIndex(
          (di) => di.date === item.deliveryDate
        );
        productsFormatted[index].quantities[indexDate].qty = item.quantity;
      } else {
        productsFormatted.push({
          quantities: dataCompositions.dates.map((date) => {
            let qty = date === item.deliveryDate ? item.quantity : null;
            return { date: date, qty: qty };
          }),
          productName: item.productName,
        });
      }
    });
    return productsFormatted;
  };

  /**
   * 09/23 - Retourne le total d'un produit dans une catégorie
   */
  const getTotalByProductAndCategory = (productName, prodCategory) => {
    let products = dataCompositions.products;
    let total = 0;
    products.forEach((item, i) => {
      if (item.productName === productName && item.prodCategory === prodCategory) {
        total += item.quantity;
      }
    });
    return total;
  }

  return (
    <div>
      <Helmet bodyAttributes={{ style: "background-color : white" }} />

      {/* Permet de filtrer dynamiquement les groupes de prod des produits */}
      <Col xs={12} sm={12} md={12} className="ignore-on-print">
        <Form.Group>
          {Array.from(dataCompositions.categories).map((category, kCategory) => (
            <Form.Check
              key={kCategory}
              type="checkbox"
              name={category}
              label={category}
              id={category}
              checked={selectedProdGroups.has(category)}
              onChange={handleCheckboxChange}
              inline
            />
          ))}
        </Form.Group>
      </Col>
      <div
        className={css(styles.print)}
        onClick={() => {
        const eltToIgnoreForPrint = document.querySelector(".ignore-on-print");

        window.onbeforeprint = function () {
          eltToIgnoreForPrint.style.display = "none";
        };

        window.onafterprint = function () {
          eltToIgnoreForPrint.style.display = "block";
        };
          window.print();
        }}
      >
        <FontAwesomeIcon icon={faPrint} />{" "}
      </div>

      {loading ? (
        <Container>
          <FakeDelivery />
        </Container>
      ) : (
        <>
          <Col xs={12} sm={12} md={12}>
            <h1 className={css(styles.title)}>
              Récap { filter.activity === "all" ? "multipôle" : filter.activity === "mealtray" ? "plateaux-repas" : filter.activity === "caterer" ? "traiteur" : filter.activity === "breakfast" ? "petit-déjeuner" : null } du{" "}
              {moment(filter.startDate).format("DD/MM/YY")} au{" "}
              {moment(filter.endDate).format("DD/MM/YY")}
            </h1>
            <p className="text-center">{JSON.parse(filter.selectedOrders).map(order => order.orderNumber+" - ")}</p>
            <h2 className={css(styles.part)}>Produits</h2>
          </Col>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Catégorie</th>
                <th>Produit</th>
                <th className={css(styles.total)}><b>Total</b></th>
                {dataCompositions.dates.map((date, kDate) => (
                  <th key={kDate}>{moment(date).format("DD/MM/YY")}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataCompositions.categories
                .filter(
                  (item) =>
                    selectedProdGroups.size === 0 ||
                    selectedProdGroups.has(item)
                )
                .map((prodCategory, kProdCategory) =>
                  getProductsByCategory(prodCategory).map(
                    (productData, kProductData) => (
                      <tr key={kProductData}>
                        <td>{kProductData === 0 && prodCategory}</td>
                        <td>{productData.productName}</td>
                        <td className={css(styles.total)}><b>{getTotalByProductAndCategory(productData.productName, prodCategory)}</b></td>
                        {productData.quantities.map((quantity, kQuantity) => (
                          <td key={kQuantity}>{quantity.qty}</td>
                        ))}
                      </tr>
                    )
                  )
                )}
            </tbody>
          </Table>
        </>
      )}
    </div>
  );
};

const styles = {
  title: {
    color: "#b64e4e",
    textAlign: "center",
    fontSize: "1.6rem",
  },
  total: {
    color: "#b64e4e"
  },
  part: {
    color: "#bbb",
    textAlign: "center",
    fontSize: "1.4rem",
  },
  print: {
    "@media print": {
      display: "none",
    },
    cursor: "pointer",
    backgroundColor: "white",
    position: "fixed",
    top: "3%",
    width: "60px",
    height: "60px",
    paddingTop: "9px",
    right: "3%",
    borderRadius: "50%",
    border: "none",
    color: "#555",
    fontSize: "1.5rem",
    textAlign: "center",
    padding: "0.6rem 0.2rem",
    transition: "300ms",
    boxShadow: "2px 2px 10px #ccc",
    ":hover": {
      textDecoration: "none",
      color: "white",
      backgroundColor: "#bbb",
      transition: "300ms",
    },
  },
};

export default CardProdMultipoleV2;
